import {memo, useCallback, useEffect} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {useRequestsBalance} from 'queries/score';
import styles from './SuccessPaymentModal.module.css';

export const SuccessPaymentModal = memo(() => {
    const {setIsSuccessPaymentModalOpen, setBalance} = useAppContext();
    const [{data}, fetch] = useRequestsBalance();

    const handleClose = useCallback(() => {
        setIsSuccessPaymentModalOpen(false);
    }, []);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (data !== null) {
            setBalance(data);
        }
    }, [data]);

    if (!data) {
        return null;
    }

    return (
        <Modal
            title={''}
            onClose={handleClose}
        >
            <div>
                <div className={styles.title}>Congratulations!</div>
                <div className={styles.subtitle}>The number of available<br/>Product to City Requests:</div>
                <div className={styles.counter}>{data}</div>
                <div className={styles.description}>
                    You can always get more <b>Product to City Requests</b> in your
                    account or you can purchase an unlimited subscription
                </div>
                <div className={styles.controls}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
