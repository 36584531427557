import {memo, useCallback, useEffect, useMemo} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Picture} from 'components/common/picture';
import {useAllScans} from 'queries/scans';
import {usePatchProductImage} from 'queries/products';
import {ReactComponent as BackIcon} from 'assets/images/arrow-left.svg';
import styles from './AllScansModal.module.css';

export const AllScansModal = memo(() => {
    const {
        setProduct,
        setIsAllScansModalOpen,
        setIsProductModalOpen,
        setIsDeleteScanModalOpen,
        setRefresh,
        product,
    } = useAppContext();
    const [{data, isPending}, fetch] = useAllScans();
    const [editedImage, patchImage] = usePatchProductImage();

    const handleClose = useCallback(() => {
        setIsAllScansModalOpen(false);
        setProduct(null);
    }, []);

    const handleBack = useCallback(() => {
        setIsAllScansModalOpen(false);
        setIsProductModalOpen(true);
    }, []);

    const TitleNode = useMemo(() => {
        return (
            <div className={styles.title}>
                <div
                    className={styles.titleControl}
                    onClick={handleBack}
                >
                    <BackIcon />
                </div>
                <div>All scans</div>
            </div>
        );
    }, []);

    const ContentNode = useMemo(() => {
        if (isPending) {
            return (
                <div className='pageloaderWrapper'>
                    <div className='pageloader'/>
                </div>
            );
        }

        return (
            <div className={styles.wrapper}>
                {data?.map(({scanImages, id}) => {
                    const [img] = scanImages || [];
                    if (!img?.url) return null;

                    return (
                        <div
                            className={styles.scanInfo}
                            key={`all_scans_item_${id}`}
                        >
                            <div className={styles.pictureWrapper}>
                                <Picture
                                    url={img.url}
                                    onChange={() => {
                                        if (!product?.id) return;
                                        patchImage({id: product.id, url: img.url});
                                    }}
                                    onDelete={() => {
                                        if (!product?.id) return;
                                        setIsDeleteScanModalOpen(true);
                                    }}
                                />
                            </div>
                            <div>Scan ID: {img.id}</div>
                        </div>
                    );
                })}
            </div>
        );
    }, [isPending, data]);

    useEffect(() => {
        if (product?.id) {
            fetch(product.id);
        }
    }, [product?.id]);

    useEffect(() => {
        if (typeof editedImage?.data !== 'string' || Boolean(editedImage?.error)) return;

        setRefresh(true);
        setIsAllScansModalOpen(false);
        setIsProductModalOpen(true);
    }, [editedImage?.data, editedImage?.error, product]);

    return (
        <Modal
            title={TitleNode}
            onClose={handleClose}
            borderUnderTitle
        >
            {ContentNode}
        </Modal>
    );
});
