import {memo} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {ReactComponent as Icon} from 'assets/images/gpt-icon-black.svg';
import styles from './GptInfoModal.module.css';

export const GptInfoModal = memo(() => {
    const {setIsGptModalOpened} = useAppContext();

    const handleClose = () => {
        setIsGptModalOpened(false);
    };

    return (
        <Modal onClose={handleClose}>
            <div className={styles.wrapper}>
                <Icon />
                <div className={styles.title}>
                    Information from ChatGPT
                </div>
                <div className={styles.text}>
                    The product description and ingredients have been added through ChatGPT.
                </div>
                <div className={styles.button}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
