import {useRequest} from 'core/hooks';

const BASE_URL = '/brands';

export const useFoundBrands = () =>
    useRequest<string, Array<string>>((text) => ({
        url: BASE_URL,
        method: 'GET',
        params: {
            filter: {
                order: 'id desc',
                limit: 10,
                where : {
                    name: {
                        like: `${text}%`,
                    },
                },
            },
        },
    }), true);
