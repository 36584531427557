import {memo} from 'react';

import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import styles from './ConfirmModal.module.css';

export const ConfirmModal = memo<IProps>(({
    onOk,
    onCancel,
    isCreateMode = false,
}) => {
    const description = isCreateMode
        ? 'This action will create a new product and users will see it in the App'
        : 'This action will affect the product card and users will see this changes in App.';

    return (
        <Modal
            title='Are you sure?'
            description={description}
            onClose={onCancel}
        >
            <div className={styles.wrapper}>
                <Button text='Ok' onClick={onOk} />
                <div
                    className={styles.link}
                    onClick={onCancel}
                >
                    Cancel
                </div>
            </div>
        </Modal>
    );
});

interface IProps {
    onOk: () => void;
    onCancel: () => void;
    isCreateMode?: boolean;
}
