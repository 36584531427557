import {FormEvent, memo, useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {InputWithLabel} from 'components/common/search/InputWithLabel';
import {Button, ButtonTypes} from 'components/common/newButton';
import {ScoreResult} from 'components/blocks/scoreResult/ScoreResult';
import {ScoreCheckingField} from 'components/blocks/scoreCheckingField';
import {Nps} from 'components/blocks/nps';
import {useAppContext} from 'components/app/AppContext';
import {SCORE_FIELDS_CONFIG} from 'core/configs';
import {useScoreInfo} from 'queries/score';
import {useProduct} from 'queries/products';
import {ReactComponent as StartIcon} from 'assets/images/start-score.svg';
import {ReactComponent as NoResults} from 'assets/images/not-found.svg';
import styles from './ScoreChecking.module.css';

export const ScoreChecking = memo(() => {
    const [productId, setProductId] = useState<number>();
    const [userId, setUserId] = useState<number>();
    const [showEmptyResult, setShowEmptyResult] = useState<boolean>(false);
    const [{data, error, isPending}, fetch] = useScoreInfo();
    const [{data: product, isPending: isPendingProduct}, fetchProduct] = useProduct();

    const {
        setProduct,
        setIsProductModalOpen,
        refresh,
        setRefresh,
    } = useAppContext();

    const info = useMemo(() => data?.[0], [data]);

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!!productId && !!userId) {
            setShowEmptyResult(false);
            fetch({product: productId, user: userId});
            fetchProduct(productId);
        } else {
            setShowEmptyResult(true);
        }
    }, [productId, userId]);

    // // Пока работает только для модалки продукта
    const handleModalOpenClick = useCallback(() => {
        if (!product) return null;

        setProduct(product);
        setIsProductModalOpen(true);
    }, [product]);

    useEffect(() => {
        if (refresh && !!productId && !!userId) {
            fetch({product: productId, user: userId});
            fetchProduct(productId);
            setRefresh(false);
        }
    }, [refresh, productId, userId]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.topline}>
                <form className={styles.searchBlock} onSubmit={handleSubmit}>
                    <InputWithLabel
                        label='Product ID'
                        onInput={(value) => setProductId(Number(value))}
                        onClear={() => setProductId(0)}
                    />
                    <InputWithLabel
                        label='User ID'
                        onInput={(value) => setUserId(Number(value))}
                        onClear={() => setUserId(0)}
                    />
                    <Button
                        text='Show score'
                        type={ButtonTypes.micro}
                        isSubmit
                    />
                </form>
                {info?.score !== undefined && (
                    <ScoreResult score={info?.score} isEnoughData={info?.completeness?.is_complete || false} />
                )}
            </div>
            {(isPending || isPendingProduct) && (
                <div className={styles.emptyWrapper}>
                    <div className='pageloaderWrapper'>
                        <div className='pageloader'/>
                    </div>
                </div>
            )}
            {!info && !isPending && !isPendingProduct && !error && !showEmptyResult && (
                <div className={styles.emptyWrapper}>
                    <div className={styles.icon}>
                        <StartIcon />
                    </div>
                    <div className={styles.title}>Start your score checking now!</div>
                    <div className={styles.description}>
                        To see results from the score system, please insert the&nbsp;user and product IDs.
                    </div>
                </div>
            )}
            {(!!error || showEmptyResult) && (
                <div className={styles.emptyWrapper}>
                    <div className={styles.icon}>
                        <NoResults />
                    </div>
                    <div className={styles.title}>No results :(</div>
                    <div className={styles.description}>
                        Maybe this user or product doesn't exist in our database. Please check again or try another one.
                    </div>
                </div>
            )}
            {!!info && !error && !showEmptyResult && (
                <table className={styles.result}>
                    <tbody>
                        <tr>
                            <td className={styles.bigTitle} colSpan={2}>Product information:</td>
                            <td className={cn(styles.bigTitle, styles.rightTitle)} colSpan={2}>User information:</td>
                        </tr>
                        {SCORE_FIELDS_CONFIG.map(({left, right}, fieldIdx) => {
                            const rowHeight = Math.max(left.length, right.length);
                            const tempArray = new Array(rowHeight).fill(1);
                            const leftProps = {...(left.length < rowHeight && {rowSpan: rowHeight})};
                            const rightProps = {...(right.length < rowHeight && {rowSpan: rowHeight})};
                            return tempArray.map((_, idx) => (
                                <tr key={`result_row_${fieldIdx}_${idx}`}>
                                    {!!left[idx] && (
                                        <>
                                            <td className={styles.resultTitle} {...leftProps}>{left[idx].title}</td>
                                            <td {...leftProps}>
                                                <ScoreCheckingField field={left[idx]} info={info} onModalClick={handleModalOpenClick} />
                                            </td>
                                        </>
                                    )}
                                    {!!right[idx] && (
                                        <>
                                            <td className={cn(styles.resultTitle, styles.rightTitle)} {...rightProps}>{right[idx].title}</td>
                                            <td {...rightProps}>
                                                <ScoreCheckingField field={right[idx]} info={info} onModalClick={handleModalOpenClick} />
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ));
                        })}
                        <tr>
                            <td colSpan={2} className={styles.footer}>
                                <b>Ingredients & Information:</b> Please be aware that ingredient lists and description for the products could&nbsp;
                                be updated by brand, factory and country of production. Please, before use the product, refer to the ingredient list&nbsp;
                                and product description on your product package for the most up to date list of ingredients to ensure it is suitable&nbsp;
                                to your personal use.
                            </td>
                            <td colSpan={2} className={cn(styles.rightTitle, styles.footer)}>
                                <b>Free Research Preview:</b> Our goal is to make the AI skincare recommendation system more effective and&nbsp;
                                to help people reduce the number of allergic reactions they experience after using skincare products.
                            </td>
                        </tr>
                        <tr className={styles.tableBackLeft}/>
                        <tr className={styles.tableBackRight}/>
                    </tbody>
                </table>
            )}
            {!!info && !error && !showEmptyResult && <Nps />}
        </div>
    );
});
