import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import {TScan} from 'core/types';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {useApproveMatch, useDeleteScan} from 'queries/scans';
import {usePatchProductImage} from 'queries/products';
import {Picture} from 'components/common/picture';
import {Button, ButtonThemes, ButtonTypes} from 'components/common/newButton';
import {ProductField} from 'components/common/productField';
import {useAppContext} from 'components/app/AppContext';
import styles from './MatchModal.module.css';

export const Content = memo<IProps>(({
    scan,
    onConfirm,
    onApprove,
}) => {
    const {setIsFindModalOpen, setIsMatchModalOpen, product: foundProduct, setScan, setRefresh} = useAppContext();
    const {
        id,
        scanImages,
        product,
        productId,
        productSearchLog,
        status,
    } = scan || {};
    const currentProduct = foundProduct || product;
    const [approvedMatch, fetchApprovedMatch] = useApproveMatch(Number(id));
    const [patchedImage, patchImage] = usePatchProductImage();
    const [errorMatch, fetchErrorMatch] = useDeleteScan(Number(id));
    const [isMatchApproved, setIsMatchApproved] = useState<boolean>(false);

    const scanImageUrl = useMemo(() => {
        if (scanImages?.[0]) {
            const {url} = scanImages?.[0];
            return url;
        }

        return null;
    }, [scanImages]);

    const productImageUrl = useMemo(() => {
        if (currentProduct?.productImages?.[0]) {
            return currentProduct.productImages[0].url;
        }

        if (isMatchApproved && status !== 'resolved') {
            return scanImageUrl;
        }

        return null;
    }, [currentProduct, isMatchApproved, scanImageUrl, status]);

    const handleControlClick = useCallback(() => {
        setIsFindModalOpen(true);
        setIsMatchModalOpen(false);
    }, []);

    const Control = useMemo(() => (
        <div className={styles.onPicture}>
            <Button text='Change' type={ButtonTypes.micro} onClick={handleControlClick} />
        </div>
    ), []);

    const FieldsNodes = useMemo(() => PRODUCT_FIELDS_CONFIG.map((field) => {
        return (
            <ProductField
                readMode
                key={field.key}
                field={field}
                product={currentProduct}
            />
        );
    }), [currentProduct]);

    const handleYesClick = useCallback(() => {
        if (Boolean(id)) {
            fetchApprovedMatch();
        }

        // Не устанавливаем новую картинку продукту, если уже была установлена ранее
        if (Boolean(product?.productImages?.length)) return;

        if (Boolean(productId) && Boolean(scanImages?.[0].url)) {
            patchImage({
                id: Number(productId),
                url: scanImages![0].url,
            });
        }
    }, [id, fetchApprovedMatch, patchImage, productId, scanImages]);

    const handleNoClick = useCallback(() => {
        if (Boolean(id)) {
            fetchErrorMatch(null);
        }
    }, [id, fetchApprovedMatch]);

    useEffect(() => {
        if (typeof approvedMatch?.data === 'string' && !approvedMatch?.error) {
            if (!product?.productImages?.length) {
                if (typeof patchedImage?.data === 'string' && !patchedImage?.error) {
                    setIsMatchApproved(true);
                    onApprove();
                }
            } else {
                setIsMatchApproved(true);
                onApprove();
            }
        }
    }, [approvedMatch?.data, approvedMatch?.error, onApprove, patchedImage?.data, patchedImage?.error]);

    useEffect(() => {
        if (status === 'resolved') {
            setIsMatchApproved(true);
        } else {
            setIsMatchApproved(false);
        }
    }, [status, setIsMatchApproved]);

    useEffect(() => {
        if (typeof errorMatch?.data === 'string' && !errorMatch?.error) {
            setScan(null);
            setRefresh(true);
            setIsMatchModalOpen(false);
        }
    }, [setIsMatchModalOpen, setScan, errorMatch?.data, errorMatch?.error]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.pictures}>
                <div className={styles.left}>
                    <Picture url={scanImageUrl} isMainPicture hidePictureMenu />
                    <div className={styles.pictureSign}>
                        Scan ID:
                        <span> {id}</span>
                    </div>
                </div>
                <div className={styles.right}>
                    <Picture
                        url={productImageUrl}
                        cornerControl={Control}
                        isMainPicture
                        hidePictureMenu
                    />
                    <div className={styles.pictureSign}>
                        Product ID:
                        <span> {currentProduct?.id || productId}</span>
                    </div>
                </div>
            </div>
            {foundProduct === null && !isMatchApproved && (
                <div className={styles.buttons}>
                    <Button
                        text='No'
                        theme={ButtonThemes.secondary}
                        onClick={handleNoClick}
                        isLoading={errorMatch.isPending}
                    />
                    <Button
                        text='Yes'
                        onClick={handleYesClick}
                        isLoading={approvedMatch.isPending || patchedImage.isPending}
                    />
                </div>
            )}
            <div className={styles.textWrapper}>
                <div className={styles.left}>
                    <div className={styles.blockTitle}>
                        Recognized info
                    </div>
                    <div className={styles.info}>
                        <div className={styles.infoCard}>
                            <div className={styles.infoCardTitle}>Other information</div>
                            <div>
                                {Boolean(productSearchLog)
                                    ? (productSearchLog!.OCR.map(({word}, id) => (
                                        <div key={`ocr_${id}`}>{word}</div>
                                        ))
                                    )
                                    : ('No')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.blockTitle}>
                        Product Info
                    </div>
                    <div className={styles.info}>
                        {FieldsNodes}
                    </div>
                    {foundProduct !== null && (
                        <div className={styles.oneButton}>
                            <Button text='Approve match' onClick={onConfirm} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

interface IProps {
    scan?: TScan | null;
    onConfirm?: () => void;
    onApprove: () => void;
}
