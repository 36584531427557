import {memo, useEffect, useMemo} from 'react';

import {Button} from 'components/common/newButton';
import {useAppContext} from 'components/app/AppContext';
import {InformationNote} from 'components/blocks/informationNote/InformationNote';
import {useCities, useCityMatchInfo} from 'queries/score';
import {sortCitiesByScore} from 'core/utils';
import {USER_ROLES} from 'core/constants';
import CitiesImage from 'assets/images/cities.png';
import {CityToggler} from './cityToggler';
import styles from './TopCities.module.css';

export const TopCities = memo<IProps>(function TopCities({productId}) {
    const {setIsComingSoonModalOpen} = useAppContext();
    const [{data: cities, isPending: isPendingCities, error: citiesError}, fetchCities] = useCities();
    const [{data, error, isPending}, fetch] = useCityMatchInfo();
    // const canShowCities = useMemo(() => !!user?.role && [USER_ROLES.root, USER_ROLES].includes(user?.role), []);
    const canShowCities = true;

    useEffect(() => {
        if (canShowCities) {
            fetchCities();
        }
    }, [canShowCities]);

    useEffect(() => {
        if (!cities || !productId || !canShowCities) return;

        const dataForScorer = cities.map(({city}) => ({city, product: productId}));
        fetch(dataForScorer);
    }, [cities, canShowCities]);

    const citiesInfo = useMemo(() => {
        if (!data || !canShowCities) return null;

        return sortCitiesByScore(data);
    }, [data, canShowCities]);

    if (!!citiesError || !!error) {
        return null;
    }

    return (
        <div className={styles.wrapper} id='top-cities-block'>
            <div className={styles.texts}>
                <div className={styles.title}>Top Cities for this Product</div>
                <div className={styles.subtitle}>
                    Potential top cities worldwide to use this product under current conditions:
                </div>
            </div>
            {isPendingCities || isPending && (
                <div className={styles.info}>
                    <div className='pageloaderWrapper'>
                        <div className='pageloader'/>
                    </div>
                </div>
            )}
            {!!citiesInfo && (
                <div className={styles.info}>
                    <div>
                        {citiesInfo.map((info) => (
                            <CityToggler info={info} key={`top_city_${info.id}`} />
                        ))}
                    </div>
                    <InformationNote type='research' />
                </div>
            )}
            {!canShowCities && (
                <div className={styles.blurBlock}>
                    <div className={styles.actionBlock}>
                        <div className={styles.actionBlockButton}>
                            <Button
                                text='Subscribe to unlock'
                                onClick={() => setIsComingSoonModalOpen(true)}
                            />
                        </div>
                        <div className={styles.actionBlockText}>
                            This feature is only available
                            for&nbsp;premium subscription users.
                        </div>
                    </div>
                    <img src={CitiesImage} alt='' className={styles.pictureBlured} />
                </div>
            )}
        </div>
    );
});

interface IProps {
    productId: number | undefined;
}
