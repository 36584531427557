import {memo, useCallback, useEffect, useState} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Radio} from 'components/common/radio';
import {Button} from 'components/common/newButton';
import {useCreateSubscribe, useFreeAttempts} from 'queries/score';
import styles from './SubscriptionModal.module.css';

export const SubscriptionModal = memo(() => {
    const {setIsSubscriptionModalOpen, setRefreshBalance, setIsSuccessPaymentModalOpen, setIsPaymentDevelopingModalOpen} = useAppContext();
    const [checkedPlan, setCheckedPlan] = useState<number>();
    const [{data, isPending, error}, fetch] = useCreateSubscribe();
    const [{data: freeAttempts, isPending: isPendingFreeAttempts}, fetchFreeAttempts] = useFreeAttempts();
    const hasFree = freeAttempts !== null && freeAttempts > 0;

    const handleClose = useCallback(() => {
        setIsSubscriptionModalOpen(false);
    }, [setIsSubscriptionModalOpen]);

    const handleSubmitClick = useCallback(async () => {
        if (!!checkedPlan) {
            await fetch(checkedPlan);
        }

        if (!hasFree || checkedPlan !== 5) {
            setIsPaymentDevelopingModalOpen(true);
            handleClose();
        }
    }, [checkedPlan, hasFree]);

    useEffect(() => {
        if (!!data && !isPending && !error && hasFree && checkedPlan === 5) {
            setIsSuccessPaymentModalOpen(true);
            handleClose();
        }
    }, [data, isPending, error, hasFree, checkedPlan]);

    useEffect(() => {
        fetchFreeAttempts();
    }, []);

    return (
        <Modal
            onClose={handleClose}
            title='Upgrade your account and get more Product to City Requests'
            width='460px'
        >
            {isPendingFreeAttempts
            ? (
                <form className={styles.loaderWrapper}>
                    <div className='pageloaderWrapper'>
                        <div className='pageloader'/>
                    </div>
                </form>
            )
            : (

                <form className={styles.wrapper}>
                    <div className={styles.options}>
                        <Radio
                            bold='5'
                            text={hasFree ? 'Requests (Only for Early Adopters)' : 'Requests'}
                            price={hasFree ? 'Free' : '$24.99'}
                            isChecked={checkedPlan === 5}
                            onCheck={() => setCheckedPlan(5)}
                        />
                        <Radio
                            bold='12'
                            text='Requests'
                            price='$59.99'
                            isChecked={checkedPlan === 12}
                            onCheck={() => setCheckedPlan(12)}
                        />
                        <Radio
                            bold='50'
                            text='Requests'
                            price='$249.99'
                            isChecked={checkedPlan === 50}
                            onCheck={() => setCheckedPlan(50)}
                        />
                        <Radio
                            bold='200'
                            text='Requests'
                            price='$999.99'
                            isChecked={checkedPlan === 200}
                            onCheck={() => setCheckedPlan(200)}
                        />
                    </div>
                    <Button
                        text='Continue'
                        onClick={handleSubmitClick}
                        isLoading={isPending}
                        isDisabled={!checkedPlan}
                    />
                </form>
            )}
        </Modal>
    );
});
