import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {usePatchScan} from 'queries/scans';
import {Content} from './Content';
import {ReactComponent as DeleteIcon} from 'assets/images/delete.svg';
import styles from '../editProductModal/EditProductModal.module.css';

export const MatchModal = memo(() => {
    const {setIsMatchModalOpen, scan, setScan, setRefresh, product, setIsSelectModalOpen, setProduct} = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isMatchApproved, setIsMatchApproved] = useState<boolean>(false);
    const [{data}, fetch] = usePatchScan();

    const handleClose = useCallback(() => {
        setIsMatchModalOpen(false);
        setScan(null);
        setProduct(null);
    }, []);
    const handleConfirm = useCallback(() => {
        setIsConfirmModalOpen(true);
    }, []);
    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);
        if (scan?.id && product?.id) {
            fetch({scanId: scan.id, productId: product.id});
        }
    }, []);
    const handleDelete = useCallback(() => {
        setScan(scan);
        setIsSelectModalOpen(true);
    }, [setScan, scan]);
    const handleApprove = useCallback(() => {
        setIsMatchApproved(true);
        setRefresh(true);
    }, [setScan, scan]);

    useEffect(() => {
        if (typeof data === 'string') {
            setIsMatchModalOpen(false);
            setRefresh(true);
        }
    }, [data]);

    const ContentNode = useMemo(() => (
        <Content
            scan={scan}
            onConfirm={handleConfirm}
            onApprove={handleApprove}
        />
    ), [scan]);

    const extraControls = [
        <div
            className={styles.action}
            onClick={handleDelete}
            key={'match_modal_delete_icon'}
        >
            <DeleteIcon />
        </div>,
    ];

    useEffect(() => {
        if (scan?.status === 'resolved') {
            setIsMatchApproved(true);
        } else {
            setIsMatchApproved(false);
        }
    }, [scan, setIsMatchApproved]);

    return (
        <>
            <Modal
                onClose={handleClose}
                title={isMatchApproved ? 'This match was approved!': 'Is that a right match?'}
                extraHeaderControls={extraControls}
                borderUnderTitle
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                />
            )}
        </>
    );
});
