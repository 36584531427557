import {useState} from 'react';

import {useRequest} from 'core/hooks';
import {
    TErrorsCount,
    TProduct,
    TSearchInput,
    TPatchImage,
    TProductProperties,
    TSearchProduct,
    TProductSort,
    TDeleteItem,
} from 'core/types';
import {MAX_ITEM_COUNT_FOR_PAGE} from 'core/constants';

const PRODUCTS_BASE_URL = '/products';

export const useProducts = () =>
    useRequest<TProductSort, Array<TProduct>>(({offset, order, group}) => ({
        url: PRODUCTS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                order: order || 'id desc',
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                where: {
                    productGroup: group,
                },
                include: [
                    'productImages',
                    'citiesInfos',
                    {
                        relation: 'scans',
                        scope: {
                            limit: 12,
                            include: [
                                {
                                    'relation': 'scanImages',
                                },
                            ],
                            where: {
                                and: [
                                    {
                                        isMisstake: false,
                                    },
                                    {
                                        inProgress: false,
                                    },
                                ],
                            },
                        },
                    },
                ],
            },
        },
    }), true);

export const useProductsCount = () =>
    useRequest<string, TErrorsCount>((group) => ({
        url: `${PRODUCTS_BASE_URL}/count`,
        method: 'GET',
        params: {
            where: {
                productGroup: group,
            },
        }
    }), true);

export const useDeleteProduct = (id: number) =>
    useRequest<TDeleteItem, string>((body) => ({
        url: `${PRODUCTS_BASE_URL}/${id}`,
        method: 'DELETE',
        ...(Boolean(body.deletedReason) && {
            data: body
        }),
    }), true);

export const useDeleteProducts = () =>
    useRequest<TDeleteItem, {count: number}>((body) => ({
        url: PRODUCTS_BASE_URL,
        method: 'DELETE',
        params: {
            where: {
                // @ts-ignore
                id: {inq: body.ids},
            },
        },
        ...(Boolean(body.deletedReason) && {
            data: {deletedReason: body.deletedReason},
        }),
    }), true);

// export const useCreateProduct = () =>
//     useRequest<TProduct, TProduct>((product) => ({
//         url: PRODUCTS_BASE_URL,
//         method: 'POST',
//         data: product,
//     }), true);

export const usePutProduct = () =>
    useRequest<TProduct, void>((product) => ({
        url: `${PRODUCTS_BASE_URL}/${product.id}`,
        method: 'PATCH',
        data: product,
    }), true);

export const useFoundProducts = () =>
    useRequest<TSearchProduct, Array<TProduct>>(({text, brand}) => {
        const whereClause = Boolean(brand)
            ? ({
                and: [
                    {
                        name: {
                            like: `%${text}%`,
                        },
                    },
                    {
                        brand: {
                            eq: String(brand),
                        },
                    },
                ],
            })
            : ({
                name: {
                    like: `%${text}%`,
                },
            });

        return {
            url: PRODUCTS_BASE_URL,
            method: 'GET',
            params: {
                filter: {
                    order: 'id desc',
                    limit: 3,
                    where: whereClause,
                    include: [
                        'productImages',
                        'citiesInfos',
                        {
                            relation: 'scans',
                            scope: {
                                limit: 12,
                                include: [
                                    {
                                        relation: 'scanImages',
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        };
    }, true);

export const useSearchItems = () =>
    useRequest<TSearchInput, Array<TProduct>>(({text, limit}) => ({
        url: `${PRODUCTS_BASE_URL}/search/list`,
        method: 'GET',
        params: {
            text: text,
            filter: {
                offset: 0,
                limit: limit,
                order: 'id desc',
                include: [
                    'productImages',
                    'citiesInfos',
                    {
                        relation: 'scans',
                        scope: {
                            limit: 12,
                            include: [
                                {
                                    relation: 'scanImages',
                                },
                            ],
                        },
                    },
                ],
            }
        },
    }), true);

export const usePatchProductImage = () =>
    useRequest<TPatchImage, string>(({id, url}) => ({
        url: `${PRODUCTS_BASE_URL}/${id}`,
        method: 'PATCH',
        data: {
            //@ts-ignore
            productImages: [
                {
                    url: url,
                },
            ],
        }
    }), true);

export const useDeleteProductImage = () =>
    useRequest<TPatchImage, void>(({id}) => ({
        url: `${PRODUCTS_BASE_URL}/${id}/product-images`,
        method: 'DELETE',
    }), true);

export const usePostProductImage = () =>
    useRequest<TPatchImage, void>(({id, url}) => ({
        url: `${PRODUCTS_BASE_URL}/${id}/product-images`,
        method: 'POST',
        data: {
            url: url,
        }
    }), true);

export const useProductsProperties = () =>
    useRequest<void, TProductProperties>(() => ({
        url: `${PRODUCTS_BASE_URL}/properties`,
        method: 'GET',
    }), true);

export const useProduct = () =>
    useRequest<number, TProduct>((id) => ({
        url: `${PRODUCTS_BASE_URL}/${id}`,
        method: 'GET',
        params: {
            filter: {
                include: [
                    'productImages',
                    {
                        relation: 'scans',
                        scope: {
                            limit: 12,
                            include: [
                                {
                                    'relation': 'scanImages',
                                },
                            ],
                            where: {
                                isMisstake: false,
                            },
                        },
                    },
                ],
            },
        },
    }), true);

export const useSmartCreateProduct = () => {
    const [isPending, setIsPending] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const makeRequest = async (file: File | undefined, product: TProduct) => {
        setIsPending(true);

        const formData = new FormData();

        if (!!file) {
            formData.append('file', file);
        }

        Object.entries(product).forEach(([key, value]) => {
            const convertedValue = typeof value === 'string' ? value : JSON.stringify(value);
            formData.append(key, convertedValue);
        });

        const savedToken = localStorage.getItem('token');

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}${PRODUCTS_BASE_URL}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${savedToken}`,
                },
            });
            const info = await res.json();
            setData(info);
        } catch (err) {
            // @ts-ignore
            setError(err);
        } finally {
            setIsPending(false);
        }
    };

    return [{data, isPending, error}, makeRequest];
}

export const useCreateProductImage = () => {
    const [isPending, setIsPending] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const makeRequest = async (id: number, file: File) => {
        setIsPending(true);

        const formData = new FormData();
        formData.append('file', file);
        const savedToken = localStorage.getItem('token');
    
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}${PRODUCTS_BASE_URL}/${id}/product-images`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${savedToken}`,
                },
            });
            const info = await res.json();
            setData(info);
        } catch (err) {
            // @ts-ignore
            setError(err);
        } finally {
            setIsPending(false);
        }
    };

    return [{data, isPending, error}, makeRequest];
}

export const useProductScansCount = () =>
    useRequest<number, number>((productId) => ({
        url: '/scans/count',
        method: 'GET',
        params: {
            where: {
                productId: productId,
            },
        }
    }), true);

export const useProductFeedbackCount = () =>
    useRequest<number, {count: number}>((productId) => ({
        url: '/feedbacks/count',
        method: 'GET',
        params: {
            where: {
                productId: productId,
            },
        }
    }), true);
