import {CityScoreConfig, ProductFieldType, ScoreConfigRow, ScoreFieldObject, ScoreFieldTypes, TProductField} from 'core/types';
import { ROUTES, USER_ROLES } from './constants';

export const PRODUCT_FIELDS_CONFIG: Array<TProductField> = [
    {
        key: 'brand',
        label: 'Brand',
        isRequired: true,
        type: ProductFieldType.text,
        notEditableForBrand: true,
    },
    {
        key: 'name',
        label: 'Product name',
        isRequired: true,
        type: ProductFieldType.text,
    },
    {
        key: 'description',
        label: 'Description',
        isRequired: true,
        type: ProductFieldType.text,
    },
    {
        key: 'productCategory',
        label: 'Product category',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'gender',
        label: 'Product best for gender',
        isRequired: true,
        type: ProductFieldType.oneFromTags,
    },
    {
        key: 'consistence',
        label: 'Product consistence',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'bodyParts',
        label: 'Body parts',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'ageGroup',
        label: 'Age group',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'skinTypes',
        label: 'Skin types',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'skinSensitiveness',
        label: 'Skin sensitiveness',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'positiveEffects',
        label: 'Positive effects',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'spf',
        label: 'SPF',
        isRequired: true,
        type: ProductFieldType.oneFromTags,
    },
    {
        key: 'keyIngredients',
        label: 'Key ingredients',
        isRequired: false,
        type: ProductFieldType.formattedText,
    },
    {
        key: 'ingredients',
        label: 'All ingredients',
        isRequired: false,
        type: ProductFieldType.formattedText,
        wrap: true,
    },
    {
        key: 'howToUse',
        label: 'How to use',
        isRequired: false,
        type: ProductFieldType.text,
    },
    {
        key: 'timeToUse',
        label: 'Time to use',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'warning',
        label: 'Warning',
        isRequired: false,
        type: ProductFieldType.text,
    },
    {
        key: 'productAvailability',
        label: 'Product availability',
        isRequired: true,
        type: ProductFieldType.tags,
    },
    {
        key: 'citiesInfos',
        label: 'Available in',
        isRequired: true,
        type: ProductFieldType.tags,
        visibilityConfig: {
            dependField: 'productAvailability',
            visibilityValue: 'In production'
        },
    },
];

export const SCORE_FIELDS_CONFIG: Array<ScoreConfigRow> = [
    {
        left: [{keys: ['brand'], title: 'Brand', type: ScoreFieldTypes.text, where: ScoreFieldObject.product}],
        right: [{keys: ['id'], title: 'User ID', type: ScoreFieldTypes.text, where: ScoreFieldObject.user}],
    },
    {
        left: [
            {
                keys: ['name', 'id'],
                title: 'Product name',
                type: ScoreFieldTypes.modal,
                where: ScoreFieldObject.product,
                format: (name, id) => {
                    if (!name) return id;
                    return name;
                },
            },
        ],
        right: [
            {
                keys: ['country', 'city', 'lat', 'lon'],
                title: 'Location',
                type: ScoreFieldTypes.text,
                where: ScoreFieldObject.user,
                format: (lon, lat, city, country) => {
                    if (country) {
                        if (city) {
                            if (lat && lon) {
                                return `${city}, ${country} (${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)})`;
                            } else {
                                return `${city}, ${country}`;
                            }
                        } else {
                            return `${country} (${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)})`;
                        }
                    }

                    return `${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)}`;
                },
            },
        ],
    },
    {
        left: [
            {
                keys: ['time_to_use'],
                title: 'When to use',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['timezone'],
                title: 'Time',
                type: ScoreFieldTypes.text,
                where: ScoreFieldObject.user,
                format: (zone) => {
                    const value = (new Date()).toLocaleTimeString('ru-RU', {timeZone: zone, hour: '2-digit', minute:'2-digit'});
                    return `${value} (${zone})`;
                },
            },
        ],
    },
    {
        left: [
            {
                keys: ['water'],
                title: 'Water',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                format: (value) => {
                    const strValue = String(value);
                    if (strValue.includes('ppm') || !Number(value)) return strValue;
                    return `${strValue} ppm`;
                },
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['water'],
                title: 'Water',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                format: (value) => {
                    const strValue = String(value);
                    if (strValue.includes('ppm') || !Number(value)) return strValue;
                    return `${strValue} ppm`;
                },
                isMatchable: true,
            },
        ],
    },
    {
        left: [
            {
                keys: ['spf'],
                title: 'SPF',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
                format: (value) => !Number(value) ? value : `SPF ${Number(value) === 0 ? 'No' : value}`,
            },
        ],
        right: [
            {
                keys: ['temperature', 'humidity', 'uv_index'],
                prefixes: ['T', 'H', 'UV'],
                title: 'Weather',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
                format: (prefix, init) => {
                    let value = init;
                    if (prefix === 'T') {
                        value = `${Math.round(Number(init))}°C`;
                    }
                    if (prefix === 'H') {
                        value = `${(Number(init) * 100).toFixed(0)}%`;
                    }
                    return `${prefix}: ${value}`;
                },
            },
        ],
    },
    {
        left: [
            {
                keys: ['age_group'],
                title: 'Age group',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['age'],
                title: 'Age',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
        ],
    },
    {
        left: [
            {
                keys: ['skin_types'],
                title: 'Skin type',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['skin_type'],
                title: 'Skin type',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
        ],
    },
    {
        left: [
            {
                keys: ['skin_sensitiveness'],
                title: 'Sensitiveness',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['skin_sensitive'],
                title: 'Sensitiveness',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
        ],
    },
    {
        left: [
            {
                keys: ['positive_effects'],
                title: 'Positive effects',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.product,
                isMatchable: true,
            },
        ],
        right: [
            {
                keys: ['skin_goals'],
                title: 'Skin goals',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
            {
                keys: ['skin_concerns'],
                title: 'Skin concerns',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
        ],
    },
    {
        left: [
            {
                keys: ['ingredients'],
                title: 'Ingredients',
                type: ScoreFieldTypes.text,
                where: ScoreFieldObject.product,
                isMatchable: true,
                wrap: true,
            },
        ],
        right: [
            {
                keys: ['ingredient_skip'],
                title: 'Avoided ingredients',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
            {
                keys: ['ingredient_preferences'],
                title: 'Preferred ingredients',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            },
            {
                keys: ['allergic_reactions'],
                title: 'Allergic reactions',
                type: ScoreFieldTypes.tags,
                where: ScoreFieldObject.user,
                isMatchable: true,
            }
        ],
    },
];

export const PRODUCT_CATEGORIES = [
    'All',
    'Face',
    'Masks',
    'MakeUp',
    'Haircare',
    'Body',
    'SPF',
    'Fragrances',
];

export const CITY_MATCH_SCORE_CONFIG: CityScoreConfig = {
    left: [
        {
            title: 'Product information:',
            noteType: 'ingredients',
            fields: [
                {
                    keys: ['brand', 'name'],
                    title: 'Product name',
                    type: ScoreFieldTypes.modal,
                    where: ScoreFieldObject.product,
                    format: (brand, name) => {
                        return brand + '\n' + name;
                    },
                },
                {
                    keys: ['time_to_use'],
                    title: 'When to use',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['water'],
                    title: 'Water',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    format: (value) => {
                        return String(value);
                    },
                    isMatchable: true,
                },
                {
                    keys: ['spf'],
                    title: 'SPF',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                    format: (value) => !Number(value) ? value : `SPF ${Number(value) === 0 ? 'No' : value}`,
                },
                {
                    keys: ['product_category'],
                    title: 'Category',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['gender'],
                    title: 'Product best for gender',
                    type: ScoreFieldTypes.tagFromText,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['age_group'],
                    title: 'Age group',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['skin_types'],
                    title: 'Skin type',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['skin_sensitiveness'],
                    title: 'Sensitiveness',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['positive_effects'],
                    title: 'Positive effects',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                },
                {
                    keys: ['ingredients'],
                    title: 'Ingredients',
                    type: ScoreFieldTypes.text,
                    where: ScoreFieldObject.product,
                    isMatchable: true,
                    wrap: true,
                },
                {
                    keys: ['allegrens'],
                    title: 'FDA Allergens',
                    type: ScoreFieldTypes.tags,
                    where: null,
                    isMatchable: true,
                },
                {
                    keys: ['restricted_ingredients'],
                    title: 'Restricted ingredients',
                    type: ScoreFieldTypes.tags,
                    where: null,
                    isMatchable: true,
                },
            ],
        },
    ],
    right: [
        {
            title: 'City information:',
            fields: [
                {
                    keys: ['country', 'city', 'lat', 'lon'],
                    title: 'Location',
                    type: ScoreFieldTypes.text,
                    where: ScoreFieldObject.city,
                    format: (lon, lat, city, country) => {
                        if (country) {
                            if (city) {
                                if (lat && lon) {
                                    return `${city}, ${country}` + '\n' + `(${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)})`;
                                } else {
                                    return `${city}, ${country}`;
                                }
                            } else {
                                return `${country} (${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)})`;
                            }
                        }

                        return `${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)}`;
                    },
                },
                {
                    keys: ['timezone'],
                    title: 'Time',
                    type: ScoreFieldTypes.text,
                    where: ScoreFieldObject.city,
                    format: (zone) => {
                        const value = (new Date()).toLocaleTimeString('ru-RU', {timeZone: zone, hour: '2-digit', minute:'2-digit'});
                        return `${value} (${zone})`;
                    },
                },
                {
                    keys: ['water'],
                    title: 'Water',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.city,
                    format: (value) => {
                        return String(value);
                    },
                    isMatchable: true,
                },
                {
                    keys: ['temperature', 'humidity', 'uv_index'],
                    prefixes: ['T', 'H', 'UV'],
                    title: 'Weather',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.city,
                    isMatchable: true,
                    format: (prefix, init) => {
                        let value = init;
                        if (prefix === 'T') {
                            value = `${Math.round(Number(init))}°C`;
                        }
                        if (prefix === 'H') {
                            value = `${(Number(init) * 100).toFixed(0)}%`;
                        }
                        return `${prefix}: ${value}`;
                    },
                },
            ],
        },
        {
            title: 'Possible ingredients conflict with the city:',
            fields: [
                {
                    keys: ['water_conflict'],
                    title: 'Water conflict',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.ingredients_info_for_city,
                    isMatchable: true,
                },
                {
                    keys: ['uv_conflict'],
                    title: 'UV conflict',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.ingredients_info_for_city,
                    isMatchable: true,
                },
                {
                    keys: ['humidity_conflict'],
                    title: 'Humidity conflict',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.ingredients_info_for_city,
                    isMatchable: true,
                },
                {
                    keys: ['temperature_conflict'],
                    title: 'Temperature conflict',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.ingredients_info_for_city,
                    isMatchable: true,
                },
            ],
        },
        {
            title: 'Typical customer profiles in the city:',
            fields: [
                {
                    keys: ['gender'],
                    title: 'Gender',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['age'],
                    title: 'Age group',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['skin_type'],
                    title: 'Skin types',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['skin_sensitive'],
                    title: 'Skin sensitives',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['skin_concerns'],
                    title: 'Skin concerns',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['skin_goals'],
                    title: 'Skin goals',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['ingredient_preferences'],
                    title: 'Popular ingredients',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
                {
                    keys: ['allergic_reactions'],
                    title: 'Possible allergic reactions',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.audience_features,
                    isMatchable: true,
                    takeFromCustomIndex: true,
                },
            ],
        },
        {
            title: 'Latest skincare trends in the city:',
            fields: [
                {
                    keys: ['top_categories'],
                    title: 'Top categories',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.trends,
                    isMatchable: true,
                },
                {
                    keys: ['popular_claims'],
                    title: 'Popular claims',
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.trends,
                    isMatchable: true,
                },
            ],
        },
        {
            title: 'Local brands in the city:',
            fields: [
                {
                    keys: ['local_brands'],
                    title: null,
                    type: ScoreFieldTypes.tags,
                    where: ScoreFieldObject.city,
                    isMatchable: true,
                },
            ],
        },
    ],
};

export const ROLE_ALLOW_ROUTES_CONFIG = {
    [USER_ROLES.brand]: [ROUTES.PRODUCTS, ROUTES.HOME, ROUTES.CITY_MATCH],
    [USER_ROLES.root]: [],
    [USER_ROLES.admin]: [],
    // TODO: добавить роли остальные
}
