import {memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';

import {useOutsideClicker} from 'core/hooks';
import {USER_ROLES} from 'core/constants';
import {useCreateProductImage} from 'queries/products';
import {useAppContext} from 'components/app/AppContext';
import {Button, ButtonTypes} from 'components/common/newButton';
import {ReactComponent as MoreIcon} from 'assets/images/more.svg';
import styles from './Picture.module.css';

export const Picture = memo<IProps>(function Picture({
    id,
    url,
    isMainPicture = false,
    cornerControl,
    hidePictureMenu = false,
    onChange,
    onDelete,
    onClick,
    isActive = false,
    productId,
    setTempImage: setExternalTempImage,
    availability,
}) {
    // @ts-ignore
    const [{data, isPending, error}, fetch] = useCreateProductImage();
    const [isError, setIsError] = useState<boolean>(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const classes = cn(
        isMainPicture ? styles.wrapperMain : styles.wrapper,
        {[styles.active]: isActive},
    );
    const dropdownRef = useRef(null);
    const {user, setRefresh} = useAppContext();

    const pictureUrl = useMemo(() => url || data?.url, [url, data]);

    const canUploadProductImage = useMemo(() => {
        return isMainPicture && !pictureUrl && (!!user?.role && [USER_ROLES.brand, USER_ROLES.root, USER_ROLES.admin].includes(user.role));
    }, [isMainPicture, pictureUrl, user?.role, productId]);

    // @ts-ignore
    const handleImageInputChange = useCallback((event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // If isEditMode, create image separately from changing product info
        if (!!productId) {
            //@ts-ignore
            fetch(productId, file);
            return;
        }

        setExternalTempImage?.(file);
    }, []);

    useOutsideClicker(dropdownRef, () => {setIsDropdownOpen(false)});

    useEffect(() => {
        if (!!data?.url) {
            setRefresh(true);
        }
    }, [data?.url]);

    return (
        <div className={classes}>
            {canUploadProductImage && (
                <div className={styles.addImageButton}>
                    {isPending
                    ? (
                        <div className='pageloaderWrapper'>
                            <div className='pageloader'/>
                        </div>
                    )
                    : (
                        <>
                            <label className={styles.addImageLabel} htmlFor='uploadForm_file'>
                                <Button text='+ Add image' onClick={() => {}} type={ButtonTypes.micro} />
                                <input
                                    className={styles.addImageSelector}
                                    id='uploadForm_File'
                                    type='file'
                                    name='file_name'
                                    accept='image/*'
                                    onChange={handleImageInputChange}
                                />
                            </label>
                            <div className={styles.instructions}>
                                Upload a photo that clearly shows the&nbsp;product with label and name on it.
                            </div>
                        </>
                    )}
                </div>
            )}
            {isError && (
                <>
                    <div className={styles.tag}>
                        {availability}
                    </div>
                    <div className={styles.noPhoto}>Failed to load product image</div>
                </>
            )}
            {(!!pictureUrl && !isError) ? (
                <>
                    <div className={styles.tag}>
                        {availability}
                    </div>
                    {!hidePictureMenu && isLoaded && (
                        <>
                            <div
                                className={styles.menu}
                                onClick={() => setIsDropdownOpen(true)}
                            >
                                <MoreIcon />
                            </div>
                            {isDropdownOpen && (
                                <>
                                    <div
                                        className={styles.dropdown}
                                        ref={dropdownRef}
                                    >
                                        <div
                                            className={styles.dropdownItem}
                                            onClick={() => {
                                                onChange?.();
                                                setIsDropdownOpen(false);
                                            }}
                                        >
                                            {`Make ${isMainPicture ? 'uncover' : 'cover'}`}
                                        </div>
                                        {Boolean(onDelete) && (
                                            <div
                                                className={styles.dropdownItem}
                                                onClick={() => {
                                                    onDelete?.();
                                                    setIsDropdownOpen(false);
                                                }}
                                            >
                                                Delete and report
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className={cn(styles.skeleton, {
                        [styles.skeletonHidden]: isLoaded
                    })}/>
                    <img
                        src={pictureUrl}
                        onLoad={() => setIsLoaded(true)}
                        alt='product_picture_or_scan'
                        className={cn({
                            [styles.imageHidden]: !isLoaded,
                            [styles.clickable]: !isMainPicture && !!onClick,
                        })}
                        onClick={() => !isMainPicture && !!pictureUrl && !!id && onClick?.({url: pictureUrl, id})}
                        onError={() => {
                            setIsError(true);
                            setIsLoaded(true);
                        }}
                    />
                    {cornerControl}
                </>
            ) : (!canUploadProductImage && !isError) && (
                <>
                    <div className={styles.noPhoto}>There is no product cover yet</div>
                    {cornerControl}
                </>
            )}
        </div>
    );
});

interface IProps {
    id?: number | null;
    url: string | null;
    isMainPicture?: boolean;
    cornerControl?: ReactElement;
    hidePictureMenu?: boolean;
    onChange?: () => void;
    onDelete?: () => void;
    onClick?: ({url, id}: {url: string, id: number}) => void;
    isActive?: boolean;
    productId?: number;
    setTempImage?: (file: File) => void;
    availability?: ReactElement;
}
