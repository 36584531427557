import {memo} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

import {mockDatasets} from './mockData';

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                color: 'black',
                font: {
                    size: 10,
                },
            },
        },
        y: {
            grid: {
                display: false,
            },
            title: {
                display: true,
                text: 'Ads',
            },
            ticks: {
                color: 'black',
                font: {
                    size: 10,
                },
            },
        },
    },
    plugins: {
        legend: {
            align: 'end' as const,
            labels: {
                boxWidth: 14,
                boxHeight: 14,
                generateLabels: () => ([
                    {
                        text: 'Real data',
                        fillStyle: '#BCE1EA',
                        strokeStyle: '#BCE1EA',
                    },
                    {
                        text: 'Predicted data',
                        fillStyle: '#FFE896',
                        strokeStyle: '#FFE896',
                    },
                ]),
            },
        },
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const HorizontalBarChart = memo<IProps>(({data, labels}) => {
    const combined = {
        ...mockDatasets,
        datasets: mockDatasets.datasets.map((dts, idx) => ({...dts, data: data[idx]})),
        labels,
    };

    return (
        <Bar
            options={options}
            data={combined}
        />
    );
});

interface IProps {
    data: Array<unknown>;
    labels: Array<string>;
}
