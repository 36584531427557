import {memo, useMemo, useState, useEffect} from 'react';
import cn from 'classnames';

import {PRODUCT_LIST_HEADERS} from 'core/constants';
import {TProduct} from 'core/types';
import {ProductRow} from 'components/blocks/productRow';
import {useAppContext} from 'components/app/AppContext';
import {Checkbox} from 'components/common/checkbox';
import styles from './ProductList.module.css';

export const ProductList = memo<IProps>(({
    items,
}) => {
    const {setSort, sort, setRefresh, setAllProductChecked, allProductChecked, setDeletingIds} = useAppContext();
    const [cached, setCached] = useState<Array<TProduct>>([]);

    const RowsNodes = useMemo(() => {
        return cached.map((item) => {
            return (
                <ProductRow
                    product={item}
                    key={`${item.name}_${item.id}`}
                />
            );
        });
    }, [cached]);

    const CheckboxHeaderNode = useMemo(() => (
        <th>
            <Checkbox
                onClick={() => {
                    const newValue = !allProductChecked;
                    if (newValue) {
                        setDeletingIds(items.map(({id}) => Number(id)));
                    } else {
                        setDeletingIds([]);
                    }
                    setAllProductChecked(newValue);
                }}
                checked={allProductChecked}
            />
        </th>
    ), [setAllProductChecked, allProductChecked, setDeletingIds, items]);

    const HeaderNodes = useMemo(() => {
        return PRODUCT_LIST_HEADERS.map((name, index) => {
            const isClickable = name === 'Prod. ID' || name === 'Brand' || name === 'Name';

            return (
                <th
                    key={`${name}_${index}_${sort?.order}`}
                    className={cn({[styles.clickable]: isClickable})}
                    onClick={() => {
                        if (!isClickable) return;

                        const {field, order} = sort || {};

                        if (name === 'Prod. ID') {
                            if (!field || field !== 'id' || order === 'id desc') {
                                setSort({field: 'id', order: 'id asc'});
                            } else {
                                setSort({field: 'id', order: 'id desc'});
                            }
                        }

                        if (name === 'Brand') {
                            if (!field || field !== 'brand' || order === 'brand desc') {
                                setSort({field: 'brand', order: 'brand asc'});
                            } else {
                                setSort({field: 'brand', order: 'brand desc'});
                            }
                        }

                        if (name === 'Name') {
                            if (!field || field !== 'name' || order === 'name desc') {
                                setSort({field: 'name', order: 'name asc'});
                            } else {
                                setSort({field: 'name', order: 'name desc'});
                            }
                        }

                        setRefresh(true);
                    }}
                >
                    {name}
                </th>
            );
        });
    }, [setSort, sort, setRefresh]);

    useEffect(() => {
        if (!cached.length) {
            setCached(items);
            return;
        }

        setCached(items);
    }, [items]);

    if (!items.length) {
        return (
            <div className={styles.notFoundWrapper}>
                <div className={styles.notFoundTitle}>
                    No products :(
                </div>
                <div className={styles.notFoundText}>
                    Maybe products with this filter don’t exist in our database.
                    Try another one.
                </div>
            </div>
        );
    }

    return (
        <>
            <table className={styles.list}>
                <thead>
                    <tr>
                        {CheckboxHeaderNode}
                        {HeaderNodes}
                    </tr>
                </thead>
                <tbody>
                    {RowsNodes}
                </tbody>
            </table>
        </>
    );
});

interface IProps {
    items: Array<TProduct>;
}
