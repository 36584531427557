import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {CancelModal} from 'components/blocks/cancelModal';
import {TProduct} from 'core/types';
import {usePostProductImage, useProductsProperties, useSmartCreateProduct} from 'queries/products';
import {usePatchScan} from 'queries/scans';
import {Content} from './Content';

export const CreateWithScanModal = memo(() => {
    const {setIsCreateWithScanModalOpen, scan, setIsSaveErrorModalOpen, setIsSuccessSaveModalOpen} = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
    const [editedProduct, setEditedProduct] = useState<TProduct>();

    const [createdData, fetchCreate] = useSmartCreateProduct();
    const [patchedScanImage, fetchPatchScan] = usePatchScan();
    const [createdProductImage, fetchCreateProductImage] = usePostProductImage();
    const [{data: properties}, fetchProperties] = useProductsProperties();

    const handleClose = useCallback(() => {
        setIsCreateWithScanModalOpen(false);
    }, []);
    const handleConfirm = useCallback(() => {
        setIsConfirmModalOpen(true);
    }, []);
    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);

        if (!editedProduct) return;

        //@ts-ignore
        const spfValue = Array.isArray(editedProduct.spf) ? editedProduct.spf[0] : editedProduct.spf;
        const transformatedCopy = {
            ...editedProduct,
            spf: spfValue === 'No' ? 0 : Number(spfValue),
            //@ts-ignore
            ingredients: (typeof editedProduct.ingredients === 'string' ? editedProduct.ingredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.ingredients) || [],
            //@ts-ignore
            keyIngredients: (typeof editedProduct.keyIngredients === 'string' ? editedProduct.keyIngredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.keyIngredients) || [],
            warning: editedProduct.warning || '',
            citiesInfos: editedProduct.citiesInfos?.map((text) => ({city: text})) || [],
        };

        //@ts-ignore
        fetchCreate(undefined, transformatedCopy);
    }, [editedProduct]);
    const handleCancel = useCallback(() => {
        if (editedProduct && Object.values(editedProduct as object).some((elem) => Boolean(elem.length))) {
            setIsCancelModalOpen(true);
        } else {
            handleClose();
        }
    }, [editedProduct]);
    const handleCancelOk = useCallback(() => {
        setIsCancelModalOpen(false);
        handleClose();
    }, []);

    const ContentNode = useMemo(() => (
        <Content
            scan={scan}
            properties={properties}
            onConfirm={handleConfirm}
            onEdit={setEditedProduct}
            onCancel={handleCancel}
        />
    ), [scan, properties, handleConfirm, setEditedProduct]);

    useEffect(() => {
        //@ts-ignore
        if (Boolean(createdData.data) && !createdData.error && !createdData.isPending
            //@ts-ignore
            && Boolean(scan?.id) && Boolean(createdData.data?.id)) {
            //@ts-ignore
            fetchPatchScan({scanId: scan!.id, productId: Number(createdData.data!.id)});
        }
    }, [createdData, scan]);

    useEffect(() => {
        if (typeof patchedScanImage?.data === 'string' && !patchedScanImage.error && !patchedScanImage.isPending) {
            //@ts-ignore
            if (!scan?.scanImages?.[0]?.url || !createdData?.data?.id) return;

            fetchCreateProductImage({
                url: scan.scanImages[0].url,
                //@ts-ignore
                id: Number(createdData?.data?.id),
            });
        }
        //@ts-ignore
    }, [patchedScanImage, createdData?.data]);

    useEffect(() => {
        if (Boolean(createdProductImage.data) && !createdProductImage.error && !createdProductImage.isPending) {
            setIsSuccessSaveModalOpen(true);
            handleClose();
        }
    }, [createdProductImage, setIsSuccessSaveModalOpen]);

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        //@ts-ignore
        if (Boolean(createdData?.error)) {
            setIsSaveErrorModalOpen(true);
        }
        //@ts-ignore
    }, [createdData?.error]);

    return (
        <>
            <Modal
                onClose={handleCancel}
                title='Create a new product'
                borderUnderTitle
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                />
            )}
            {isCancelModalOpen && (
                <CancelModal
                    onOk={handleCancelOk}
                    onCancel={() => setIsCancelModalOpen(false)}
                />
            )}
        </>
    );
});
