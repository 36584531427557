import {memo, useMemo, ReactElement, useCallback, useEffect} from 'react';
import cn from 'classnames';

import {ReactComponent as CloseIcon} from 'assets/images/close.svg';
import styles from './Modal.module.css';

export const Modal = memo<IProps>(function Modal({
    onClose,
    title,
    description,
    borderUnderTitle = false,
    children,
    extraHeaderControls,
    width,
}) {
    const isSmallModal = useMemo(() => !borderUnderTitle, [borderUnderTitle]);

    const wrapperClasses = isSmallModal ? styles.short : styles.wrapper;
    const actionsClasses = cn(
        styles.actions,
        {[styles.actionsWide]: isSmallModal},
    );

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        document.body.classList.add('overflowed');
        return () => {
            if (document.querySelectorAll('[data-section="overlay"]')?.length > 0) {
                return;
            }

            document.body.classList.remove('overflowed');
        };
    }, []);

    const inlineStyles = !!width ? {width} : {};

    return (
        <>
            <div
                className={styles.overlay}
                onClick={handleClose}
                data-section='overlay'
            />
            <div className={wrapperClasses} style={inlineStyles}>
                {isSmallModal
                    ? (
                        <>
                            <div className={styles.headerShort}>
                                <div className={actionsClasses}>
                                    <div className={styles.action} onClick={handleClose}>
                                        <CloseIcon />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.titleWrapperShort}>
                                <div className={styles.titleShort}>{title}</div>
                                {Boolean(description) && (
                                    <div className={styles.titleDescription}>{description}</div>
                                )}
                            </div>
                        </>
                    )
                    : (
                        <div className={styles.header}>
                            <div className={styles.title}>
                                {title}
                            </div>
                            <div className={actionsClasses}>
                                {extraHeaderControls?.map((element) => element)}
                                <div className={styles.action} onClick={handleClose}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                    )}

                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </>
    );
});

interface IProps {
    onClose: () => void;
    title?: string | ReactElement;
    description?: string;
    extraHeaderControls?: Array<ReactElement> | null;
    borderUnderTitle?: boolean;
    children?: ReactElement;
    width?: string;
}
