import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Button, ButtonThemes, ButtonTypes} from 'components/common/newButton';
import {Search} from 'components/common/search';
import {useAppContext} from 'components/app/AppContext';
import {PERMISSIONS, ROUTES, USER_ROLES} from 'core/constants';
import {useCheckAllowRoutes} from 'core/hooks';
import {useCheckPermission} from 'core/utils';
import {useScansErrorsCount} from 'queries/scans';
import styles from './Menu.module.css';

export const Menu = memo(() => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [{data, isPending}, fetch] = useScansErrorsCount();
    const [count, setCount] = useState<string>();
    const {setIsProductModalOpen, user, createdProductBubble, setCreatedProductBubble} = useAppContext();
    const canUseMenu = useCheckPermission(PERMISSIONS.can_use_menu);
    const canSeeHeadlines = useCheckPermission(PERMISSIONS.can_see_pages_headlines);

    const fetchErrorsCount = useCallback(fetch, []);

    const handleAddProduct = useCallback(() => {
        setIsProductModalOpen(true);
    }, []);

    const role = useMemo(() => user?.role as USER_ROLES, [user?.role]);

    const isMatchesRouteAvailable = useCheckAllowRoutes(ROUTES.MATCHES);
    const isInProgressRouteAvailable = useCheckAllowRoutes(ROUTES.IN_PROGRESS);
    const isErrorsRouteAvailable = useCheckAllowRoutes(ROUTES.ERRORS);

    const canUseSearch = useMemo(() => {
        if (!role) {
            return false;
        }

        return ![USER_ROLES.brand].includes(role);
    }, [role]);

    const titleText = useMemo(() => {
        if (pathname === ROUTES.PRODUCTS) return 'Brand products';
        if (pathname === ROUTES.SCORE) return 'Score checking';
        if (pathname === ROUTES.CITY_MATCH) return 'Target audience';
        return null;
    }, [pathname]);

    const buttonsNodes = useMemo(() => {
        if (pathname === ROUTES.PRODUCTS) {
            return (
                <>
                    <div className={styles.right}>
                        <Button
                            text='Check target audience'
                            type={ButtonTypes.micro}
                            theme={ButtonThemes.secondary}
                            link={ROUTES.CITY_MATCH}
                        />
                    </div>
                    <div className={styles.right}>
                        <Button
                            text='+ Add new product'
                            type={ButtonTypes.micro}
                            onClick={handleAddProduct}
                        />
                    </div>
                </>
            );
        }

        if (pathname === ROUTES.CITY_MATCH) {
            return (
                <>
                    <div className={styles.right}>
                        <Button
                            text='All brand products'
                            type={ButtonTypes.micro}
                            theme={ButtonThemes.secondary}
                            link={ROUTES.PRODUCTS}
                        />
                    </div>
                </>
            );
        }

        return null;
    }, [pathname]);

    useEffect(() => {
        if (!!role && role !== USER_ROLES.brand) {
            fetchErrorsCount();
        }
    }, [fetchErrorsCount, pathname, role]);

    useEffect(() => {
        if (Boolean(data?.count) && data?.count !== Number(count) && !isPending) {
            const countLabel = Number(data?.count) > 99 ? '99+' : String(data?.count || 0);
            setCount(countLabel);
        }
    }, [data?.count]);

    useEffect(() => {
        if (pathname !== ROUTES.PRODUCTS || createdProductBubble === '0') return;

        setCreatedProductBubble('0');
    }, [pathname, createdProductBubble, setCreatedProductBubble]);

    if (!role) {
        return null;
    }

    if (!canUseMenu && canSeeHeadlines) {
        return (
            <div className={styles.wrapperWithTitle}>
                <h1 className={styles.pageTitle}>
                    {titleText}
                </h1>
                <div className={styles.buttonsWrapper}>
                    {buttonsNodes}
                </div>
            </div>
        );
    }

    if ([ROUTES.SCORE, ROUTES.CITY_MATCH].includes(pathname)) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.switcher}>
                    {role !== USER_ROLES.brand && (
                        <div className={styles.border} />
                    )}
                    {role !== USER_ROLES.brand && (
                        <div className={styles.tab}>
                            <Button
                                text='Products'
                                type={ButtonTypes.tab}
                                theme={pathname === ROUTES.PRODUCTS ? ButtonThemes.primary : ButtonThemes.tab}
                                link={ROUTES.PRODUCTS}
                                top={createdProductBubble}
                                onClick={() => {
                                    if (pathname === ROUTES.PRODUCTS) {
                                        navigate(0);
                                    }
                                }}
                            />
                        </div>
                    )}
                    {/* @ts-ignore */}
                    {(role !== 'moderator') && isMatchesRouteAvailable && (
                        <div className={styles.tab}>
                            <Button
                                text='Matches'
                                type={ButtonTypes.tab}
                                theme={pathname === ROUTES.MATCHES ? ButtonThemes.primary : ButtonThemes.tab}
                                link={ROUTES.MATCHES}
                            />
                        </div>
                    )}
                    {isInProgressRouteAvailable && (
                        <div className={styles.tab}>
                            <Button
                                text='In progress'
                                type={ButtonTypes.tab}
                                theme={pathname === ROUTES.IN_PROGRESS ? ButtonThemes.primary : ButtonThemes.tab}
                                link={ROUTES.IN_PROGRESS}
                            />
                        </div>
                    )}
                    {isErrorsRouteAvailable && (
                        <div className={styles.tab}>
                            <Button
                                text='Errors'
                                type={ButtonTypes.tab}
                                theme={pathname === ROUTES.ERRORS ? ButtonThemes.primary : ButtonThemes.tab}
                                link={ROUTES.ERRORS}
                                top={count}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.mobileMenu}>
                    {canUseSearch && <Search />}
                    <div className={styles.mobileRight}>
                        <Button
                            text='+ Add new product'
                            type={ButtonTypes.micro}
                            onClick={handleAddProduct}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <Button
                    text='+ Add new product'
                    type={ButtonTypes.micro}
                    onClick={handleAddProduct}
                />
            </div>
        </div>
    );
});
