import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Tag} from 'components/common/tag';
import {useAppContext} from 'components/app/AppContext';
import {Preview} from 'components/common/preview';
import {Checkbox} from 'components/common/checkbox';
import {TagTypes, TScan} from 'core/types';
import {ReactComponent as EditIcon} from 'assets/images/edit.svg';
import {ReactComponent as DeleteIcon} from 'assets/images/delete.svg';
import styles from './ErrorRow.module.css';

export const ErrorRow = memo<IProps>(({
    scan,
}) => {
    const {
        setScan,
        setIsErrorModalOpen,
        setIsSelectModalOpen,
        allErrorsChecked,
        setDeletingIds,
        deletingIds,
    } = useAppContext();

    const [checked, setChecked] = useState<boolean>(allErrorsChecked);
    const checkboxRef = useRef<HTMLInputElement>(null);

    const {
        id,
        productId,
        scanImages,
        product,
        createdDate,
    } = scan;
    const {
        brand,
        name,
        productCategory,
        skinTypes,
        ingredients,
        productImages,
    } = product || {};

    const getBooleanTag = useCallback((field?: string | Array<string>) => {
        if (Boolean(field?.length)) {
            return <Tag text='Yes' type={TagTypes.POSITIVE} />
        }

        return <Tag text='No' type={TagTypes.NEGATIVE} />
    }, []);

    //@ts-ignore
    const handleClick = useCallback((e) => {
        if (e.target === checkboxRef.current) return;

        setScan(scan);
        setIsErrorModalOpen(true);
    }, [checkboxRef.current]);

    const handleDelete = useCallback((e: MouseEvent) => {
        setScan(scan);
        setIsSelectModalOpen(true);
        e.stopPropagation();
    }, [scan]);

    const handleCheck = useCallback(() => {
        const newValue = !checked;

        if (newValue) {
            const newArrayForDeleting = [...deletingIds, Number(id)];
            setDeletingIds(newArrayForDeleting);
        } else {
            const newArrayForDeleting = deletingIds.filter((item) => item !== id);
            setDeletingIds(newArrayForDeleting);
        }

        setChecked(newValue);
    }, [setChecked, checked, setDeletingIds, deletingIds, id]);

    const ScanImageNode = useMemo(() => {
        if (!scanImages?.[0]) {
            return <Preview url={null} />
        }

        const {url, id: imgID} = scanImages[0];
        return <Preview url={url} alt={`${imgID}_${id}_${name}`} />;
    }, [scanImages]);

    const ProductImageNode = useMemo(() => {
        if (!productImages?.[0]) {
            return <Preview url={null} />
        }

        const {url, id: imgID} = productImages[0];
        return <Preview url={url} alt={`${imgID}_${id}_${name}`} />;
    }, [productImages]);

    const SkinTypesNodes = useMemo(() => (
        skinTypes?.map((type, index) => (
            <Tag text={type} key={`${type}_${index}`} />
        )) || []
    ), [skinTypes]);

    const CategoryNodes = useMemo(() => (
        productCategory?.map((category, index) => (
            <Tag text={category} key={`${category}_${index}`} />
        )) || []
    ), [productCategory]);

    useEffect(() => {
        setChecked(allErrorsChecked);
    }, [allErrorsChecked]);

    useEffect(() => {
        if (!deletingIds.length) {
            setChecked(false);
        }
    }, [deletingIds]);

    return (
        <tr>
            <td>
                <Checkbox
                    checked={checked}
                    onClick={handleCheck}
                    ref={checkboxRef}
                />
            </td>
            <td>{id}</td>
            <td
                onClick={handleClick}
                className={styles.clickable}
            >
                {ScanImageNode}
            </td>
            <td>{productId || getBooleanTag('')}</td>
            <td
                onClick={handleClick}
                className={styles.clickable}
            >
                {ProductImageNode}
            </td>
            <td className={styles.short}>
                {brand || getBooleanTag('')}
            </td>
            <td className={styles.short}>
                {name || getBooleanTag('')}
            </td>
            <td className={styles.short}>
                <div className={styles.tagsWrapper}>
                    {Boolean(CategoryNodes.length)
                        ? CategoryNodes
                        : <Tag text='No' type={TagTypes.NEGATIVE} />
                    }
                </div>
            </td>
            <td className={styles.short}>
                <div className={styles.tagsWrapper}>
                    {Boolean(SkinTypesNodes.length)
                        ? SkinTypesNodes
                        : <Tag text='No' type={TagTypes.NEGATIVE} />
                    }
                </div>
            </td>
            <td className={styles.short}>
                {getBooleanTag(ingredients)}
            </td>
            <td className={styles.short}>
                {createdDate
                    ? new Date(createdDate).toLocaleDateString('ru-RU')
                    : <Tag text='No' type={TagTypes.NEGATIVE} />}
            </td>
            <td className={styles.actions}>
                <div className={styles.action} onClick={handleClick}>
                    <EditIcon />
                </div>
                <div
                    className={styles.action}
                    /* @ts-ignore */
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </div>
            </td>
        </tr>
    );
});

interface IProps {
    scan: TScan;
}
