import {ChangeEvent, memo, ReactNode, useCallback, useRef, useState} from 'react';

import {useOutsideClicker} from 'core/hooks';
import {ReactComponent as OpenIcon} from 'assets/images/arrow-bottom.svg';
import styles from './Dropdown.module.css';

export const Dropdown = memo<IProps>(({options, current, onChange}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const {text} = current;
    const dropdownRef = useRef(null);
    const iconClasses = isOpened ? styles.iconOpened : undefined;

    const handleOptionClick = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        if (value !== current.value) {
            const newOption = value === ''
                ? options.find((opt) => opt.value === undefined)
                : options.find((opt) => opt.value === value);

            if (!!newOption) {
                onChange(newOption);
            }
        }

        setIsOpened(false);
    }, [onChange, current.text, setIsOpened]);

    useOutsideClicker(dropdownRef, () => {setIsOpened(false)});

    return (
        <div className={styles.wrapper} ref={dropdownRef}>
            <div className={styles.toggle} onClick={() => setIsOpened(!isOpened)}>
                <div className={styles.current}>{text}</div>
                <OpenIcon className={iconClasses} />
            </div>
            {isOpened && (
                <div className={styles.content}>
                    {options.map(({text, value}, idx: number) => (
                        <label className={styles.checkbox} key={`gender_choice_${idx}`}>
                            {text}
                            <input
                                type='radio'
                                name='gender_choice'
                                value={value}
                                onChange={handleOptionClick}
                                checked={value === current.value}
                            />
                            <span className={styles.checkmark} />
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
});


interface IProps {
    options: Array<IOption>;
    current: IOption;
    onChange: (newOption: IOption) => void;
}

export interface IOption {
    text: ReactNode;
    value: string;
}
