import {memo, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import styles from './InProgressFilter.module.css';

export const InProgressFilter = memo(() => {
    const [searchParams, setSearchParams] = useSearchParams();

    const activeFilter = useMemo(() => {
        return searchParams.get('filter');
    }, [searchParams]);

    const handleClick = useCallback((key: string) => {
        setSearchParams(
            {filter: key},
            {replace: true},
        );
    }, []);

    return (
        <div className={styles.wrapper}>
            <button
                className={activeFilter === 'all' ? styles.buttonActive : styles.button}
                onClick={() => handleClick('all')}
            >
                All
            </button>
            <button
                className={activeFilter === 'missing' ? styles.buttonActive : styles.button}
                onClick={() => handleClick('missing')}
            >
                Missing information
            </button>
            <button
                className={activeFilter === 'new' ? styles.buttonActive : styles.button}
                onClick={() => handleClick('new')}
            >
                New product
            </button>
        </div>
    );
});
