import {Fragment, memo} from 'react';

import {Button} from 'components/common/newButton';
import {ROUTES} from 'core/constants';
import {ReactComponent as Icon} from 'assets/images/404.svg';
import styles from './errors.module.css';

export const NotFound = memo(() => {
    return (
        <div className={styles.wrapper}>
            <Icon />
            <div className={styles.title}>
                Page not found
            </div>
            <div className={styles.text}>
                The link you clicked may be broken
                <Fragment><br/></Fragment>
                or the page may have been removed.
            </div>
            <div className={styles.button}>
                <Button text='Back to Mainpage' link={ROUTES.HOME} />
            </div>
        </div>
    );
});
