import {useMemo} from 'react';
import {plural} from 'pluralize';

import {ProductFieldType, TCityMatchResult, TProduct, TProductProperties} from 'core/types';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {GRAPH_DATA_TO_PRODUCT} from 'components/blocks/graphWidget';
import {GraphType} from 'components/blocks/graphWidget/data';
import {useAppContext} from 'components/app/AppContext';
import {PERMISSIONS, ROLES_WITH_PERMISSIONS} from './constants';

const BLACK_LIST_FOR_PLURALIZE = [
    'consistence',
    'skinSensitiveness',
    'spf',
    'timeToUse',
    'citiesInfos',
    'productAvailability',
];

export const getProductFieldValue = (
    fieldKey: keyof TProduct,
    type: ProductFieldType,
    product?: TProduct | null,
    initialProperties?: TProductProperties | null,
) => {
    const properties = {
        ...(initialProperties || {}),
        genders: [{id: 0, name: 'Men'}, {id: 1, name: 'Women'}, {id: 2, name: 'All'}],
        productAvailability: initialProperties?.productAvailability.map((val, idx) => ({id: idx, name: val})),
    };

    if (type === ProductFieldType.text) {
        const value = product?.[fieldKey];
        return typeof value === 'number' ? String(value) : value || '';
    }

    if (type === ProductFieldType.formattedText) {
        const value = product?.[fieldKey];
        if (!value || !Array.isArray(value)) {
            return undefined;
        }
        return value.join(', ');
    }

    if (type === ProductFieldType.tags) {
        const chosenData = product?.[fieldKey] || [];
        if (!Array.isArray(chosenData)) {
            return undefined;
        }

        //@ts-ignore
        const serializedData = fieldKey !== 'citiesInfos' ? chosenData : chosenData.map((info) => info.city);
        const pluralizedKey = (BLACK_LIST_FOR_PLURALIZE.includes(fieldKey) ? fieldKey : plural(fieldKey)) as keyof TProductProperties;
        const all = new Set([
            ...serializedData,
            ...(properties?.[pluralizedKey]
                ?.sort((a, b) => {
                //@ts-ignore
                    if (!a.city || !b.city) return 0;
                    return a.id - b.id;
                })
                //@ts-ignore
                .map((prop) => prop.name || prop.city) || []),
        ]);
        const filtered = Array.from(all);
        return filtered.map((text) => ({
            text: text,
            //@ts-ignore
            showOnEdit: !serializedData.includes(text),
        }));
    }

    if (type === ProductFieldType.oneFromTags) {
        const value = product?.[fieldKey] === 0 ? 'No' : product?.[fieldKey];

        const pluralizedKey = (BLACK_LIST_FOR_PLURALIZE.includes(fieldKey) ? fieldKey : plural(fieldKey)) as keyof TProductProperties;
        const all = new Set([
            //@ts-ignore
            ...((!value && value !== 0) ? [] : [String(value)]),
            //@ts-ignore
            ...(properties?.[pluralizedKey]?.map((prop) => prop.name || prop.city) || []),
        ]);
        const filtered = Array.from(all);
        return filtered.map((text) => ({
            text: text,
            //@ts-ignore
            showOnEdit: (!value && value !== 0) ? true : text !== String(value),
        }));
    }

    return undefined;
};

export const checkRequiredProductField = (editedProduct: TProduct, field: keyof TProduct) => {
    const configForField = PRODUCT_FIELDS_CONFIG.find(({key}) => key === field);

    if (!configForField!.isRequired) {
        return true;
    }

    const value = editedProduct[field];

    if (field === 'spf' && value === 0) {
        return true;
    }

    if (configForField!.visibilityConfig) {
        const dependEntered = editedProduct[configForField!.visibilityConfig.dependField];
        const dependValueForCompare = Array.isArray(dependEntered) ? dependEntered[0] : dependEntered;
        const needShow = dependValueForCompare === configForField!.visibilityConfig.visibilityValue;

        if (!needShow) {
            return true;
        }
    }

    if (!value) {
        return false;
    }

    if (Array.isArray(value) || typeof value === 'string') {
        return value.length !== 0;
    }

    return true;
};

export const getTrimmedCopyForText = (product: TProduct): TProduct => {
    return Object.entries(product).reduce((acc, [key, value]) => {
        const {type} = PRODUCT_FIELDS_CONFIG.find((field) => field.key === key) || {};
        //@ts-ignore
        if (!type || type !== ProductFieldType.text || !value?.length) {
            //@ts-ignore
            acc[key] = value;
            return acc;
        }
        //@ts-ignore
        acc[key] = value.trim();
        return acc;
    }, {});
};

export const getRangeOfAges = (ages: Array<string>): string => {
    const divider = '—';
    const allAges = ages.map((interval) => interval.split(divider)).flat().sort();
    if (allAges.length === 1) {
        return allAges[0];
    }
    return `${allAges[0]}${divider}${allAges[allAges.length - 1]}`;
}

export const sortCitiesByScore = (scores: Array<TCityMatchResult>) => {
    return scores
        .sort((a, b) => b.score - a.score)
        .slice(0, 5)
        .map((info, idx) => ({
            id: info.city.id,
            city: info.city.city,
            country: info.city.country,
            score: Math.round(info.score * 100),
            num: idx + 1,
        }));
};

export const calculateMockTrend = (data: Array<{value: number, date: string, isPredicted: boolean}>) => {
    const realData = data.filter(({isPredicted}) => !isPredicted);
    const average = realData.reduce((acc, {value}) => acc + value, 0) / realData.length;
    const firstPredicted = data.find(({isPredicted}) => isPredicted);

    if (firstPredicted!.value >= average) {
        return 'good';
    }

    return 'bad';
}

export const calculateMockTrendWithArrays = (real: Array<number | null>, predicted: Array<number | null>) => {
    const realWithoutNull = real.filter((v) => v !== null);
    // @ts-ignore
    const average = realWithoutNull.reduce((acc, value) => acc + value, 0) / realWithoutNull.length;
    const firstPredicted = predicted.find((v) => v !== null);

    if (!!firstPredicted && firstPredicted >= average) {
        return 'bad';
    }

    return 'good';
}

export const getGraphDataByProduct = (id: number, graphType: GraphType) => {
    const productId = String(id);
    const productsWhiteList = Object.keys(GRAPH_DATA_TO_PRODUCT);

    if (!productsWhiteList.includes(productId)) {
        return null;
    }

    // @ts-ignore
    const fullProductData = GRAPH_DATA_TO_PRODUCT[productId];
    const data = fullProductData[graphType];
    return data || null;
};

export const getFormattedDataForGraphByType = (info: Object, graphType: GraphType) => {
    if (graphType === GraphType.INTERESTS) {
        // @ts-ignore
        const {data, is_good_time_for_promotion} = info;

        if (!data) {
            return null;
        }

        // @ts-ignore
        const local = [], global = [];
        // @ts-ignore
        data.forEach((elem) => {
            const {date, global_brand, local_brand, is_prediction} = elem;
            local.push({
                value: local_brand,
                date: formatDate(date),
                isPredicted: is_prediction,
            });

            global.push({
                value: global_brand,
                date: formatDate(date),
                isPredicted: is_prediction,
            });
        });

        // @ts-ignore
        return {data: [local, global], trend: is_good_time_for_promotion};
    }

    if (graphType === GraphType.ADS) {
        // @ts-ignore
        const {data2, is_good_time_for_promotion2} = info;

        if (!data2) {
            return null;
        }

        // @ts-ignore
        const real = [], predicted = [], labels = [];
        // @ts-ignore
        data2.forEach((elem) => {
            const {date, count, is_prediction} = elem;
            if (!is_prediction) {
                real.push(count);
                predicted.push(null);
            } else {
                predicted.push(count);
                real.push(null);
            }

            labels.push(formatDate(date));
        });

        // @ts-ignore
        return {data: [real, predicted], trend: is_good_time_for_promotion2, labels};
    }

    return null;
}

const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const [year, monthIndex] = [dateObj.getUTCFullYear(), dateObj.getUTCMonth()];

    if (monthIndex > 11) {
        return null;
    }

    const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${MONTHS[monthIndex]} ${year}`;
}

export const useCheckPermission = (permission: PERMISSIONS) => {
    const {user} = useAppContext();

    const currentPermissions = useMemo(() => {
        if (!user) {
            return [];
        }

        return ROLES_WITH_PERMISSIONS[user.role];
    }, [user]);

    return currentPermissions.includes(permission);
}
