import {memo} from 'react';
import {useLocation, Navigate} from 'react-router-dom';

import {ROUTES, USER_ROLES} from 'core/constants';
import {ROLE_ALLOW_ROUTES_CONFIG} from 'core/configs';
import {useAppContext} from 'components/app/AppContext';

export const RedirectIfAuth = memo<IProps>(({children}) => {
    const {user} = useAppContext();
    const location = useLocation();

    if (!!user) {
        const allowedRoutes = ROLE_ALLOW_ROUTES_CONFIG[user.role as USER_ROLES];

        // If no role or allowedRoutes in config, think all allowes by default
        if (!!allowedRoutes?.length && location.pathname !== allowedRoutes[0]) {
            return (
                <Navigate
                    to={allowedRoutes[0]}
                    state={{from: location}}
                    replace
                />
            );
        }

        return (
            <Navigate
                to={ROUTES.PRODUCTS}
                state={{from: location}}
                replace
            />
        );
    }

    return children;
});

interface IProps {
    children: JSX.Element;
}
