import {Fragment, memo, useCallback} from 'react';
import {FallbackProps} from 'react-error-boundary';

import {Button} from 'components/common/newButton';
import {ReactComponent as Icon} from 'assets/images/noNetwork.svg';
import styles from './errors.module.css';

export const NoNetworkError = memo(({resetErrorBoundary}: FallbackProps) => {
    const handleReloadClick = useCallback(() => {
        resetErrorBoundary();
    }, [resetErrorBoundary]);

    return (
        <div className={styles.wrapper}>
            <Icon />
            <div className={styles.title}>
                Something went wrong
            </div>
            <div className={styles.text}>
                We didn’t quite understand what
                <Fragment><br/></Fragment>
                happened, but try reloading the page.
            </div>
            <div className={styles.button}>
                <Button text='Reload' onClick={handleReloadClick} />
            </div>
        </div>
    );
});
