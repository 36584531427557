import {memo, useEffect, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

import {Layout} from 'components/common/layout';
import {Products} from 'components/pages/products';
import {Matches} from 'components/pages/matches';
import {InProgress} from 'components/pages/inProgress';
import {Errors} from 'components/pages/errors';
import {ScoreChecking} from 'components/pages/score';
import {CityMatch} from 'components/pages/cityMatch';
import {Enter} from 'components/pages/enter';
import {Login} from 'components/pages/login';
import {ROUTES} from 'core/constants';
import {DeleteProductModal} from 'components/blocks/modals/deleteProduct';
import {DeleteMatchModal} from 'components/blocks/modals/deleteMatch';
import {DeleteInProgressScan} from 'components/blocks/modals/deleteInProgressScan';
import {DeleteErrorModal} from 'components/blocks/modals/deleteError';
import {FirstRequestModal} from 'components/blocks/modals/firstRequestModal';
import {SuccessPaymentModal} from 'components/blocks/modals/successPaymentModal';
import {PaymentDevelopingModal} from 'components/blocks/modals/paymentDevelopingModal';
import {SuccessDeleteModal} from 'components/blocks/successDeleteModal';
import {SuccessSaveModal} from 'components/blocks/successSaveModal';
import {ComingSoonModal} from 'components/blocks/comingSoonModal';
import {EditProductModal} from 'components/blocks/editProductModal';
import {MatchModal} from 'components/blocks/matchModal';
import {FindModal} from 'components/blocks/findModal';
import {CreateWithScanModal} from 'components/blocks/createWithScanModal';
import {ErrorModal} from 'components/blocks/errorModal';
import {AllScansModal} from 'components/blocks/allScansModal';
import {DeleteScanModal} from 'components/blocks/modals/deleteScanModal';
import {BrandInfoModal} from 'components/blocks/brandInfoModal';
import {GptInfoModal} from 'components/blocks/gptInfoModal';
import {SubscriptionModal} from 'components/blocks/subscriptionModal';
import {NotFound, BadRequest, NoNetworkError} from 'components/blocks/troubles';
import {TProduct, TScan, TCurrentUser, TImage} from 'core/types';
import {AppContext} from './AppContext';
import {RequireAuth} from './RequireAuth';
import {RedirectIfAuth} from './RedirectIfAuth';

export const App = memo(() => {
    const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);
    const [product, setProduct] = useState<TProduct | null>(null);
    const [scan, setScan] = useState<TScan | null>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [refreshBalance, setRefreshBalance] = useState<boolean>(false);
    const [isSuccessDeleteModalOpen, setIsSuccessDeleteModalOpen] = useState<boolean>(false);
    const [isSuccessSaveModalOpen, setIsSuccessSaveModalOpen] = useState<boolean>(false);
    const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState<boolean>(false);
    const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
    const [isMatchModalOpen, setIsMatchModalOpen] = useState<boolean>(false);
    const [isFindModalOpen, setIsFindModalOpen] = useState<boolean>(false);
    const [isCreateWithScanModalOpen, setIsCreateWithScanModalOpen] = useState<boolean>(false);
    const [user, setUser] = useState<TCurrentUser | null>(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [isAllScansModalOpen, setIsAllScansModalOpen] = useState<boolean>(false);
    const [isDeleteScanModalOpen, setIsDeleteScanModalOpen] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<{data: TScan | TImage, isProductImage: boolean} | null>(null);
    const [isSaveErrorModalOpen, setIsSaveErrorModalOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<{field: string, order: string} | null>(null);
    const [createdProductBubble, setCreatedProductBubble] = useState<string>('0');
    const [enteredBrandInSearch, setEnteredBrandInSearch] = useState<string>('');
    const [enteredProductInSearch, setEnteredProductInSearch] = useState<string>('');
    const [allProductChecked, setAllProductChecked] = useState<boolean>(false);
    const [allMatchesChecked, setAllMatchesChecked] = useState<boolean>(false);
    const [allInProgressChecked, setAllInProgressChecked] = useState<boolean>(false);
    const [allErrorsChecked, setAllErrorsChecked] = useState<boolean>(false);
    const [deletingIds, setDeletingIds] = useState<Array<number>>([]);
    const [wasProductChanged, setWasProductChanged] = useState<boolean>(false);
    const [isBrandModalOpened, setIsBrandModalOpened] = useState<boolean>(false);
    const [isGptModalOpened, setIsGptModalOpened] = useState<boolean>(false);
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState<boolean>(false);
    const [isAuthPending, setIsAuthPending] = useState<boolean>(false);
    const [isFirstRequestModalOpen, setIsFirstRequestModalOpen] = useState<boolean>(false);
    const [isSuccessPaymentModalOpen, setIsSuccessPaymentModalOpen] = useState<boolean>(false);
    const [isPaymentDevelopingModalOpen, setIsPaymentDevelopingModalOpen] = useState<boolean>(false);
    const [balance, setBalance] = useState<number | null>(null);

    const {pathname} = window.location;

    useEffect(() => {
        if (allProductChecked) {
            setAllMatchesChecked(false);
            setAllInProgressChecked(false);
            setAllErrorsChecked(false);
            return;
        }

        if (allMatchesChecked) {
            setAllProductChecked(false);
            setAllInProgressChecked(false);
            setAllErrorsChecked(false);
            return;
        }

        if (allInProgressChecked) {
            setAllProductChecked(false);
            setAllMatchesChecked(false);
            setAllErrorsChecked(false);
            return;
        }

        if (allErrorsChecked) {
            setAllProductChecked(false);
            setAllMatchesChecked(false);
            setAllInProgressChecked(false);
        }
    }, [allProductChecked, allMatchesChecked, allInProgressChecked, allErrorsChecked]);

    return (
        <BrowserRouter>
            <AppContext.Provider value={{
                product,
                setProduct,
                scan,
                setScan,
                isSelectModalOpen,
                setIsSelectModalOpen,
                refresh,
                setRefresh,
                isSuccessDeleteModalOpen,
                setIsSuccessDeleteModalOpen,
                isSuccessSaveModalOpen,
                setIsSuccessSaveModalOpen,
                isComingSoonModalOpen,
                setIsComingSoonModalOpen,
                isProductModalOpen,
                setIsProductModalOpen,
                isMatchModalOpen,
                setIsMatchModalOpen,
                isFindModalOpen,
                setIsFindModalOpen,
                isCreateWithScanModalOpen,
                setIsCreateWithScanModalOpen,
                user,
                setUser,
                setIsErrorModalOpen,
                isErrorModalOpen,
                isAllScansModalOpen,
                setIsAllScansModalOpen,
                isDeleteScanModalOpen,
                setIsDeleteScanModalOpen,
                deletingImage,
                setDeletingImage,
                isSaveErrorModalOpen,
                setIsSaveErrorModalOpen,
                sort,
                setSort,
                createdProductBubble,
                setCreatedProductBubble,
                enteredBrandInSearch,
                setEnteredBrandInSearch,
                enteredProductInSearch,
                setEnteredProductInSearch,
                allProductChecked,
                setAllProductChecked,
                allMatchesChecked,
                setAllMatchesChecked,
                allInProgressChecked,
                setAllInProgressChecked,
                allErrorsChecked,
                setAllErrorsChecked,
                deletingIds,
                setDeletingIds,
                wasProductChanged,
                setWasProductChanged,
                isBrandModalOpened,
                setIsBrandModalOpened,
                isGptModalOpened,
                setIsGptModalOpened,
                isSubscriptionModalOpen,
                setIsSubscriptionModalOpen,
                isAuthPending,
                setIsAuthPending,
                refreshBalance,
                setRefreshBalance,
                isFirstRequestModalOpen,
                setIsFirstRequestModalOpen,
                isSuccessPaymentModalOpen,
                setIsSuccessPaymentModalOpen,
                isPaymentDevelopingModalOpen,
                setIsPaymentDevelopingModalOpen,
                balance,
                setBalance,
            }}>
                <Layout>
                    <ErrorBoundary FallbackComponent={NoNetworkError}>
                        {isProductModalOpen && <EditProductModal />}
                        {isSuccessDeleteModalOpen && <SuccessDeleteModal />}
                        {isSuccessSaveModalOpen && <SuccessSaveModal />}
                        {isComingSoonModalOpen && <ComingSoonModal />}
                        {isMatchModalOpen && <MatchModal />}
                        {isFindModalOpen && <FindModal />}
                        {isSelectModalOpen && pathname === ROUTES.PRODUCTS && <DeleteProductModal />}
                        {isSelectModalOpen && pathname === ROUTES.MATCHES && <DeleteMatchModal />}
                        {isSelectModalOpen && pathname === ROUTES.IN_PROGRESS && <DeleteInProgressScan />}
                        {isCreateWithScanModalOpen && <CreateWithScanModal />}
                        {isErrorModalOpen && <ErrorModal />}
                        {isAllScansModalOpen && <AllScansModal />}
                        {isDeleteScanModalOpen && <DeleteScanModal />}
                        {isSaveErrorModalOpen && <BadRequest />}
                        {isSelectModalOpen && pathname === ROUTES.ERRORS && <DeleteErrorModal />}
                        {isBrandModalOpened && <BrandInfoModal />}
                        {isGptModalOpened && <GptInfoModal />}
                        {isSubscriptionModalOpen && <SubscriptionModal />}
                        {isFirstRequestModalOpen && <FirstRequestModal />}
                        {isSuccessPaymentModalOpen && <SuccessPaymentModal />}
                        {isPaymentDevelopingModalOpen && <PaymentDevelopingModal />}
                        <Routes>
                            <Route path={ROUTES.HOME} element={<RedirectIfAuth><Enter /></RedirectIfAuth>} />
                            <Route path={ROUTES.LOGIN} element={<RedirectIfAuth><Login /></RedirectIfAuth>} />
                            <Route path={ROUTES.PRODUCTS} element={<RequireAuth><Products /></RequireAuth>} />
                            <Route path={ROUTES.MATCHES} element={<RequireAuth><Matches /></RequireAuth>} />
                            <Route path={ROUTES.IN_PROGRESS} element={<RequireAuth><InProgress /></RequireAuth>} />
                            <Route path={ROUTES.ERRORS} element={<RequireAuth><Errors /></RequireAuth>} />
                            <Route path={ROUTES.SCORE} element={<RequireAuth><ScoreChecking /></RequireAuth>} />
                            {/* TODO: добавить спец проверку на авторизацию - для брендов */}
                            <Route path={ROUTES.CITY_MATCH} element={<RequireAuth><CityMatch /></RequireAuth>} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </ErrorBoundary>
                </Layout>
            </AppContext.Provider>
        </BrowserRouter>
    );
});
