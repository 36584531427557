export enum GraphType {
    INTERESTS = 'interests',
    ADS = 'ads',
    MENTIONS = 'mentions',
}

export const GRAPH_DATA_TO_PRODUCT = {
    43372: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 47.4, date: 'Jan 2023', isPredicted: false},
                {value: 41.75, date: 'Feb 2023', isPredicted: false},
                {value: 53.75, date: 'Mar 2023', isPredicted: false},
                {value: 42.6, date: 'Apr 2023', isPredicted: false},
                {value: 57.25, date: 'May 2023', isPredicted: false},
                {value: 63.25, date: 'Jun 2023', isPredicted: false},
                {value: 62.4, date: 'Jul 2023', isPredicted: false},
                {value: 71.5, date: 'Aug 2023', isPredicted: false},
                {value: 54, date: 'Sep 2023', isPredicted: false},
                {value: 43, date: 'Oct 2023', isPredicted: false},
                {value: 48.25, date: 'Nov 2023', isPredicted: false},
                {value: 31.6, date: 'Dec 2023', isPredicted: false},
                {value: 57.5, date: 'Jan 2024', isPredicted: false},
                {value: 41.5, date: 'Feb 2024', isPredicted: false},
                {value: 44.12, date: 'Mar 2024', isPredicted: true},
                {value: 39.02, date: 'Apr 2024', isPredicted: true},
                {value: 54.02, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 62.2, date: 'Jan 2023', isPredicted: false},
                {value: 65.5, date: 'Feb 2023', isPredicted: false},
                {value: 64.5, date: 'Mar 2023', isPredicted: false},
                {value: 64.4, date: 'Apr 2023', isPredicted: false},
                {value: 66.25, date: 'May 2023', isPredicted: false},
                {value: 67.75, date: 'Jun 2023', isPredicted: false},
                {value: 67.6, date: 'Jul 2023', isPredicted: false},
                {value: 63, date: 'Aug 2023', isPredicted: false},
                {value: 59.5, date: 'Sep 2023', isPredicted: false},
                {value: 62.6, date: 'Oct 2023', isPredicted: false},
                {value: 64.5, date: 'Nov 2023', isPredicted: false},
                {value: 58, date: 'Dec 2023', isPredicted: false},
                {value: 55, date: 'Jan 2024', isPredicted: false},
                {value: 47.75, date: 'Feb 2024', isPredicted: false},
                {value: 62.05, date: 'Mar 2024', isPredicted: true},
                {value: 63.83, date: 'Apr 2024', isPredicted: true},
                {value: 68.91, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 0.68, date: 'Jan 2023', isPredicted: false},
                {value: 1.36, date: 'Feb 2023', isPredicted: false},
                {value: 1.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.02, date: 'Apr 2023', isPredicted: false},
                {value: 2.89, date: 'May 2023', isPredicted: false},
                {value: 2.32, date: 'Jun 2023', isPredicted: false},
                {value: 4.42, date: 'Jul 2023', isPredicted: false},
                {value: 3.46, date: 'Aug 2023', isPredicted: false},
                {value: 2.63, date: 'Sep 2023', isPredicted: false},
                {value: 3.49, date: 'Oct 2023', isPredicted: false},
                {value: 4.32, date: 'Nov 2023', isPredicted: false},
                {value: 6.88, date: 'Dec 2023', isPredicted: false},
                {value: 6.00, date: 'Jan 2024', isPredicted: false},
                {value: 3.00, date: 'Feb 2024', isPredicted: false},
                {value: 5.29, date: 'Mar 2024', isPredicted: true},
                {value: 4.95, date: 'Apr 2024', isPredicted: true},
                {value: 4.69, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1482.02, date: 'Jan 2023', isPredicted: false},
                {value: 2975.42, date: 'Feb 2023', isPredicted: false},
                {value: 2634.26, date: 'Mar 2023', isPredicted: false},
                {value: 2224.14, date: 'Apr 2023', isPredicted: false},
                {value: 6313.70, date: 'May 2023', isPredicted: false},
                {value: 5063.83, date: 'Jun 2023', isPredicted: false},
                {value: 9639.85, date: 'Jul 2023', isPredicted: false},
                {value: 7544.23, date: 'Aug 2023', isPredicted: false},
                {value: 5729.47, date: 'Sep 2023', isPredicted: false},
                {value: 7615.76, date: 'Oct 2023', isPredicted: false},
                {value: 9420.94, date: 'Nov 2023', isPredicted: false},
                {value: 17191.56, date: 'Dec 2023', isPredicted: false},
                {value: 15000.00, date: 'Jan 2024', isPredicted: false},
                {value: 26000.00, date: 'Feb 2024', isPredicted: false},
                {value: 45828.20, date: 'Mar 2024', isPredicted: true},
                {value: 42908.52, date: 'Apr 2024', isPredicted: true},
                {value: 40662.30, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43370: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 67.6, date: 'Jan 2023', isPredicted: false},
                {value: 63.08333333, date: 'Feb 2023', isPredicted: false},
                {value: 65.5, date: 'Mar 2023', isPredicted: false},
                {value: 65.2, date: 'Apr 2023', isPredicted: false},
                {value: 68.83333333, date: 'May 2023', isPredicted: false},
                {value: 68.5, date: 'Jun 2023', isPredicted: false},
                {value: 71.4, date: 'Jul 2023', isPredicted: false},
                {value: 64.75, date: 'Aug 2023', isPredicted: false},
                {value: 58.08333333, date: 'Sep 2023', isPredicted: false},
                {value: 60.2, date: 'Oct 2023', isPredicted: false},
                {value: 66.91666667, date: 'Nov 2023', isPredicted: false},
                {value: 66.06666667, date: 'Dec 2023', isPredicted: false},
                {value: 70.5, date: 'Jan 2024', isPredicted: false},
                {value: 67, date: 'Feb 2024', isPredicted: false},
                {value: 54.95, date: 'Mar 2024', isPredicted: true},
                {value: 54.59, date: 'Apr 2024', isPredicted: true},
                {value: 56.76, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 70.66666667, date: 'Jan 2023', isPredicted: false},
                {value: 71.16666667, date: 'Feb 2023', isPredicted: false},
                {value: 69, date: 'Mar 2023', isPredicted: false},
                {value: 72.73333333, date: 'Apr 2023', isPredicted: false},
                {value: 70.91666667, date: 'May 2023', isPredicted: false},
                {value: 72.83333333, date: 'Jun 2023', isPredicted: false},
                {value: 73, date: 'Jul 2023', isPredicted: false},
                {value: 74.41666667, date: 'Aug 2023', isPredicted: false},
                {value: 72.66666667, date: 'Sep 2023', isPredicted: false},
                {value: 68.8, date: 'Oct 2023', isPredicted: false},
                {value: 75, date: 'Nov 2023', isPredicted: false},
                {value: 77.86666667, date: 'Dec 2023', isPredicted: false},
                {value: 84.5, date: 'Jan 2024', isPredicted: false},
                {value: 80.08333333, date: 'Feb 2024', isPredicted: false},
                {value: 56.93, date: 'Mar 2024', isPredicted: true},
                {value: 61.11333333, date: 'Apr 2024', isPredicted: true},
                {value: 60.58666667, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                8,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 1.25, date: 'Jan 2023', isPredicted: false},
                {value: 2.50, date: 'Feb 2023', isPredicted: false},
                {value: 2.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.87, date: 'Apr 2023', isPredicted: false},
                {value: 5.31, date: 'May 2023', isPredicted: false},
                {value: 4.26, date: 'Jun 2023', isPredicted: false},
                {value: 8.10, date: 'Jul 2023', isPredicted: false},
                {value: 6.34, date: 'Aug 2023', isPredicted: false},
                {value: 4.82, date: 'Sep 2023', isPredicted: false},
                {value: 6.40, date: 'Oct 2023', isPredicted: false},
                {value: 7.92, date: 'Nov 2023', isPredicted: false},
                {value: 12.61, date: 'Dec 2023', isPredicted: false},
                {value: 11.00, date: 'Jan 2024', isPredicted: false},
                {value: 15.00, date: 'Feb 2024', isPredicted: false},
                {value: 26.44, date: 'Mar 2024', isPredicted: true},
                {value: 24.75, date: 'Apr 2024', isPredicted: true},
                {value: 23.46, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1185.61, date: 'Jan 2023', isPredicted: false},
                {value: 2380.34, date: 'Feb 2023', isPredicted: false},
                {value: 2107.40, date: 'Mar 2023', isPredicted: false},
                {value: 1779.32, date: 'Apr 2023', isPredicted: false},
                {value: 5050.96, date: 'May 2023', isPredicted: false},
                {value: 4051.06, date: 'Jun 2023', isPredicted: false},
                {value: 7711.88, date: 'Jul 2023', isPredicted: false},
                {value: 6035.39, date: 'Aug 2023', isPredicted: false},
                {value: 4583.58, date: 'Sep 2023', isPredicted: false},
                {value: 6092.60, date: 'Oct 2023', isPredicted: false},
                {value: 7536.75, date: 'Nov 2023', isPredicted: false},
                {value: 13753.25, date: 'Dec 2023', isPredicted: false},
                {value: 12000.00, date: 'Jan 2024', isPredicted: false},
                {value: 24000.00, date: 'Feb 2024', isPredicted: false},
                {value: 42302.95, date: 'Mar 2024', isPredicted: true},
                {value: 39607.87, date: 'Apr 2024', isPredicted: true},
                {value: 37534.43, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43371: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 70, date: 'Jan 2023', isPredicted: false},
                {value: 70.1875, date: 'Feb 2023', isPredicted: false},
                {value: 70.5625, date: 'Mar 2023', isPredicted: false},
                {value: 66.25, date: 'Apr 2023', isPredicted: false},
                {value: 63, date: 'May 2023', isPredicted: false},
                {value: 61.9375, date: 'Jun 2023', isPredicted: false},
                {value: 63.85, date: 'Jul 2023', isPredicted: false},
                {value: 62.625, date: 'Aug 2023', isPredicted: false},
                {value: 61.3125, date: 'Sep 2023', isPredicted: false},
                {value: 56.8, date: 'Oct 2023', isPredicted: false},
                {value: 71.8125, date: 'Nov 2023', isPredicted: false},
                {value: 78.3, date: 'Dec 2023', isPredicted: false},
                {value: 82.0625, date: 'Jan 2024', isPredicted: false},
                {value: 74.625, date: 'Feb 2024', isPredicted: false},
                {value: 56.44527778, date: 'Mar 2024', isPredicted: true},
                {value: 57.1625, date: 'Apr 2024', isPredicted: true},
                {value: 54.4025, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 68.8, date: 'Jan 2023', isPredicted: false},
                {value: 69.4375, date: 'Feb 2023', isPredicted: false},
                {value: 75.3125, date: 'Mar 2023', isPredicted: false},
                {value: 67.45, date: 'Apr 2023', isPredicted: false},
                {value: 62, date: 'May 2023', isPredicted: false},
                {value: 62.875, date: 'Jun 2023', isPredicted: false},
                {value: 67.15, date: 'Jul 2023', isPredicted: false},
                {value: 69.0625, date: 'Aug 2023', isPredicted: false},
                {value: 69.9375, date: 'Sep 2023', isPredicted: false},
                {value: 65.1, date: 'Oct 2023', isPredicted: false},
                {value: 80.375, date: 'Nov 2023', isPredicted: false},
                {value: 88.7, date: 'Dec 2023', isPredicted: false},
                {value: 89.4375, date: 'Jan 2024', isPredicted: false},
                {value: 82.5, date: 'Feb 2024', isPredicted: false},
                {value: 54.94833333, date: 'Mar 2024', isPredicted: true},
                {value: 57.2025, date: 'Apr 2024', isPredicted: true},
                {value: 54.0375, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 0.68, date: 'Jan 2023', isPredicted: false},
                {value: 1.36, date: 'Feb 2023', isPredicted: false},
                {value: 1.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.02, date: 'Apr 2023', isPredicted: false},
                {value: 2.89, date: 'May 2023', isPredicted: false},
                {value: 2.32, date: 'Jun 2023', isPredicted: false},
                {value: 4.42, date: 'Jul 2023', isPredicted: false},
                {value: 3.46, date: 'Aug 2023', isPredicted: false},
                {value: 2.63, date: 'Sep 2023', isPredicted: false},
                {value: 3.49, date: 'Oct 2023', isPredicted: false},
                {value: 4.32, date: 'Nov 2023', isPredicted: false},
                {value: 6.88, date: 'Dec 2023', isPredicted: false},
                {value: 6.00, date: 'Jan 2024', isPredicted: false},
                {value: 3.00, date: 'Feb 2024', isPredicted: false},
                {value: 5.29, date: 'Mar 2024', isPredicted: true},
                {value: 4.95, date: 'Apr 2024', isPredicted: true},
                {value: 4.69, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1482.02, date: 'Jan 2023', isPredicted: false},
                {value: 2975.42, date: 'Feb 2023', isPredicted: false},
                {value: 2634.26, date: 'Mar 2023', isPredicted: false},
                {value: 2224.14, date: 'Apr 2023', isPredicted: false},
                {value: 6313.70, date: 'May 2023', isPredicted: false},
                {value: 5063.83, date: 'Jun 2023', isPredicted: false},
                {value: 9639.85, date: 'Jul 2023', isPredicted: false},
                {value: 7544.23, date: 'Aug 2023', isPredicted: false},
                {value: 5729.47, date: 'Sep 2023', isPredicted: false},
                {value: 7615.76, date: 'Oct 2023', isPredicted: false},
                {value: 9420.94, date: 'Nov 2023', isPredicted: false},
                {value: 17191.56, date: 'Dec 2023', isPredicted: false},
                {value: 15000.00, date: 'Jan 2024', isPredicted: false},
                {value: 26000.00, date: 'Feb 2024', isPredicted: false},
                {value: 45828.20, date: 'Mar 2024', isPredicted: true},
                {value: 42908.52, date: 'Apr 2024', isPredicted: true},
                {value: 40662.30, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43367: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 13.06666667, date: 'Jan 2023', isPredicted: false},
                {value: 19.625, date: 'Feb 2023', isPredicted: false},
                {value: 17.08333333, date: 'Mar 2023', isPredicted: false},
                {value: 13.93333333, date: 'Apr 2023', isPredicted: false},
                {value: 22.95833333, date: 'May 2023', isPredicted: false},
                {value: 17.29166667, date: 'Jun 2023', isPredicted: false},
                {value: 25.5, date: 'Jul 2023', isPredicted: false},
                {value: 18.41666667, date: 'Aug 2023', isPredicted: false},
                {value: 12.58333333, date: 'Sep 2023', isPredicted: false},
                {value: 15.7, date: 'Oct 2023', isPredicted: false},
                {value: 18.70833333, date: 'Nov 2023', isPredicted: false},
                {value: 25.66666667, date: 'Dec 2023', isPredicted: false},
                {value: 21.91666667, date: 'Jan 2024', isPredicted: false},
                {value: 10.16666667, date: 'Feb 2024', isPredicted: false},
                {value: 17.92, date: 'Mar 2024', isPredicted: true},
                {value: 16.77833333, date: 'Apr 2024', isPredicted: true},
                {value: 15.9, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 25.7, date: 'Jan 2023', isPredicted: false},
                {value: 23.54166667, date: 'Feb 2023', isPredicted: false},
                {value: 30.20833333, date: 'Mar 2023', isPredicted: false},
                {value: 30.7, date: 'Apr 2023', isPredicted: false},
                {value: 27.58333333, date: 'May 2023', isPredicted: false},
                {value: 29.125, date: 'Jun 2023', isPredicted: false},
                {value: 31.56666667, date: 'Jul 2023', isPredicted: false},
                {value: 27.375, date: 'Aug 2023', isPredicted: false},
                {value: 26.33333333, date: 'Sep 2023', isPredicted: false},
                {value: 28.03333333, date: 'Oct 2023', isPredicted: false},
                {value: 40, date: 'Nov 2023', isPredicted: false},
                {value: 42.83333333, date: 'Dec 2023', isPredicted: false},
                {value: 29.66666667, date: 'Jan 2024', isPredicted: false},
                {value: 32.41666667, date: 'Feb 2024', isPredicted: false},
                {value: 26.835, date: 'Mar 2024', isPredicted: true},
                {value: 27.02333333, date: 'Apr 2024', isPredicted: true},
                {value: 27.24, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                5,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                7,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 36.96, date: 'Jan 2023', isPredicted: false},
                {value: 36.96, date: 'Feb 2023', isPredicted: false},
                {value: 74.20, date: 'Mar 2023', isPredicted: false},
                {value: 65.70, date: 'Apr 2023', isPredicted: false},
                {value: 55.47, date: 'May 2023', isPredicted: false},
                {value: 157.46, date: 'Jun 2023', isPredicted: false},
                {value: 126.29, date: 'Jul 2023', isPredicted: false},
                {value: 240.41, date: 'Aug 2023', isPredicted: false},
                {value: 188.15, date: 'Sep 2023', isPredicted: false},
                {value: 142.89, date: 'Oct 2023', isPredicted: false},
                {value: 189.93, date: 'Nov 2023', isPredicted: false},
                {value: 234.95, date: 'Dec 2023', isPredicted: false},
                {value: 205.00, date: 'Jan 2024', isPredicted: false},
                {value: 160.00, date: 'Feb 2024', isPredicted: false},
                {value: 282.02, date: 'Mar 2024', isPredicted: true},
                {value: 264.05, date: 'Apr 2024', isPredicted: true},
                {value: 250.23, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 125750.10, date: 'Jan 2023', isPredicted: false},
                {value: 252466.28, date: 'Feb 2023', isPredicted: false},
                {value: 223518.08, date: 'Mar 2023', isPredicted: false},
                {value: 188719.96, date: 'Apr 2023', isPredicted: false},
                {value: 535721.17, date: 'May 2023', isPredicted: false},
                {value: 429668.65, date: 'Jun 2023', isPredicted: false},
                {value: 817947.21, date: 'Jul 2023', isPredicted: false},
                {value: 640132.60, date: 'Aug 2023', isPredicted: false},
                {value: 486148.81, date: 'Sep 2023', isPredicted: false},
                {value: 646201.32, date: 'Oct 2023', isPredicted: false},
                {value: 799371.83, date: 'Nov 2023', isPredicted: false},
                {value: 1272758.69, date: 'Dec 2023', isPredicted: false},
                {value: 1110509.00, date: 'Jan 2024', isPredicted: false},
                {value: 1505678.00, date: 'Feb 2024', isPredicted: false},
                {value: 2653942.60, date: 'Mar 2024', isPredicted: true},
                {value: 2484862.36, date: 'Apr 2024', isPredicted: true},
                {value: 2354781.66, date: 'May 2024', isPredicted: true},
            ]
        ],
    },
    43375: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 51.2, date: 'Jan 2023', isPredicted: false},
                {value: 50.25, date: 'Feb 2023', isPredicted: false},
                {value: 47.6875, date: 'Mar 2023', isPredicted: false},
                {value: 48.05, date: 'Apr 2023', isPredicted: false},
                {value: 48.1875, date: 'May 2023', isPredicted: false},
                {value: 48.4375, date: 'Jun 2023', isPredicted: false},
                {value: 57.55, date: 'Jul 2023', isPredicted: false},
                {value: 46.5, date: 'Aug 2023', isPredicted: false},
                {value: 44.6875, date: 'Sep 2023', isPredicted: false},
                {value: 40, date: 'Oct 2023', isPredicted: false},
                {value: 45.8125, date: 'Nov 2023', isPredicted: false},
                {value: 55.75, date: 'Dec 2023', isPredicted: false},
                {value: 59.1875, date: 'Jan 2024', isPredicted: false},
                {value: 55.625, date: 'Feb 2024', isPredicted: false},
                {value: 41.8525, date: 'Mar 2024', isPredicted: true},
                {value: 45.31, date: 'Apr 2024', isPredicted: true},
                {value: 42.8775, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 66.7, date: 'Jan 2023', isPredicted: false},
                {value: 68.3125, date: 'Feb 2023', isPredicted: false},
                {value: 69.875, date: 'Mar 2023', isPredicted: false},
                {value: 67.3, date: 'Apr 2023', isPredicted: false},
                {value: 65.3125, date: 'May 2023', isPredicted: false},
                {value: 65.9375, date: 'Jun 2023', isPredicted: false},
                {value: 71.6, date: 'Jul 2023', isPredicted: false},
                {value: 73.0625, date: 'Aug 2023', isPredicted: false},
                {value: 71.25, date: 'Sep 2023', isPredicted: false},
                {value: 68.25, date: 'Oct 2023', isPredicted: false},
                {value: 76.875, date: 'Nov 2023', isPredicted: false},
                {value: 84.95, date: 'Dec 2023', isPredicted: false},
                {value: 86.75, date: 'Jan 2024', isPredicted: false},
                {value: 84.0625, date: 'Feb 2024', isPredicted: false},
                {value: 56.42, date: 'Mar 2024', isPredicted: true},
                {value: 59.335, date: 'Apr 2024', isPredicted: true},
                {value: 56.825, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                5,
                10,
                2,
                3,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                3,
                5,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 869.6045968, date: 'Jan 2023', isPredicted: false},
                {value: 869.6045968, date: 'Feb 2023', isPredicted: false},
                {value: 1242.048666, date: 'Mar 2023', isPredicted: false},
                {value: 1156.964138, date: 'Apr 2023', isPredicted: false},
                {value: 1054.685537, date: 'May 2023', isPredicted: false},
                {value: 2074.591201, date: 'Jun 2023', isPredicted: false},
                {value: 1762.881735, date: 'Jul 2023', isPredicted: false},
                {value: 2904.109724, date: 'Aug 2023', isPredicted: false},
                {value: 2381.477176, date: 'Sep 2023', isPredicted: false},
                {value: 1928.888164, date: 'Oct 2023', isPredicted: false},
                {value: 2399.314373, date: 'Nov 2023', isPredicted: false},
                {value: 2849.512987, date: 'Dec 2023', isPredicted: false},
                {value: 2550, date: 'Jan 2024', isPredicted: false},
                {value: 3480.00, date: 'Feb 2024', isPredicted: false},
                {value: 3100.00, date: 'Mar 2024', isPredicted: true},
                {value: 2640.52459, date: 'Apr 2024', isPredicted: true},
                {value: 2502.295082, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1521808.04, date: 'Jan 2023', isPredicted: false},
                {value: 1521808.04, date: 'Feb 2023', isPredicted: false},
                {value: 2173585.16, date: 'Mar 2023', isPredicted: false},
                {value: 2024687.24, date: 'Apr 2023', isPredicted: false},
                {value: 1845699.69, date: 'May 2023', isPredicted: false},
                {value: 3630534.60, date: 'Jun 2023', isPredicted: false},
                {value: 3085043.04, date: 'Jul 2023', isPredicted: false},
                {value: 5082192.02, date: 'Aug 2023', isPredicted: false},
                {value: 4167585.06, date: 'Sep 2023', isPredicted: false},
                {value: 3375554.29, date: 'Oct 2023', isPredicted: false},
                {value: 4198800.15, date: 'Nov 2023', isPredicted: false},
                {value: 4986647.73, date: 'Dec 2023', isPredicted: false},
                {value: 4462500.00, date: 'Jan 2024', isPredicted: false},
                {value: 6960000.00, date: 'Feb 2024', isPredicted: false},
                {value: 5425000.00, date: 'Mar 2024', isPredicted: true},
                {value: 4620918.03, date: 'Apr 2024', isPredicted: true},
                {value: 4379016.39, date: 'May 2024', isPredicted: true},
            ]
        ],
    },
    43373: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 51.2, date: 'Jan 2023', isPredicted: false},
                {value: 50.25, date: 'Feb 2023', isPredicted: false},
                {value: 47.6875, date: 'Mar 2023', isPredicted: false},
                {value: 48.05, date: 'Apr 2023', isPredicted: false},
                {value: 48.1875, date: 'May 2023', isPredicted: false},
                {value: 48.4375, date: 'Jun 2023', isPredicted: false},
                {value: 57.55, date: 'Jul 2023', isPredicted: false},
                {value: 46.5, date: 'Aug 2023', isPredicted: false},
                {value: 44.6875, date: 'Sep 2023', isPredicted: false},
                {value: 40, date: 'Oct 2023', isPredicted: false},
                {value: 45.8125, date: 'Nov 2023', isPredicted: false},
                {value: 55.75, date: 'Dec 2023', isPredicted: false},
                {value: 59.1875, date: 'Jan 2024', isPredicted: false},
                {value: 55.625, date: 'Feb 2024', isPredicted: false},
                {value: 41.8525, date: 'Mar 2024', isPredicted: true},
                {value: 45.31, date: 'Apr 2024', isPredicted: true},
                {value: 42.8775, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 66.7, date: 'Jan 2023', isPredicted: false},
                {value: 68.3125, date: 'Feb 2023', isPredicted: false},
                {value: 69.875, date: 'Mar 2023', isPredicted: false},
                {value: 67.3, date: 'Apr 2023', isPredicted: false},
                {value: 65.3125, date: 'May 2023', isPredicted: false},
                {value: 65.9375, date: 'Jun 2023', isPredicted: false},
                {value: 71.6, date: 'Jul 2023', isPredicted: false},
                {value: 73.0625, date: 'Aug 2023', isPredicted: false},
                {value: 71.25, date: 'Sep 2023', isPredicted: false},
                {value: 68.25, date: 'Oct 2023', isPredicted: false},
                {value: 76.875, date: 'Nov 2023', isPredicted: false},
                {value: 84.95, date: 'Dec 2023', isPredicted: false},
                {value: 86.75, date: 'Jan 2024', isPredicted: false},
                {value: 84.0625, date: 'Feb 2024', isPredicted: false},
                {value: 56.42, date: 'Mar 2024', isPredicted: true},
                {value: 59.335, date: 'Apr 2024', isPredicted: true},
                {value: 56.825, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                5,
                10,
                2,
                3,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                3,
                5,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 869.6045968, date: 'Jan 2023', isPredicted: false},
                {value: 869.6045968, date: 'Feb 2023', isPredicted: false},
                {value: 1242.048666, date: 'Mar 2023', isPredicted: false},
                {value: 1156.964138, date: 'Apr 2023', isPredicted: false},
                {value: 1054.685537, date: 'May 2023', isPredicted: false},
                {value: 2074.591201, date: 'Jun 2023', isPredicted: false},
                {value: 1762.881735, date: 'Jul 2023', isPredicted: false},
                {value: 2904.109724, date: 'Aug 2023', isPredicted: false},
                {value: 2381.477176, date: 'Sep 2023', isPredicted: false},
                {value: 1928.888164, date: 'Oct 2023', isPredicted: false},
                {value: 2399.314373, date: 'Nov 2023', isPredicted: false},
                {value: 2849.512987, date: 'Dec 2023', isPredicted: false},
                {value: 2550, date: 'Jan 2024', isPredicted: false},
                {value: 3480.00, date: 'Feb 2024', isPredicted: false},
                {value: 3100.00, date: 'Mar 2024', isPredicted: true},
                {value: 2640.52459, date: 'Apr 2024', isPredicted: true},
                {value: 2502.295082, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1521808.04, date: 'Jan 2023', isPredicted: false},
                {value: 1521808.04, date: 'Feb 2023', isPredicted: false},
                {value: 2173585.16, date: 'Mar 2023', isPredicted: false},
                {value: 2024687.24, date: 'Apr 2023', isPredicted: false},
                {value: 1845699.69, date: 'May 2023', isPredicted: false},
                {value: 3630534.60, date: 'Jun 2023', isPredicted: false},
                {value: 3085043.04, date: 'Jul 2023', isPredicted: false},
                {value: 5082192.02, date: 'Aug 2023', isPredicted: false},
                {value: 4167585.06, date: 'Sep 2023', isPredicted: false},
                {value: 3375554.29, date: 'Oct 2023', isPredicted: false},
                {value: 4198800.15, date: 'Nov 2023', isPredicted: false},
                {value: 4986647.73, date: 'Dec 2023', isPredicted: false},
                {value: 4462500.00, date: 'Jan 2024', isPredicted: false},
                {value: 6960000.00, date: 'Feb 2024', isPredicted: false},
                {value: 5425000.00, date: 'Mar 2024', isPredicted: true},
                {value: 4620918.03, date: 'Apr 2024', isPredicted: true},
                {value: 4379016.39, date: 'May 2024', isPredicted: true},
            ]
        ],
    },
    43384: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 47.4, date: 'Jan 2023', isPredicted: false},
                {value: 41.75, date: 'Feb 2023', isPredicted: false},
                {value: 53.75, date: 'Mar 2023', isPredicted: false},
                {value: 42.6, date: 'Apr 2023', isPredicted: false},
                {value: 57.25, date: 'May 2023', isPredicted: false},
                {value: 63.25, date: 'Jun 2023', isPredicted: false},
                {value: 62.4, date: 'Jul 2023', isPredicted: false},
                {value: 71.5, date: 'Aug 2023', isPredicted: false},
                {value: 54, date: 'Sep 2023', isPredicted: false},
                {value: 43, date: 'Oct 2023', isPredicted: false},
                {value: 48.25, date: 'Nov 2023', isPredicted: false},
                {value: 31.6, date: 'Dec 2023', isPredicted: false},
                {value: 57.5, date: 'Jan 2024', isPredicted: false},
                {value: 41.5, date: 'Feb 2024', isPredicted: false},
                {value: 44.12, date: 'Mar 2024', isPredicted: true},
                {value: 39.02, date: 'Apr 2024', isPredicted: true},
                {value: 54.02, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 62.2, date: 'Jan 2023', isPredicted: false},
                {value: 65.5, date: 'Feb 2023', isPredicted: false},
                {value: 64.5, date: 'Mar 2023', isPredicted: false},
                {value: 64.4, date: 'Apr 2023', isPredicted: false},
                {value: 66.25, date: 'May 2023', isPredicted: false},
                {value: 67.75, date: 'Jun 2023', isPredicted: false},
                {value: 67.6, date: 'Jul 2023', isPredicted: false},
                {value: 63, date: 'Aug 2023', isPredicted: false},
                {value: 59.5, date: 'Sep 2023', isPredicted: false},
                {value: 62.6, date: 'Oct 2023', isPredicted: false},
                {value: 64.5, date: 'Nov 2023', isPredicted: false},
                {value: 58, date: 'Dec 2023', isPredicted: false},
                {value: 55, date: 'Jan 2024', isPredicted: false},
                {value: 47.75, date: 'Feb 2024', isPredicted: false},
                {value: 62.05, date: 'Mar 2024', isPredicted: true},
                {value: 63.83, date: 'Apr 2024', isPredicted: true},
                {value: 68.91, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 0.68, date: 'Jan 2023', isPredicted: false},
                {value: 1.36, date: 'Feb 2023', isPredicted: false},
                {value: 1.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.02, date: 'Apr 2023', isPredicted: false},
                {value: 2.89, date: 'May 2023', isPredicted: false},
                {value: 2.32, date: 'Jun 2023', isPredicted: false},
                {value: 4.42, date: 'Jul 2023', isPredicted: false},
                {value: 3.46, date: 'Aug 2023', isPredicted: false},
                {value: 2.63, date: 'Sep 2023', isPredicted: false},
                {value: 3.49, date: 'Oct 2023', isPredicted: false},
                {value: 4.32, date: 'Nov 2023', isPredicted: false},
                {value: 6.88, date: 'Dec 2023', isPredicted: false},
                {value: 6.00, date: 'Jan 2024', isPredicted: false},
                {value: 3.00, date: 'Feb 2024', isPredicted: false},
                {value: 5.29, date: 'Mar 2024', isPredicted: true},
                {value: 4.95, date: 'Apr 2024', isPredicted: true},
                {value: 4.69, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1482.02, date: 'Jan 2023', isPredicted: false},
                {value: 2975.42, date: 'Feb 2023', isPredicted: false},
                {value: 2634.26, date: 'Mar 2023', isPredicted: false},
                {value: 2224.14, date: 'Apr 2023', isPredicted: false},
                {value: 6313.70, date: 'May 2023', isPredicted: false},
                {value: 5063.83, date: 'Jun 2023', isPredicted: false},
                {value: 9639.85, date: 'Jul 2023', isPredicted: false},
                {value: 7544.23, date: 'Aug 2023', isPredicted: false},
                {value: 5729.47, date: 'Sep 2023', isPredicted: false},
                {value: 7615.76, date: 'Oct 2023', isPredicted: false},
                {value: 9420.94, date: 'Nov 2023', isPredicted: false},
                {value: 17191.56, date: 'Dec 2023', isPredicted: false},
                {value: 15000.00, date: 'Jan 2024', isPredicted: false},
                {value: 26000.00, date: 'Feb 2024', isPredicted: false},
                {value: 45828.20, date: 'Mar 2024', isPredicted: true},
                {value: 42908.52, date: 'Apr 2024', isPredicted: true},
                {value: 40662.30, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43385: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 70, date: 'Jan 2023', isPredicted: false},
                {value: 70.1875, date: 'Feb 2023', isPredicted: false},
                {value: 70.5625, date: 'Mar 2023', isPredicted: false},
                {value: 66.25, date: 'Apr 2023', isPredicted: false},
                {value: 63, date: 'May 2023', isPredicted: false},
                {value: 61.9375, date: 'Jun 2023', isPredicted: false},
                {value: 63.85, date: 'Jul 2023', isPredicted: false},
                {value: 62.625, date: 'Aug 2023', isPredicted: false},
                {value: 61.3125, date: 'Sep 2023', isPredicted: false},
                {value: 56.8, date: 'Oct 2023', isPredicted: false},
                {value: 71.8125, date: 'Nov 2023', isPredicted: false},
                {value: 78.3, date: 'Dec 2023', isPredicted: false},
                {value: 82.0625, date: 'Jan 2024', isPredicted: false},
                {value: 74.625, date: 'Feb 2024', isPredicted: false},
                {value: 56.44527778, date: 'Mar 2024', isPredicted: true},
                {value: 57.1625, date: 'Apr 2024', isPredicted: true},
                {value: 54.4025, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 68.8, date: 'Jan 2023', isPredicted: false},
                {value: 69.4375, date: 'Feb 2023', isPredicted: false},
                {value: 75.3125, date: 'Mar 2023', isPredicted: false},
                {value: 67.45, date: 'Apr 2023', isPredicted: false},
                {value: 62, date: 'May 2023', isPredicted: false},
                {value: 62.875, date: 'Jun 2023', isPredicted: false},
                {value: 67.15, date: 'Jul 2023', isPredicted: false},
                {value: 69.0625, date: 'Aug 2023', isPredicted: false},
                {value: 69.9375, date: 'Sep 2023', isPredicted: false},
                {value: 65.1, date: 'Oct 2023', isPredicted: false},
                {value: 80.375, date: 'Nov 2023', isPredicted: false},
                {value: 88.7, date: 'Dec 2023', isPredicted: false},
                {value: 89.4375, date: 'Jan 2024', isPredicted: false},
                {value: 82.5, date: 'Feb 2024', isPredicted: false},
                {value: 54.94833333, date: 'Mar 2024', isPredicted: true},
                {value: 57.2025, date: 'Apr 2024', isPredicted: true},
                {value: 54.0375, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 0.68, date: 'Jan 2023', isPredicted: false},
                {value: 1.36, date: 'Feb 2023', isPredicted: false},
                {value: 1.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.02, date: 'Apr 2023', isPredicted: false},
                {value: 2.89, date: 'May 2023', isPredicted: false},
                {value: 2.32, date: 'Jun 2023', isPredicted: false},
                {value: 4.42, date: 'Jul 2023', isPredicted: false},
                {value: 3.46, date: 'Aug 2023', isPredicted: false},
                {value: 2.63, date: 'Sep 2023', isPredicted: false},
                {value: 3.49, date: 'Oct 2023', isPredicted: false},
                {value: 4.32, date: 'Nov 2023', isPredicted: false},
                {value: 6.88, date: 'Dec 2023', isPredicted: false},
                {value: 6.00, date: 'Jan 2024', isPredicted: false},
                {value: 3.00, date: 'Feb 2024', isPredicted: false},
                {value: 5.29, date: 'Mar 2024', isPredicted: true},
                {value: 4.95, date: 'Apr 2024', isPredicted: true},
                {value: 4.69, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1482.02, date: 'Jan 2023', isPredicted: false},
                {value: 2975.42, date: 'Feb 2023', isPredicted: false},
                {value: 2634.26, date: 'Mar 2023', isPredicted: false},
                {value: 2224.14, date: 'Apr 2023', isPredicted: false},
                {value: 6313.70, date: 'May 2023', isPredicted: false},
                {value: 5063.83, date: 'Jun 2023', isPredicted: false},
                {value: 9639.85, date: 'Jul 2023', isPredicted: false},
                {value: 7544.23, date: 'Aug 2023', isPredicted: false},
                {value: 5729.47, date: 'Sep 2023', isPredicted: false},
                {value: 7615.76, date: 'Oct 2023', isPredicted: false},
                {value: 9420.94, date: 'Nov 2023', isPredicted: false},
                {value: 17191.56, date: 'Dec 2023', isPredicted: false},
                {value: 15000.00, date: 'Jan 2024', isPredicted: false},
                {value: 26000.00, date: 'Feb 2024', isPredicted: false},
                {value: 45828.20, date: 'Mar 2024', isPredicted: true},
                {value: 42908.52, date: 'Apr 2024', isPredicted: true},
                {value: 40662.30, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43386: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 67.6, date: 'Jan 2023', isPredicted: false},
                {value: 63.08333333, date: 'Feb 2023', isPredicted: false},
                {value: 65.5, date: 'Mar 2023', isPredicted: false},
                {value: 65.2, date: 'Apr 2023', isPredicted: false},
                {value: 68.83333333, date: 'May 2023', isPredicted: false},
                {value: 68.5, date: 'Jun 2023', isPredicted: false},
                {value: 71.4, date: 'Jul 2023', isPredicted: false},
                {value: 64.75, date: 'Aug 2023', isPredicted: false},
                {value: 58.08333333, date: 'Sep 2023', isPredicted: false},
                {value: 60.2, date: 'Oct 2023', isPredicted: false},
                {value: 66.91666667, date: 'Nov 2023', isPredicted: false},
                {value: 66.06666667, date: 'Dec 2023', isPredicted: false},
                {value: 70.5, date: 'Jan 2024', isPredicted: false},
                {value: 67, date: 'Feb 2024', isPredicted: false},
                {value: 54.95, date: 'Mar 2024', isPredicted: true},
                {value: 54.59, date: 'Apr 2024', isPredicted: true},
                {value: 56.76, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 70.66666667, date: 'Jan 2023', isPredicted: false},
                {value: 71.16666667, date: 'Feb 2023', isPredicted: false},
                {value: 69, date: 'Mar 2023', isPredicted: false},
                {value: 72.73333333, date: 'Apr 2023', isPredicted: false},
                {value: 70.91666667, date: 'May 2023', isPredicted: false},
                {value: 72.83333333, date: 'Jun 2023', isPredicted: false},
                {value: 73, date: 'Jul 2023', isPredicted: false},
                {value: 74.41666667, date: 'Aug 2023', isPredicted: false},
                {value: 72.66666667, date: 'Sep 2023', isPredicted: false},
                {value: 68.8, date: 'Oct 2023', isPredicted: false},
                {value: 75, date: 'Nov 2023', isPredicted: false},
                {value: 77.86666667, date: 'Dec 2023', isPredicted: false},
                {value: 84.5, date: 'Jan 2024', isPredicted: false},
                {value: 80.08333333, date: 'Feb 2024', isPredicted: false},
                {value: 56.93, date: 'Mar 2024', isPredicted: true},
                {value: 61.11333333, date: 'Apr 2024', isPredicted: true},
                {value: 60.58666667, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                0,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                6,
                4,
                8,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 1.25, date: 'Jan 2023', isPredicted: false},
                {value: 2.50, date: 'Feb 2023', isPredicted: false},
                {value: 2.21, date: 'Mar 2023', isPredicted: false},
                {value: 1.87, date: 'Apr 2023', isPredicted: false},
                {value: 5.31, date: 'May 2023', isPredicted: false},
                {value: 4.26, date: 'Jun 2023', isPredicted: false},
                {value: 8.10, date: 'Jul 2023', isPredicted: false},
                {value: 6.34, date: 'Aug 2023', isPredicted: false},
                {value: 4.82, date: 'Sep 2023', isPredicted: false},
                {value: 6.40, date: 'Oct 2023', isPredicted: false},
                {value: 7.92, date: 'Nov 2023', isPredicted: false},
                {value: 12.61, date: 'Dec 2023', isPredicted: false},
                {value: 11.00, date: 'Jan 2024', isPredicted: false},
                {value: 15.00, date: 'Feb 2024', isPredicted: false},
                {value: 26.44, date: 'Mar 2024', isPredicted: true},
                {value: 24.75, date: 'Apr 2024', isPredicted: true},
                {value: 23.46, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 1185.61, date: 'Jan 2023', isPredicted: false},
                {value: 2380.34, date: 'Feb 2023', isPredicted: false},
                {value: 2107.40, date: 'Mar 2023', isPredicted: false},
                {value: 1779.32, date: 'Apr 2023', isPredicted: false},
                {value: 5050.96, date: 'May 2023', isPredicted: false},
                {value: 4051.06, date: 'Jun 2023', isPredicted: false},
                {value: 7711.88, date: 'Jul 2023', isPredicted: false},
                {value: 6035.39, date: 'Aug 2023', isPredicted: false},
                {value: 4583.58, date: 'Sep 2023', isPredicted: false},
                {value: 6092.60, date: 'Oct 2023', isPredicted: false},
                {value: 7536.75, date: 'Nov 2023', isPredicted: false},
                {value: 13753.25, date: 'Dec 2023', isPredicted: false},
                {value: 12000.00, date: 'Jan 2024', isPredicted: false},
                {value: 24000.00, date: 'Feb 2024', isPredicted: false},
                {value: 42302.95, date: 'Mar 2024', isPredicted: true},
                {value: 39607.87, date: 'Apr 2024', isPredicted: true},
                {value: 37534.43, date: 'May 2024', isPredicted: true},
            ],
        ],
    },
    43387: {
        [GraphType.INTERESTS]: [
            // USA
            [
                {value: 13.06666667, date: 'Jan 2023', isPredicted: false},
                {value: 19.625, date: 'Feb 2023', isPredicted: false},
                {value: 17.08333333, date: 'Mar 2023', isPredicted: false},
                {value: 13.93333333, date: 'Apr 2023', isPredicted: false},
                {value: 22.95833333, date: 'May 2023', isPredicted: false},
                {value: 17.29166667, date: 'Jun 2023', isPredicted: false},
                {value: 25.5, date: 'Jul 2023', isPredicted: false},
                {value: 18.41666667, date: 'Aug 2023', isPredicted: false},
                {value: 12.58333333, date: 'Sep 2023', isPredicted: false},
                {value: 15.7, date: 'Oct 2023', isPredicted: false},
                {value: 18.70833333, date: 'Nov 2023', isPredicted: false},
                {value: 25.66666667, date: 'Dec 2023', isPredicted: false},
                {value: 21.91666667, date: 'Jan 2024', isPredicted: false},
                {value: 10.16666667, date: 'Feb 2024', isPredicted: false},
                {value: 17.92, date: 'Mar 2024', isPredicted: true},
                {value: 16.77833333, date: 'Apr 2024', isPredicted: true},
                {value: 15.9, date: 'May 2024', isPredicted: true},
            ],
            // World
            [
                {value: 25.7, date: 'Jan 2023', isPredicted: false},
                {value: 23.54166667, date: 'Feb 2023', isPredicted: false},
                {value: 30.20833333, date: 'Mar 2023', isPredicted: false},
                {value: 30.7, date: 'Apr 2023', isPredicted: false},
                {value: 27.58333333, date: 'May 2023', isPredicted: false},
                {value: 29.125, date: 'Jun 2023', isPredicted: false},
                {value: 31.56666667, date: 'Jul 2023', isPredicted: false},
                {value: 27.375, date: 'Aug 2023', isPredicted: false},
                {value: 26.33333333, date: 'Sep 2023', isPredicted: false},
                {value: 28.03333333, date: 'Oct 2023', isPredicted: false},
                {value: 40, date: 'Nov 2023', isPredicted: false},
                {value: 42.83333333, date: 'Dec 2023', isPredicted: false},
                {value: 29.66666667, date: 'Jan 2024', isPredicted: false},
                {value: 32.41666667, date: 'Feb 2024', isPredicted: false},
                {value: 26.835, date: 'Mar 2024', isPredicted: true},
                {value: 27.02333333, date: 'Apr 2024', isPredicted: true},
                {value: 27.24, date: 'May 2024', isPredicted: true},
            ],
        ],
        [GraphType.ADS]: [
            [
                3,
                1,
                1,
                1,
                1,
                5,
                null,
                null,
                null,
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                7,
                7,
            ],
        ],
        [GraphType.MENTIONS]: [
            // Mentions
            [
                {value: 36.96, date: 'Jan 2023', isPredicted: false},
                {value: 36.96, date: 'Feb 2023', isPredicted: false},
                {value: 74.20, date: 'Mar 2023', isPredicted: false},
                {value: 65.70, date: 'Apr 2023', isPredicted: false},
                {value: 55.47, date: 'May 2023', isPredicted: false},
                {value: 157.46, date: 'Jun 2023', isPredicted: false},
                {value: 126.29, date: 'Jul 2023', isPredicted: false},
                {value: 240.41, date: 'Aug 2023', isPredicted: false},
                {value: 188.15, date: 'Sep 2023', isPredicted: false},
                {value: 142.89, date: 'Oct 2023', isPredicted: false},
                {value: 189.93, date: 'Nov 2023', isPredicted: false},
                {value: 234.95, date: 'Dec 2023', isPredicted: false},
                {value: 205.00, date: 'Jan 2024', isPredicted: false},
                {value: 160.00, date: 'Feb 2024', isPredicted: false},
                {value: 282.02, date: 'Mar 2024', isPredicted: true},
                {value: 264.05, date: 'Apr 2024', isPredicted: true},
                {value: 250.23, date: 'May 2024', isPredicted: true},
            ],
            // Reach
            [
                {value: 125750.10, date: 'Jan 2023', isPredicted: false},
                {value: 252466.28, date: 'Feb 2023', isPredicted: false},
                {value: 223518.08, date: 'Mar 2023', isPredicted: false},
                {value: 188719.96, date: 'Apr 2023', isPredicted: false},
                {value: 535721.17, date: 'May 2023', isPredicted: false},
                {value: 429668.65, date: 'Jun 2023', isPredicted: false},
                {value: 817947.21, date: 'Jul 2023', isPredicted: false},
                {value: 640132.60, date: 'Aug 2023', isPredicted: false},
                {value: 486148.81, date: 'Sep 2023', isPredicted: false},
                {value: 646201.32, date: 'Oct 2023', isPredicted: false},
                {value: 799371.83, date: 'Nov 2023', isPredicted: false},
                {value: 1272758.69, date: 'Dec 2023', isPredicted: false},
                {value: 1110509.00, date: 'Jan 2024', isPredicted: false},
                {value: 1505678.00, date: 'Feb 2024', isPredicted: false},
                {value: 2653942.60, date: 'Mar 2024', isPredicted: true},
                {value: 2484862.36, date: 'Apr 2024', isPredicted: true},
                {value: 2354781.66, date: 'May 2024', isPredicted: true},
            ]
        ],
    },
};
