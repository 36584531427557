import {memo, useMemo, useEffect, Fragment, useCallback} from 'react';

import {SelectModal} from 'components/common/selectModal';
import {useAppContext} from 'components/app/AppContext';
import {useDeleteProduct, useDeleteProducts} from 'queries/products';

const REASONS = [
    'Uploaded by mistake',
    'Product discontinued',
    'Duplicate',
    'Another reason',
];

export const DeleteProductModal = memo(() => {
    const {
        product,
        setProduct,
        setIsSelectModalOpen,
        setIsSuccessDeleteModalOpen,
        setIsProductModalOpen,
        deletingIds,
    } = useAppContext();
    const [{data, isPending, error}, fetch] = useDeleteProduct(Number(product?.id));
    const [deletedPack, fetchDeletingPack] = useDeleteProducts();

    const handleClose = useCallback(() => {
        setIsSelectModalOpen(false);
        setIsProductModalOpen(false);
        setProduct(null);
    }, [setProduct, setIsSelectModalOpen, setIsProductModalOpen]);

    const handleReport = useCallback((text?: string) => {
        if (Boolean(product?.id)) {
            fetch({deletedReason: text});
            return;
        }

        if (Boolean(deletingIds.length)) {
            fetchDeletingPack({
                deletedReason: text,
                // @ts-ignore
                ids: deletingIds,
            });
        }
    }, [product?.id]);

    const title = useMemo(() => (
        <>
            Why do you want
            <Fragment><br/></Fragment>
            to delete {Boolean(product) ? 'this' : `${deletingIds.length} items`}
            &nbsp;from Products?
        </>
    ), [deletingIds, product]);

    useEffect(() => {
        if ((Boolean(product) && !error && typeof data === 'string')
            || (Boolean(deletingIds.length) && !deletedPack?.error && Boolean(deletedPack?.data))
        ) {
            handleClose();
            setIsSuccessDeleteModalOpen(true);
        }
    }, [error, data, product, deletingIds, deletedPack?.error, deletedPack?.data, handleClose, setIsSuccessDeleteModalOpen]);

    return (
        <SelectModal
            title={title}
            reasons={REASONS}
            isLoading={isPending}
            onClose={handleClose}
            onSubmit={handleReport}
        />
    );
});
