import {memo, ReactNode, useCallback, useMemo} from 'react';

import {ScoreResult} from 'components/blocks/scoreResult/ScoreResult';
import {Picture} from 'components/common/picture';
import {useAppContext} from 'components/app/AppContext';
import {ScoreSentiments, TAudienceFeature, TProduct} from 'core/types';
import {ReactComponent as PeopleIcon} from 'assets/images/people.svg';
import {ReactComponent as GeoIcon} from 'assets/images/geo.svg';
import {ReactComponent as FeedbackIcon} from 'assets/images/feedback.svg';
import {ReactComponent as InfoIcon} from 'assets/images/info-icon.svg';
import AmazonIcon from 'assets/images/amazon.png';
import MetaIcon from 'assets/images/meta.png';
import styles from './Highlights.module.css';

export const Highlights = memo<IProps>((props) => {
    const {product, score, demography, trends, feedbacks, interestsInfo, possibleCustomers} = props;
    const {name, brand, productImages} = product;
    const updated = new Date().toLocaleDateString('ru-RU');

    const {
        setProduct,
        setIsProductModalOpen,
    } = useAppContext();

    const handleModalOpenClick = useCallback(() => {
        if (!product) return null;

        setProduct(product);
        setIsProductModalOpen(true);
    }, [product]);

    const PictureControl = useMemo(() => (
        <div className={styles.pictureControl} onClick={handleModalOpenClick}>
            <InfoIcon />
        </div>
    ), []);

    const amazonInfo = useMemo(() => {
        const {positiveEffects} = product;

        if (positiveEffects?.length) {
            return positiveEffects.join(', ');
        }

        return 'No recommendation';
    }, [product.positiveEffects]);

    const metaInfo = useMemo(() => {
        if (!interestsInfo?.length) {
            return 'No recommendation';
        }

        const [data] = interestsInfo;
        const filteredData = [
            data.skin_type,
            data.skin_sensitive,
            data.skin_concerns,
            data.skin_goals,
            data.ingredient_preferences
        ].filter(Boolean)
            .flat()
            .filter((val) => val?.sentiment === ScoreSentiments.positive)
            .map((val) => val?.value);

        return filteredData?.length > 0 ? filteredData.join(', ') : 'No recommendation';
    }, [interestsInfo]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.picture}>
                <Picture
                    url={productImages?.[0].url || null}
                    cornerControl={PictureControl}
                    isMainPicture
                    hidePictureMenu
                />
            </div>
            <div className={styles.texts}>
                <div className={styles.top}>
                    <div className={styles.title}>{brand}</div>
                    <div className={styles.subtitle}>{name}</div>
                </div>
                <div className={styles.blocks}>
                    <HighlightBlock
                        icon={<PeopleIcon />}
                        count={(possibleCustomers || demography || 0).toLocaleString('ru-RU')}
                        sign='Possible customers for&nbsp;this product'
                    />
                    <HighlightBlock
                        icon={<GeoIcon />}
                        count={`${trends.toFixed(0)}%`}
                        sign='Match with trends in&nbsp;this location'
                    />
                    <HighlightBlock
                        icon={<FeedbackIcon />}
                        count={String(feedbacks)}
                        sign='Feedbacks about this&nbsp;product'
                    />
                </div>
                <div className={styles.suggestions}>
                    <div className={styles.title}>
                        Suggestions for promotion:
                    </div>
                    <div className={styles.row}>
                        <img
                            src={AmazonIcon}
                            alt=''
                            className={styles.icon}
                        />
                        <span className={styles.text}>
                            <b>Amazon KeyWords:&nbsp;</b>
                            {amazonInfo}
                        </span>
                    </div>
                    <div className={styles.row}>
                        <img
                            src={MetaIcon}
                            alt=''
                            className={styles.icon}
                        />
                        <span className={styles.text}>
                            <b>Meta Interests:&nbsp;</b>
                            {metaInfo}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.rightCorner}>
                {score !== undefined && (
                    <ScoreResult score={score} label='CITY MATCH SCORE' />
                )}
                <div className={styles.date}>
                    Updated:&nbsp;{updated}
                </div>
            </div>
        </div>
    );
});

interface IProps {
    product: TProduct;
    score: number | undefined;
    demography: number;
    trends: number;
    feedbacks: number;
    interestsInfo: Array<TAudienceFeature> | undefined;
    possibleCustomers: number | undefined;
}

const HighlightBlock = memo<IHighlightBlockProps>(({icon, count, sign}) => (
    <div className={styles.block}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.title}>{count}</div>
        <div className={styles.sign}>{sign}</div>
    </div>
));

interface IHighlightBlockProps {
    icon: ReactNode;
    count: string;
    sign: string;
}
