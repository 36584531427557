import {memo, useState, useCallback, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {TCurrentUser} from 'core/types';
import {ROUTES, USER_ROLES} from 'core/constants';
import {useOutsideClicker} from 'core/hooks';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as DefaultUserPicture} from 'assets/images/user-default-pic.svg';
import {ReactComponent as ArrowIcon} from 'assets/images/arrow.svg';
import styles from './UserMenu.module.css';

export const UserMenu = memo<IProps>(({user}) => {
    const [opened, setOpened] = useState<boolean>(false);
    const {setUser, setIsSubscriptionModalOpen, balance} = useAppContext();
    const dropdownRef = useRef(null);
    // const {pathname} = useLocation();
    // const canExtendSubscription = user.role === USER_ROLES.brand && pathname === ROUTES.CITY_MATCH;
    const canExtendSubscription = user.role === USER_ROLES.brand;

    const handleLogOut = useCallback(() => {
        setOpened(false);
        localStorage.removeItem('token');
        localStorage.removeItem('was-first-request-modal-opened');
        setUser(null);
    }, []);

    useOutsideClicker(dropdownRef, () => {setOpened(false)});

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.main}
                onClick={() => setOpened(!opened)}
            >
                <DefaultUserPicture />
                <div className={styles.info}>
                    <div className={styles.top}>{user.name}</div>
                    <div className={styles.bottom}>
                        {balance !== null && canExtendSubscription
                        ? <><b>{balance}</b> requests left</>
                        : <>{user.role}</>}
                    </div>
                </div>
                <div className={opened ? styles.rotatedArrow : styles.arrow}>
                    <ArrowIcon />
                </div>
            </div>
            <div className={opened ? styles.menu : styles.menuHidden} ref={dropdownRef}>
                {canExtendSubscription && (
                    <div
                        className={styles.menuItem}
                        onClick={() => {
                            setOpened(false);
                            setIsSubscriptionModalOpen(true);
                        }}
                    >
                        Extend subscription
                    </div>
                )}
                <Link
                    className={styles.menuItem}
                    onClick={handleLogOut}
                    to={ROUTES.HOME}
                >
                    Log out
                </Link>
            </div>
        </div>
    );
});

interface IProps {
    user: TCurrentUser;
}
