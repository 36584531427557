import {Fragment, memo} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {ReactComponent as Icon} from 'assets/images/saveSuccess.svg';
import styles from './SuccessSaveModal.module.css';

export const SuccessSaveModal = memo(() => {
    const {setIsSuccessSaveModalOpen, setRefresh, setProduct} = useAppContext();

    const handleClose = () => {
        setProduct(null);
        setRefresh(true);
        setIsSuccessSaveModalOpen(false);
    };

    return (
        <Modal onClose={handleClose}>
            <div className={styles.wrapper}>
                <Icon />
                <div className={styles.title}>
                    Item was successfully
                    <Fragment><br/></Fragment>
                    saved!
                </div>
                <div className={styles.button}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
