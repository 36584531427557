const mockDataReal = {
    label: 'Real data',
    backgroundColor: '#BCE1EA',
    skipNull: true,
}

const mockDataPrediction = {
    label: 'Predicted data',
    backgroundColor: '#FFE896',
    skipNull: true,
}

export const mockDatasets = {
    datasets: [
        mockDataReal,
        mockDataPrediction,
    ],
}
