import {memo, useCallback, useMemo} from 'react';
import cn from 'classnames';

import {ProductFieldType, TagTypes, TProduct, TProductField, TProductProperties} from 'core/types';
import {checkRequiredProductField, getProductFieldValue} from 'core/utils';
import {PRODUCT_FIELD_PLACEHOLDER, MAX_LENGTH} from 'core/constants';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {Input} from 'components/common/input';
import {Tag} from 'components/common/tag';
import styles from './ProductField.module.css';

export const ProductField = memo<IProductField>(function ProductField({
    field,
    product,
    editedProduct,
    properties,
    isActive,
    isNotEditable, // TODO: переделать под собственный конфиг филда
    showInvalid = false,
    onClick,
    onChange,
    readMode = false,
    strictValue,
}) {
    const {key, type, label, wrap, visibilityConfig} = field;

    const value = useMemo(() => {
        if (!strictValue) {
            return getProductFieldValue(key, type, product, properties);
        }
        return strictValue;
    }, [key, type, properties, product, strictValue]);

    const isInvalid = useMemo(() => {
        if (!showInvalid || !editedProduct || !Object.values(editedProduct).length) return false;

        return !checkRequiredProductField(editedProduct, key);
    }, [showInvalid, key, editedProduct]);

    const NoNode = useMemo(() => (
        <div className={styles.noField}>
            Not filled
        </div>
    ), []);

    const createTagsNodes = useCallback((tags: unknown) => {
        //@ts-ignore
        if (!tags?.length) {
            return NoNode;
        }
        //@ts-ignore
        return tags?.map(({text}, index) => (
            <Tag text={text} key={`modal_${text}_${index}`} type={TagTypes.BIG} />
        )) || [];
    }, []);

    const isFieldVisible = useMemo(() => {
        if (!visibilityConfig) {
            return true;
        }

        if (!editedProduct) {
            return false;
        }

        // ALARM Пока работает только для Availability кейса!
        const entered = editedProduct[visibilityConfig.dependField];
        const valueForCompare = Array.isArray(entered) ? entered[0] : entered;

        return valueForCompare === visibilityConfig.visibilityValue;
    }, [editedProduct, visibilityConfig]);

    if (!isFieldVisible) {
        return null;
    }

    if (readMode) {
        const {type} = PRODUCT_FIELDS_CONFIG.find((configField) => configField.key === key) || {};
        //@ts-ignore
        const isSPFNotFilled = key === 'spf' && value?.[0]?.text === null;
        const isTag = (type === ProductFieldType.tags || type === ProductFieldType.oneFromTags) && !isSPFNotFilled;

        return (
            <div className={styles.infoCard}>
                <div className={styles.infoCardTitle}>{label}</div>
                <div className={cn({[styles.infoCardTags]: isTag})}>
                    {isTag ? createTagsNodes(value) : isSPFNotFilled ? NoNode : (value || NoNode)}
                </div>
            </div>
        );
    }

    return (
        <Input
            label={label}
            // @ts-ignore
            value={value}
            placeholder={PRODUCT_FIELD_PLACEHOLDER[type]}
            isActive={isActive}
            isInvalid={isInvalid}
            onClick={onClick}
            onChange={onChange}
            notEditable={isNotEditable}
            wrap={wrap && Number(value?.length) > MAX_LENGTH}
            onlyOneTagCanBeChosen={key === 'spf' || key === 'gender'} // TODO: вынести в словарь
        />
    );
});

interface IProductField {
    field: TProductField,
    product?: TProduct | null,
    editedProduct?: TProduct | null,
    properties?: TProductProperties | null,
    isActive?: boolean,
    showInvalid?: boolean,
    isNotEditable?: boolean,
    onClick?: (value: string) => void;
    onChange?: (value: string | string[]) => void;
    readMode?: boolean;
    strictValue?: string; // железно хотим засетить значение, а не брать его из продукта
}
