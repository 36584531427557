import {useRequest} from 'core/hooks';
import {TErrorsCount, TPatchScan, TProductSort, TScan, TDeleteItem} from 'core/types';
import {MAX_ITEM_COUNT_FOR_PAGE} from 'core/constants';

const SCANS_BASE_URL = '/scans';

export const useScansCount = () =>
    useRequest<number, TErrorsCount>((productId) => {
        const whereClause = Boolean(productId)
            ? ({
                productId: productId,
                status: 'new',
            })
            : ({
                isMisstake: true,
            });

        return {
            url: `${SCANS_BASE_URL}/count`,
            method: 'GET',
            params: {
                where: whereClause,
            },
        };
    }, true);

export const useScansMatches = () =>
    useRequest<TProductSort, Array<TScan>>(({offset, order, group}) => ({
        url: SCANS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                order: order || 'updatedDate desc',
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                where: {
                    and: [
                        {
                            isMisstake: false,
                        },
                        {
                            productId: {
                                neq: 0,
                            },
                        },
                        {
                            productId: {
                                neq: null,
                            },
                        },
                        {
                            inProgress: false,
                        },
                        {
                            productGroup: group,
                        },
                    ],
                },
                include: [
                    'scanImages',
                    'productSearchLog',
                    {
                        relation: 'product',
                        scope: {
                            include: [
                                {
                                    'relation': 'productImages',
                                },
                                {
                                    'relation': 'citiesInfos',
                                },
                            ],
                        },
                    },
                ],
            }
        }
    }), true);

export const useScansMatchesCount = () =>
    useRequest<string, TErrorsCount>((group) => ({
        url: `${SCANS_BASE_URL}/count`,
        method: 'GET',
        params: {
            where: {
                and: [
                    {
                        isMisstake: false,
                    },
                    {
                        productId: {
                            neq: 0,
                        }
                    },
                    {
                        productId: {
                            neq: null,
                        }
                    },
                    {
                        productGroup: group,
                    }
                ]
            }
        }
    }), true);

export const useScansInProgress = () =>
    useRequest<TProductSort, Array<TScan>>(({offset, order}) => ({
        url: SCANS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                order: order || 'id desc',
                where: {
                    and: [
                        {
                            or: [
                                {productId: 0},
                                {productId: null},
                                {inProgress: true},
                            ],
                        },
                        {isMisstake: false},
                    ]
                },
                include: [
                    'scanImages',
                    'productSearchLog',
                    {
                        relation: 'product',
                        scope: {
                            include: [
                                {
                                    'relation': 'productImages',
                                },
                                {
                                    'relation': 'citiesInfos',
                                },
                            ],
                        },
                    },
                ],
            }
        }
    }), true);

export const useScansInProgressMissed = () =>
    useRequest<TProductSort, Array<TScan>>(({offset, order}) => ({
        url: SCANS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                order: order || 'id desc',
                where: {
                    and: [
                        {isMisstake: false},
                        {inProgress: true},
                        {not: {productId: 0}},
                    ]
                },
                include: [
                    'scanImages',
                    'productSearchLog',
                    {
                        relation: 'product',
                        scope: {
                            include: [
                                {
                                    'relation': 'productImages',
                                },
                                {
                                    'relation': 'citiesInfos',
                                },
                            ],
                        },
                    },
                ],
            }
        }
    }), true);

export const useScansInProgressWithoutProduct = () =>
    useRequest<TProductSort, Array<TScan>>(({offset, order}) => ({
        url: SCANS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                order: order || 'id desc',
                where: {
                    and: [
                        {
                            or: [
                                {productId: 0},
                                {productId: null},
                            ],
                        },
                        {isMisstake: false},
                    ]
                },
                include: [
                    'scanImages',
                    'productSearchLog',
                    {
                        relation: 'product',
                        scope: {
                            include: [
                                {
                                    'relation': 'productImages',
                                },
                                {
                                    'relation': 'citiesInfos',
                                },
                            ],
                        },
                    },
                ],
            }
        }
    }), true);

export const useScansInProgressCount = () =>
    useRequest<string, TErrorsCount>((key) => {
        const filter: any = {
            where: {
                and: [
                    {
                        or: [
                            {productId: 0},
                            {productId: null},
                            {inProgress: true},
                        ],
                    },
                    {isMisstake: false},
                ]
            },
        };

        if (key === 'missing') {
            filter.where = {
                and: [
                    {isMisstake: false},
                    {inProgress: true},
                    {not: {productId: 0}},
                ]
            }
        }

        if (key === 'new') {
            filter.where = {
                and: [
                    {
                        or: [
                            {productId: 0},
                            {productId: null},
                        ],
                    },
                    {isMisstake: false},
                ],
            }
        }

        return {
            url: `${SCANS_BASE_URL}/count`,
            method: 'GET',
            params: filter,
        };
    }, true);

export const useScansErrors = () =>
    useRequest<TProductSort, Array<TScan>>(({offset,order}) => ({
        url: SCANS_BASE_URL,
        method: 'GET',
        params: {
            filter: {
                offset: offset,
                limit: MAX_ITEM_COUNT_FOR_PAGE,
                order: order || 'id desc',
                where: {
                    and: [
                        {
                            isMisstake: true,
                        },
                        {
                            inProgress: false,
                        },
                    ],
                },
                include: [
                    'scanImages',
                    'productSearchLog',
                    {
                        relation: 'product',
                        scope: {
                            include: [
                                {
                                    relation: 'productImages',
                                },
                                {
                                    'relation': 'citiesInfos',
                                },
                            ],
                        },
                    }
                ]

            },
        },
    }), true);

export const useScansErrorsCount = () =>
    useRequest<void, TErrorsCount>(() => ({
        url: `${SCANS_BASE_URL}/count`,
        method: 'GET',
        params: {
            where: {
                isMisstake: true,
            }
        }
    }), true);


export const usePatchScan = () =>
    useRequest<TPatchScan, string>(({scanId, productId}) => ({
        url: `${SCANS_BASE_URL}/${scanId}`,
        method: 'PATCH',
        data: {
            productId: productId,
            isMisstake: false,
        },
    }), true);

export const useAllScans = () =>
    useRequest<number, Array<TScan>>((productId) => ({
        url: `${SCANS_BASE_URL}`,
        method: 'GET',
        params: {
            filter: {
                where: {
                    productId: productId,
                },
                include: [
                    'scanImages'
                ],
            },
        }
    }), true);

export const useDeleteScan = (id: number) =>
    useRequest<TDeleteItem | null, string>((body) => ({
        url: `${SCANS_BASE_URL}/${id}`,
        method: 'PATCH',
        data: {
            isMisstake: true,
            ...(Boolean(body?.deletedReason) && {deletedReason: body?.deletedReason}),
        }
    }), true);

export const useDeleteScans = () =>
    useRequest<TDeleteItem, {count: number}>((body) => ({
        url: SCANS_BASE_URL,
        method: 'DELETE',
        params: {
            where: {
                // @ts-ignore
                id: {inq: body.ids},
            },
        },
        ...(Boolean(body.deletedReason) && {
            data: {deletedReason: body.deletedReason},
        }),
    }), true);

export const useMakeUnError = () =>
    useRequest<number, string>((id) => ({
        url: `${SCANS_BASE_URL}/${id}`,
        method: 'PATCH',
        //@ts-ignore
        data: {
            isMisstake: false,
        }
    }), true);

export const useDeleteError = (id: number) =>
    useRequest<TDeleteItem, string>((body) => ({
        url: `${SCANS_BASE_URL}/${id}`,
        method: 'DELETE',
        ...(Boolean(body.deletedReason) && {
            data: body
        }),
    }), true);

export const useApproveMatch = (scanId: number) =>
    useRequest<void, string>(() => ({
        url: `${SCANS_BASE_URL}/${scanId}`,
        method: 'PATCH',
        // @ts-ignore
        data: {
            status: 'resolved',
        },
    }), true);

export const useRemoveScanFromInProgress = () =>
    useRequest<number, string>((scanId) => ({
        url: `${SCANS_BASE_URL}/${scanId}`,
        method: 'PATCH',
        // @ts-ignore
        data: {
            inProgress: false,
        },
    }), true);

export const useMoveScanFromInProgressToError = () =>
    useRequest<number, string>((scanId) => ({
        url: `${SCANS_BASE_URL}/${scanId}`,
        method: 'PATCH',
        // @ts-ignore
        data: {
            inProgress: false,
            isMisstake: true,
        },
    }), true);
