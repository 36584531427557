import {Fragment, memo} from 'react';

import {Button} from 'components/common/newButton';
import {ROUTES} from 'core/constants';
import {ReactComponent as Icon} from 'assets/images/403.svg';
import styles from './errors.module.css';

export const Forbidden = memo(() => {
    return (
        <div className={styles.wrapper}>
            <Icon />
            <div className={styles.title}>
                You don't have access :(
            </div>
            <div className={styles.text}>
                This page is only for internal use, but you
                <Fragment><br/></Fragment>
                can always go back to the mail page.
            </div>
            <div className={styles.button}>
                <Button text='Back to Mainpage' link={ROUTES.HOME} />
            </div>
        </div>
    );
});
