import {memo} from 'react';
import {useLocation, Navigate} from 'react-router-dom';

import {ROUTES, USER_ROLES} from 'core/constants';
import {useAppContext} from 'components/app/AppContext';
import {ROLE_ALLOW_ROUTES_CONFIG} from 'core/configs';

export const RequireAuth = memo<IProps>(({children}) => {
    const {user, isAuthPending} = useAppContext();
    const location = useLocation();

    if (!user && !isAuthPending) {
        return (
            <Navigate
                to={ROUTES.LOGIN}
                state={{from: location}}
                replace
            />
        );
    }

    if (!!user?.role) {
        const allowedRoutes = ROLE_ALLOW_ROUTES_CONFIG[user.role as USER_ROLES];

        // If no role or allowedRoutes in config, think all allowes by default
        //@ts-ignore
        if (!!allowedRoutes?.length && !allowedRoutes.includes(location.pathname)) {
            return (
                <Navigate
                    to={allowedRoutes[0]}
                    state={{from: location}}
                    replace
                />
            );
        }
    }

    return children;
});

interface IProps {
    children: JSX.Element;
}
