import {ProductFieldType} from "./types";

export const PRODUCT_LIST_HEADERS = [
    'Prod. ID',
    'Prod. Img',
    'Brand',
    'Name',
    'Category',
    'Skin types',
    'Age group',
    'Availability',
    'Date of creation',
    'Actions',
];

export const SCAN_LIST_HEADERS = [
    'Scan ID',
    'Scan Img.',
    'Prod. ID',
    'Prod. Img.',
    'Brand',
    'Name',
    'Category',
    'Skin types',
    'Ingredients',
    'Date of scan',
    'Actions',
];

export const ROUTES = {
    HOME: '/',
    PRODUCTS: '/products',
    MATCHES: '/matches',
    IN_PROGRESS: '/in_progress',
    ERRORS: '/errors',
    LOGIN: '/login',
    SCORE: '/score',
    CITY_MATCH: '/target_audience',
};

export const MAX_ITEM_COUNT_FOR_PAGE = 25;

export const TAGS_SELECTED_ALONE = [
    'All skin types',
    'All-day',
    '0+',
    'Not available',
    'Out of Stock',
    'In production',
    'Not indicated',
];

export const PRODUCT_FIELD_PLACEHOLDER = {
    [ProductFieldType.text]: 'Type here',
    [ProductFieldType.formattedText]: 'Type here',
    [ProductFieldType.tags]: 'Choose from the list',
    [ProductFieldType.oneFromTags]: 'Choose from the list',
};

export const MAX_LENGTH = 250;

export enum USER_ROLES {
    root = 'root', // <-- aka admin, can everything
    admin = 'admin', // <-- admin, can everything
    brand = 'brand',
}

export enum PERMISSIONS {
    can_see_pages_headlines = 'can_see_pages_headlines',
    can_use_menu = 'can_use_menu',
    can_use_filters = 'can_use_filters',
    can_upload_image  = 'can_upload_image',
}

export const ROLES_WITH_PERMISSIONS = {
    [USER_ROLES.brand]: [
        PERMISSIONS.can_see_pages_headlines,
        PERMISSIONS.can_upload_image,
    ],
    [USER_ROLES.root]: [
        PERMISSIONS.can_use_menu,
        PERMISSIONS.can_use_filters,
        PERMISSIONS.can_upload_image,
    ],
    [USER_ROLES.admin]: [
        PERMISSIONS.can_use_menu,
        PERMISSIONS.can_use_filters,
        PERMISSIONS.can_upload_image,
    ],
}
