import {Fragment, memo} from 'react';

import {ReactComponent as Icon} from 'assets/images/500.svg';
import styles from './errors.module.css';

export const InternalError = memo(() => {
    return (
        <div className={styles.wrapper}>
            <Icon />
            <div className={styles.title}>
                Technical problems...
            </div>
            <div className={styles.text}>
                We didn’t quite understand what
                <Fragment><br/></Fragment>
                happened, but we will try to fix it.
            </div>
        </div>
    );
});
