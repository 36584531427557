import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import cn from 'classnames';

import {TagTypes, TProduct, TProductProperties} from 'core/types';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {checkRequiredProductField} from 'core/utils';
import {ROUTES, USER_ROLES} from 'core/constants';
import {Picture, MainPicture} from 'components/common/picture';
import {ProductField} from 'components/common/productField';
import {Button, ButtonThemes} from 'components/common/newButton';
import {Tag} from 'components/common/tag';
import {
    useDeleteProductImage,
    usePatchProductImage,
    useProductFeedbackCount,
    useProductScansCount
} from 'queries/products';
import {useScansCount} from 'queries/scans';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as WarningIcon} from 'assets/images/warning.svg';
import styles from './EditProductModal.module.css';

export const Content = memo<IProps>(({
    product,
    onConfirm,
    onEdit,
    onCancel,
    properties,
    onLocalPictureChange,
}) => {
    const [editedProduct, setEditedProduct] = useState(product);
    const [activeInput, setActiveInput] = useState<string | null>();
    const [showError, setShowError] = useState<boolean>(false);
    const [zoomedPicture, setZoomedPicture] = useState<{url: string, id: number} | null>(null);

    const [patchedImage, patchImage] = usePatchProductImage();
    const [deletedImage, deleteImage] = useDeleteProductImage();
    const [{data}, fetchScansCount] = useScansCount();
    const {count} = data || {};
    const [{data: scansCount}, fetchProductScansCount] = useProductScansCount();
    const [{data: feedbackCount}, fetchProductFeedbackCount] = useProductFeedbackCount();

    const [changedTempImage, setChangedTempImage] = useState<string>();

    const {
        setRefresh,
        setIsProductModalOpen,
        setIsAllScansModalOpen,
        setIsDeleteScanModalOpen,
        setDeletingImage,
        user,
    } = useAppContext();
    const {pathname} = useLocation();
    const {
        id,
        name,
        scans,
        productImages,
    } = product || {};

    const isBrand = useMemo(() => !!user?.role && [USER_ROLES.brand].includes(user.role), [user?.role]);

    const notEditable = useMemo(() =>
        // @ts-ignore 
        user?.role === 'moderator' && Boolean(product),
        [user?.role, product]
    );

    const readMode = useMemo(() => pathname === ROUTES.CITY_MATCH, [pathname]);

    const coverImageInfo = useMemo(() => {
        if (productImages?.[0]) {
            return {
                url: productImages[0].url,
                id: productImages[0].id,
            };
        }

        return null;
    }, [productImages, scans, id, name]);

    const needSave = useMemo(() => {
        if (!product) {
            return Boolean(editedProduct)
                && Object.values(editedProduct as object)?.some((elem) => Boolean(elem?.length));
        }

        return Object
            .entries(editedProduct as TProduct)
            ?.some(([key, value]) => {
                if (key === 'gender') {
                    const modifiedNewValue = Array.isArray(value) ? value[0] : String(value);
                    return String(product[key]) !== modifiedNewValue;
                }

                // Кейс для spf, там одно число-строка в массиве
                if (key === 'spf') {
                    const modifiedNewValue = Array.isArray(value) ? value[0] : String(value);
                    return String(product[key]) !== modifiedNewValue;
                }
                // Кейс для ингредиентов, потому что они получаются из форматированной строки
                // @ts-ignore
                if (typeof value === 'string' && Array.isArray(product[key])) {
                    // @ts-ignore
                    return value !== product[key].join(',');
                }

                if (key === 'createdDate' || key === 'updatedDate') {
                    return false;
                }

                if (typeof value === 'string' || typeof value === 'number' || value === null) {
                    // @ts-ignore
                    return product[key] !== value;
                }

                if (!Array.isArray(value)) {
                    // don't compare objects
                    return false;
                }

                if (!value.length || typeof value?.[0] !== 'string') {
                    // don't compare pictures or empty arrays
                    return false;
                }

                // @ts-ignore
                if (!product[key]) {
                    return true;
                }

                // кейс для городов, у них другая ДТО
                if (key === 'citiesInfos') {
                    // @ts-ignore
                    const productCities = product[key]?.map(({city}) => city);
                    return (
                        value.some((elem) => !productCities?.includes(elem))
                        // @ts-ignore
                        || productCities?.some((elem) => !value.includes(elem))
                    );
                }

                return Boolean(
                    // @ts-ignore
                    value.some((elem) => !product[key]?.includes(elem))
                    // @ts-ignore
                    || product[key]?.some((elem) => !value.includes(elem))
                );
            });
    }, [editedProduct, product]);

    const handleInputChange = useCallback((value: string | Array<string>, field: string) => {
        setEditedProduct({...editedProduct, [field]: value});
    }, [editedProduct, setEditedProduct]);

    const handleAllScansOpen = useCallback(() => {
        setIsAllScansModalOpen(true);
        setIsProductModalOpen(false);
    }, []);

    useEffect(() => {
        if (typeof patchedImage?.data !== 'string' || Boolean(patchedImage?.error)) return;

        setRefresh(true);
    }, [patchedImage?.data, patchedImage?.error]);

    useEffect(() => {
        if (!deletedImage?.data || Boolean(deletedImage?.error)) return;

        setRefresh(true);
    }, [deletedImage?.data, deletedImage?.error]);

    useEffect(() => {
        if (!!id && !!user?.role && !isBrand) {
            fetchScansCount(id);
        }

        if (!!id && isBrand) {
            fetchProductScansCount(id);
            fetchProductFeedbackCount(id);
        }
    }, [id]);

    useEffect(() => {
        if (Boolean(editedProduct)) {
            onEdit?.(editedProduct!);
        }
    }, [editedProduct, onEdit]);

    const FieldsNodes = useMemo(() => PRODUCT_FIELDS_CONFIG.map((field) => {
        return (
            <ProductField
                key={field.key}
                field={field}
                product={product}
                properties={readMode ? null : properties}
                isActive={activeInput === field.label}
                isNotEditable={notEditable || (isBrand && field.notEditableForBrand)}
                showInvalid={showError}
                editedProduct={editedProduct}
                onClick={(value) => {
                    setShowError(false);
                    setActiveInput(value);
                }}
                onChange={(value) => {
                    handleInputChange(value, field.key);
                }}
                readMode={readMode}
                strictValue={field.key === 'brand' && isBrand ? user?.brand : undefined}
            />
        );
    }), [
        activeInput,
        notEditable,
        product,
        properties,
        editedProduct,
        showError,
        setShowError,
        setActiveInput,
        handleInputChange,
        readMode,
    ]);

    const AvailabilityTag = useMemo(() => {
        const {productAvailability} = editedProduct || {};
        if (!productAvailability?.length) {
            return null;
        }

        const [value] = productAvailability;

        if (value === 'Not available') {
            return (
                <Tag
                    text='Not available'
                    type={TagTypes.NEGATIVE}
                    isIconSupported
                />
            );
        }

        if (value === 'Out of Stock') {
            return (
                <Tag
                    text='Out of Stock'
                    type={TagTypes.DEFAULT}
                    isIconSupported
                />
            );
        }

        return (
            <Tag
                text='Available'
                type={TagTypes.POSITIVE}
                isIconSupported
            />
        );
    }, [editedProduct?.productAvailability]);

    const handleClick = useCallback(() => {
        if (!editedProduct || !Object.values(editedProduct).length) return;

        const areAllFields = PRODUCT_FIELDS_CONFIG.every(({key}) => checkRequiredProductField(editedProduct, key));

        if (!areAllFields) {
            setShowError(true);
            return;
        }

        onConfirm?.();
    }, [editedProduct, setShowError, onConfirm]);

    const handleChangePictureSrc = useCallback((file: File) => {
        const src = URL.createObjectURL(file);
        setChangedTempImage(src);

        onLocalPictureChange?.(file);
    }, []);

    useEffect(() => {
        if (!editedProduct && isBrand && !!user?.brand) {
            handleInputChange(user.brand, 'brand');
        }
    }, []);

    return (
        <>
            <div className={styles.photos}>
                <MainPicture
                    url={coverImageInfo?.url}
                    productId={id}
                    availability={AvailabilityTag}
                    onLocalImageChange={handleChangePictureSrc}
                />
                {!!zoomedPicture?.id && (
                    <div className={styles.underPictureSign}>
                        <b>Scan ID </b>
                        <span>{zoomedPicture.id}</span>
                    </div>
                )}
                {isBrand && !!id && (
                    <>
                        <div className={styles.underPictureSign}>
                            <b>Total user scans </b>
                            {/* @ts-ignore */}
                            <span>{scansCount?.count || 0}</span>
                        </div>
                        <div className={styles.underPictureSign}>
                            <b>Total user feedbacks </b>
                            <span>{feedbackCount?.count || 0}</span>
                        </div>
                    </>
                )}
                {Boolean(scans?.length) && !isBrand && (
                    <>
                        <div className={styles.scansTitle}>
                            <div>
                                <span>Recent Scans</span>
                                {Boolean(count) && (
                                    <span className={styles.scansCount}>
                                        :&nbsp;{Number(count) > 12 ? count : scans?.length}
                                    </span>
                                )}
                            </div>
                            {Number(count) > 12 && (
                                <div
                                    className={styles.scansSeeAllBtn}
                                    onClick={handleAllScansOpen}
                                >
                                    See all
                                </div>
                            )}
                        </div>
                        {Boolean(scans?.length) && !isBrand && (
                            <div className={styles.scansWrapper}>
                                {scans?.map((scan, index) => (
                                    <div
                                        className={styles.scanInfo}
                                        key={`scan_image_${scan?.scanImages?.[0]?.url}_${index}`}
                                    >
                                        <Picture
                                            id={scan?.scanImages?.[0]?.id || null}
                                            url={scan?.scanImages?.[0]?.url || null}
                                            onChange={() => {
                                                if (!product?.id || !scan?.scanImages?.[0]?.url) return;
                                                patchImage({id: product.id, url: scan?.scanImages![0].url});
                                            }}
                                            onDelete={() => {
                                                if (!product?.id) return;
                                                setDeletingImage({data: scan, isProductImage: false});
                                                setIsDeleteScanModalOpen(true);
                                            }}
                                            onClick={(data) => setZoomedPicture(data)}
                                            isActive={zoomedPicture?.url === scan?.scanImages?.[0]?.url}
                                            hidePictureMenu={readMode}
                                            setTempImage={() => {}}
                                        />
                                        <div>Scan ID: {scan?.scanImages?.[0]?.id}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className={styles.info}>
                {FieldsNodes}
                {showError && (
                    <div className={cn(styles.error)}>
                        <div>
                            <WarningIcon />
                        </div>
                        <div className={styles.errorText}>
                            Not enough information! Please complete all fields before saving the product.
                        </div>
                    </div>
                )}
                <div className={cn(styles.buttons, {[styles.buttonsVisible]: (needSave || !!changedTempImage)})}>
                    {(needSave || !!changedTempImage) && (
                        <>
                            <Button
                                text={Boolean(product) ? 'Save changes' : '+ Add product'}
                                onClick={handleClick}
                            />
                            <Button
                                text='Cancel'
                                theme={ButtonThemes.secondary}
                                onClick={onCancel}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
});

interface IProps {
    product?: TProduct | null;
    onConfirm?: () => void;
    onCancel?: () => void;
    onEdit?: (product: TProduct) => void;
    properties: TProductProperties | null;
    onLocalPictureChange: (file: File) => void;
}
