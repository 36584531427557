import {ChangeEvent, memo, useCallback, useRef, useState} from 'react';

import {ReactComponent as ClearIcon} from 'assets/images/clear.svg';
import styles from './Search.module.css';

export const InputWithLabel = memo<IProps>(({
    label,
    onInput,
    onClear,
}) => {
    const [value, setValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setValue(value);
        onInput(value);
    }, []);

    const handleClear = useCallback(() => {
        setValue('');
        onClear();
    }, [setValue]);

    const handleInputClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current!.focus();
        }
    }, []);

    return (
        <div className={styles.wrapperWithoutBtn} onClick={handleInputClick}>
            <div className={styles.borderWithoutBtn} />
            <span className={styles.label}>
                {label}
            </span>
            <input
                ref={inputRef}
                value={value}
                onChange={handleChange}
            />
            {Boolean(value.length) && (
                <div
                    className={styles.clearBtn}
                    onClick={handleClear}
                >
                    <ClearIcon />
                </div>
            )}
        </div>
    );
});

interface IProps {
    label: string;
    onInput: (value: string) => void;
    onClear: () => void;
}
