import {memo, useEffect, useMemo} from 'react';

import {useSimilarProducts} from 'queries/score';
// import {Button} from 'components/common/newButton';
// import {useAppContext} from 'components/app/AppContext';
// import SimilarProductsImage from 'assets/images/similar.png';
import {Gallery} from './gallery';
import styles from './SimilarProducts.module.css';

export const SimilarProducts = memo<IProps>(function SimilarProducts({productId}) {
    const [{data, isPending, error}, fetch] = useSimilarProducts();
    const products = useMemo(() => {
        if (!data) return [];

        //@ts-ignore
        return data[productId]?.similar_products || [];
    }, [data]);

    // const {setIsComingSoonModalOpen} = useAppContext();

    useEffect(() => {
        if (!productId) return;

        fetch(productId);
    }, [productId]);

    if (!productId || !!error) {
        return null;
    }

    if (!!data && products.length === 0) {
        return null;
    }

    return (
        <div className={styles.wrapper} id='similar-products-block'>
            <div className={styles.texts}>
                <div className={styles.title}>Products with similar ingredients:</div>
            </div>
            {isPending && (
                <div className={styles.info}>
                    <div className='pageloaderWrapper'>
                        <div className='pageloader'/>
                    </div>
                </div>
            )}
            {!!data && (
                <div className={styles.info}>
                    <Gallery items={products} />
                </div>
            )}
            {/*<div className={styles.blurBlock}>*/}
            {/*    <div className={styles.actionBlock}>*/}
            {/*        <div className={styles.actionBlockButton}>*/}
            {/*            <Button*/}
            {/*                text='Subscribe to unlock'*/}
            {/*                onClick={() => setIsComingSoonModalOpen(true)}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div className={styles.actionBlockText}>*/}
            {/*            This feature is only available*/}
            {/*            for&nbsp;premium subscription users.*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <img src={SimilarProductsImage} alt='' className={styles.pictureBlured} />*/}
            {/*</div>*/}
        </div>
    );
});

interface IProps {
    productId: number | undefined;
}
