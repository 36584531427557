import {memo, useEffect, useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useErrorHandler} from 'react-error-boundary';

import {useProducts, useSearchItems, useProductsCount} from 'queries/products';
import {ROUTES, MAX_ITEM_COUNT_FOR_PAGE, USER_ROLES} from 'core/constants';
import {TProduct} from 'core/types';
import {ProductList} from 'components/blocks/productList';
import {Pagination} from 'components/blocks/pagination';
import {Forbidden, InternalError} from 'components/blocks/troubles';
import {CategoryFilter} from 'components/blocks/categoryFilter';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as NoResults} from 'assets/images/not-found.svg';
import styles from './Products.module.css';

export const Products = memo(() => {
    const [{data, isPending, error}, fetch] = useProducts();
    const [{data: countData}, fetchCount] = useProductsCount();
    const [searchItems, fetchSearch] = useSearchItems();
    const fetchProductsCount = useCallback(fetchCount, []);
    const [cached, setCached] = useState<Array<TProduct> | null>([]);
    const [cachedCount, setCachedCount] = useState<number | null>();

    const {
        refresh,
        setRefresh,
        sort,
        setIsSelectModalOpen,
        deletingIds,
        setDeletingIds,
        user,
    } = useAppContext();
    const {search} = useLocation();
    const navigate = useNavigate();
    const [_, setSearchParams] = useSearchParams();
    const handleError = useErrorHandler();

    const handleClearSearch = useCallback(() => {
        navigate({pathname: ROUTES.PRODUCTS});
    }, [navigate]);

    const handleDeletePack = useCallback(() => {
        setIsSelectModalOpen(true);
    }, [setIsSelectModalOpen]);

    const handleClearPackSelection = useCallback(() => {
        setDeletingIds([]);
    }, [setDeletingIds]);

    const canUseCategoryFilter = useMemo(() => {
        if (!user?.role) {
            return false;
        }

        return ![USER_ROLES.brand].includes(user.role);
    }, [user]);

    const searchText = useMemo(() => {
        if (!search.length) return null;

        return new URLSearchParams(search).get('text');
    }, [search]);

    const currentPage = useMemo(() => {
        return new URLSearchParams(search).get('p');
    }, [search]);

    const offset = useMemo(() => {
        if (!search.length) return 0;

        const page = new URLSearchParams(search).get('p');
        if (!page || Number(page) === 1) return 0;

        return (Number(page) - 1) * MAX_ITEM_COUNT_FOR_PAGE;
    }, [search]);

    const activeFilter = useMemo(() => {
        if (!search.length) return null;

        return new URLSearchParams(search).get('filter');
    }, [search]);

    const searchInfo = useMemo(() => {
        if (!search.length || !(new URLSearchParams(search).get('text')) || !searchItems.data) {
            return null;
        }

        return (
            <div className={styles.search}>
                <div className={styles.searchText}>
                    Search results:&nbsp;
                </div>
                <div className={styles.searchCount}>
                    {searchItems?.data?.length}
                </div>
                {Boolean(searchItems?.data?.length) && (
                    <div
                        className={styles.searchButton}
                        onClick={handleClearSearch}
                    >
                        Clear
                    </div>
                )}
            </div>
        );
    }, [search, handleClearSearch, searchItems.data]);

    const selectedInfo = useMemo(() => {
        if (!deletingIds.length) {
            return null;
        }

        return (
            <div className='selectedInfo'>
                <div className='selectedInfoCount'>
                    {deletingIds.length}
                </div>
                <div className='selectedInfoText'>
                    item(s) to delete
                </div>
                <div className='selectedInfoButtons'>
                    <div
                        className='selectedInfoButton'
                        onClick={handleDeletePack}
                    >
                        Delete
                    </div>
                    <div
                        className='selectedInfoButton'
                        onClick={handleClearPackSelection}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }, [deletingIds]);

    useEffect(() => {
        if (searchText?.length) {
            fetchSearch({text: searchText, limit: MAX_ITEM_COUNT_FOR_PAGE});
        } else if (!!activeFilter) {
            fetch({offset, group: activeFilter});
            fetchProductsCount(activeFilter);
        }
    }, [searchText, offset, activeFilter]);

    useEffect(() => {
        if (refresh) {
            setCached(data);
            setCachedCount(countData?.count);

            if (searchText?.length) {
                fetchSearch({text: searchText, limit: MAX_ITEM_COUNT_FOR_PAGE});
            } else if (!!activeFilter) {
                fetch({offset, order: sort?.order, group: activeFilter});
                fetchProductsCount(activeFilter);
            }
            setRefresh(false);
        }
    }, [fetch, refresh, offset, sort, setCached, setCachedCount, data, activeFilter]);

    useEffect(() => {
        if (isPending && Boolean(cached?.length)) {
            document.body.classList.add('overflowed');
        } else {
            document.body.classList.remove('overflowed');
        }
    }, [isPending, cached?.length]);

    useEffect(() => {
        if (!activeFilter && !searchText) {
            setSearchParams(
                {filter: 'All'},
                {replace: true},
            );
        }
    }, [activeFilter, searchText]);

    useEffect(() => {
        if (currentPage) {
            setCached([]);
        }
    }, [currentPage]);

    if (Boolean(error)) {
        // @ts-ignore
        if (typeof error !== 'string' && error?.status === 403) return <Forbidden />;
        // @ts-ignore
        if (typeof error !== 'string' && error?.status === 500) return <InternalError />;

        handleError(error);
        return null;
    }

    // Свежая загрузка (без кеша)
    if ((isPending || searchItems.isPending) && !cached?.length) {
        return (
            <>
                {canUseCategoryFilter && <CategoryFilter />}
                <div className='pageloaderWrapper'>
                    <div className='pageloader'/>
                </div>
            </>
        );
    }

    if (searchText?.length && (!searchItems.data?.length || Boolean(searchItems.error)) && !searchItems.isPending) {
        return (
            <>
                {searchInfo}
                <div className={styles.notFoundWrapper}>
                    <div className={styles.icon}>
                        <NoResults />
                    </div>
                    <div className={styles.notFoundTitle}>
                        No results :(
                    </div>
                    <div className={styles.notFoundText}>
                        Maybe this product doesn’t exist in our database.
                        Check the name or try another one.
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {canUseCategoryFilter && <CategoryFilter />}
            {searchInfo}
            {selectedInfo}
            {/* Подгрузка (обновление кеша) */}
            {isPending && Boolean(cached?.length) && (
                <>
                    <div className='contentLoaderUnder'>
                        <div className='contentLoaderWrapper'>
                            <div className='contentLoader'/>
                        </div>
                    </div>
                </>
            )}
            <div>
                <ProductList
                    items={(searchText?.length ? searchItems.data : data || cached) || []}
                />
                {/*TODO: добавить нормальную пагинацию на поиск*/}
                {!searchText?.length && (
                    <Pagination count={countData?.count || cachedCount || null} />
                )}
            </div>
        </>
    );
});
