import {Dispatch, FormEvent, memo, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
// import {jsPDF} from 'jspdf';
// import html2canvas from 'html2canvas';
import {InputWithLabel} from 'components/pages/cityMatch';
import {Button, ButtonTypes} from 'components/common/newButton';
import {CityMatchRow} from 'components/blocks/cityMatchRow/CityMatchRow';
import {InformationNote} from 'components/blocks/informationNote/InformationNote';
import {TopCities} from 'components/blocks/topCities';
import {SimilarProducts} from 'components/blocks/similarProducts';
import {Nps} from 'components/blocks/nps';
import {Search} from 'components/blocks/citySearch';
import {Highlights} from 'components/blocks/highlights';
import {GenderDropdown} from 'components/blocks/genderDropdown';
import {HorizontalBarChart} from 'components/blocks/horizontalBarChart';
import {GraphWidget} from 'components/blocks/graphWidget';
import {GraphType} from 'components/blocks/graphWidget/data';
import {LineChart, LineChart2} from 'components/blocks/lineChart';
import {useAppContext} from 'components/app/AppContext';
import {
    useCities,
    useCityDemography,
    useCityMatchInfo,
    useProductPossibleCustomers,
    useRequestsBalance,
    useTrendsLocation,
    useTrendsProductGraph,
} from 'queries/score';
import {useProduct, useProductFeedbackCount} from 'queries/products';
import {CITY_MATCH_SCORE_CONFIG} from 'core/configs';
import {ScoreFieldObject, TCityMatchResult} from 'core/types';
import {
    calculateMockTrend,
    calculateMockTrendWithArrays,
    getFormattedDataForGraphByType,
    getGraphDataByProduct
} from 'core/utils';
import {ReactComponent as StartIcon} from 'assets/images/start-target-audience.svg';
import {ReactComponent as NoResults} from 'assets/images/not-found.svg';
// import Logo from 'assets/images/BH-logo-horiz.png';
import styles from './CityMatch.module.css';

const BALANCE_THRESHOLD = 0;

export const CityMatch = memo(() => {
    const [productId, setProductId] = useState<number>();
    const [city, setCity] = useState<string>();
    const [showEmptyResult, setShowEmptyResult] = useState<boolean>(false);
    const [areRequestsPending, setAreRequestsPending] = useState<boolean>(false);
    const [currentGender, setCurrentGender] = useState<string>('Female');
    const [{data, error, isPending}, fetch] = useCityMatchInfo();
    const [{data: product, isPending: isPendingProduct}, fetchProduct] = useProduct();
    const [{data: balance, isPending: isPendingBalance}, fetchBalance] = useRequestsBalance();
    const [{data: cityInfo, isPending: isPendingCityDemography}, fetchDemography] = useCityDemography();
    const [{data: feedbacks, isPending: isPendingFeedbacks}, fetchFeedbacks] = useProductFeedbackCount();
    const [{data: trends, isPending: isPendingTrends}, fetchTrends] = useTrendsLocation();
    const [{data: graphData, isPending: isPendingGraphData}, fetchGraphData] = useTrendsProductGraph();
    const [{data: cities}, fetchCities] = useCities();
    const [{data: possibleCustomers}, fetchProductPossibleCustomers] = useProductPossibleCustomers();
    const [currentScoreInfo, setCurrentScoreInfo] = useState<TCityMatchResult[] | null>();
    const [hideCitySuggests, setHideCitySuggests] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const ref = useRef<HTMLDivElement>(null);

    const info = useMemo(() => currentScoreInfo?.[0], [currentScoreInfo]);
    const {left, right} = CITY_MATCH_SCORE_CONFIG;

    const isPendingStatus = (isPending || isPendingBalance || isPendingProduct || areRequestsPending || isPendingCityDemography || isPendingTrends || isPendingFeedbacks || isPendingGraphData);
    const showResult = !!info && !error && !isPendingStatus && !showEmptyResult || false;

    const [leftRow0Height, setLeftRow0Height] = useState<number>(0);
    const [rightRow0Height, setRightRow0Height] = useState<number>(0);
    const [leftRow1Height, setLeftRow1Height] = useState<number>(0);
    const [rightRow1Height, setRightRow1Height] = useState<number>(0);
    const [leftRow2Height, setLeftRow2Height] = useState<number>(0);
    const [rightRow2Height, setRightRow2Height] = useState<number>(0);
    const [leftRow3Height, setLeftRow3Height] = useState<number>(0);
    const [rightRow3Height, setRightRow3Height] = useState<number>(0);

    const heightSetterConfig: {
        [key: number]: {
            leftSetter: Dispatch<SetStateAction<number>>,
            rightSetter: Dispatch<SetStateAction<number>>,
            left: number,
            right: number,
        }
    } = {
        0: {leftSetter: setLeftRow0Height, rightSetter: setRightRow0Height, left: leftRow0Height, right: rightRow0Height},
        1: {leftSetter: setLeftRow1Height, rightSetter: setRightRow1Height, left: leftRow1Height, right: rightRow1Height},
        2: {leftSetter: setLeftRow2Height, rightSetter: setRightRow2Height, left: leftRow2Height, right: rightRow2Height},
        3: {leftSetter: setLeftRow3Height, rightSetter: setRightRow3Height, left: leftRow3Height, right: rightRow3Height},
    };

    const {
        setProduct,
        setIsProductModalOpen,
        setIsSubscriptionModalOpen,
        setUser,
        user,
        setIsFirstRequestModalOpen,
        setBalance,
    } = useAppContext();

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setHideCitySuggests(true);
        if (!!productId && !!city) {
            setShowEmptyResult(false);
            setAreRequestsPending(true);
            setCurrentScoreInfo(null);

            // Эта проверка нужна, т.к. мы могли изменить баланс в другой вкладке и т.п.
            fetchBalance();
        } else {
            setShowEmptyResult(true);
        }
    }, [productId, city]);

    // Пока работает только для модалки продукта
    const handleModalOpenClick = useCallback(() => {
        if (!product) return null;

        setProduct(product);
        setIsProductModalOpen(true);
    }, [product]);

    const graph1Data = useMemo(() => {
        if (!productId || !graphData) return null;

        return getFormattedDataForGraphByType(graphData, GraphType.INTERESTS)
    }, [productId, graphData]);

    const graph2Data = useMemo(() => {
        if (!productId || !graphData) return null;

        // return getGraphDataByProduct(productId, GraphType.ADS);
        return getFormattedDataForGraphByType(graphData, GraphType.ADS)
    }, [productId, graphData]);

    const graph3Data = useMemo(() => {
        if (!productId) return null;

        return getGraphDataByProduct(productId, GraphType.MENTIONS);
    }, [productId]);

    useEffect(() => {
        if (balance !== null && !!user) {
            setUser({...user, balance: balance === null ? null : balance})
        }

        if (balance !== null && balance > BALANCE_THRESHOLD && !!productId && !!city) {
            const cityId = cities?.find((info) => info?.city === city)?.id;

            setSearchParams(
                {product: String(productId)},
                {replace: true},
            );
            fetch([{product: productId, city}]);
            fetchProduct(productId);
            fetchFeedbacks(productId);
            fetchGraphData(productId);
            setCurrentGender('Female');

            if (cityId) {
                fetchDemography(cityId);
                fetchTrends({id: productId, cityId: cityId});
                fetchProductPossibleCustomers({productId, cityId})
            }
        } else if (balance === BALANCE_THRESHOLD) {
            setAreRequestsPending(false);
            setIsSubscriptionModalOpen(true);
        }
    }, [balance]);

    useEffect(() => {
        if (!!currentScoreInfo && balance !== null) {
            setAreRequestsPending(false);
        }
    }, [currentScoreInfo, product, balance]);

    useEffect(() => {
        if (!!data) {
            setCurrentScoreInfo(data);
            if (balance !== null) {
                // После того, как мы получили данные, баланс уменьшается на 1
                setBalance(balance - 1);
            }

            if (!localStorage.getItem('was-first-request-modal-opened')) {
                setIsFirstRequestModalOpen(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!!error) {
            setAreRequestsPending(false);
        }
    }, [error]);

    useEffect(() => {
        fetchCities();
    }, []);

    // useEffect(() => {
    //     fetchBalance();
    // }, []);

    // useEffect(() => {
    //     if (balance !== null) {
    //         setBalance(balance);
    //     }
    // }, [balance]);

    const handleChangeGender = useCallback((gender: string) => {
        setCurrentGender(gender);
    }, [productId, city]);

    useEffect(() => {
        const productId = searchParams.get('product');

        if (!productId) return;

        setProductId(Number(productId));
    }, [searchParams]);

    return (
        <div className={styles.wrapper}>
            <div ref={ref}>
                <div className={styles.topline}>
                    <form className={styles.searchBlock} onSubmit={handleSubmit}>
                        <InputWithLabel
                            label='Product ID'
                            value={productId ? String(productId) : ''}
                            onInput={(value) => setProductId(Number(value))}
                            onClear={() => setProductId(0)}
                        />
                        <Search
                            onChange={(value) => {
                                setHideCitySuggests(false);
                                setCity(value);
                            }}
                            hideSuggests={hideCitySuggests}
                            possibleValues={cities || []}
                        />
                        <Button
                            text='Show city match'
                            type={ButtonTypes.micro}
                            isSubmit
                        />
                    </form>
                </div>
                {isPendingStatus && (
                    <div className={styles.emptyWrapper}>
                        <div className='pageloaderWrapper'>
                            <div className='pageloader'/>
                        </div>
                    </div>
                )}
                {/* {!info && !isPending && !isPendingProduct && !isPendingBalance && !error && !showEmptyResult && ( */}
                {!info && !isPendingStatus && !error && !showEmptyResult && (
                    <div className={styles.emptyWrapper}>
                        <div className={styles.icon}>
                            <StartIcon />
                        </div>
                        <div className={styles.title}>Check your target audience now!</div>
                        <div className={styles.description}>
                            To see results from the score system, please insert the&nbsp;product IDs and city.
                        </div>
                    </div>
                )}
                {(!!error || showEmptyResult) && (
                    <div className={styles.emptyWrapper}>
                        <div className={styles.icon}>
                            <NoResults />
                        </div>
                        <div className={styles.title}>No results :(</div>
                        <div className={styles.description}>
                            Maybe this city or product doesn't exist in our database. Please check again or try another one.
                        </div>
                    </div>
                )}
                {showResult && (
                    <>
                        {!!product && (
                            <Highlights
                                product={product}
                                score={info?.score}
                                demography={cityInfo?.demography || 0}
                                trends={trends || 0}
                                feedbacks={feedbacks?.count || 0}
                                interestsInfo={info?.['audience_features']}
                                possibleCustomers={possibleCustomers || undefined}
                            />
                        )}
                        <div className={styles.result}>
                            <div className={styles.left}>
                                {left.map(({title, fields, noteType}, fieldIdx) => (
                                    <div className={styles.block} key={`result_block_left_${fieldIdx}`}>
                                        <div className={styles.blockTitle}>{title}</div>
                                        <table>
                                            <tbody>
                                                {fields.map((field, idx) => {
                                                    const heightData = heightSetterConfig[idx];
                                                    const maxHeight = !!heightData ? Math.max(heightData.left, heightData.right) : 0;

                                                    return (
                                                        <>
                                                            <CityMatchRow
                                                                key={`result_row_left_${fieldIdx}_${idx}`}
                                                                field={field}
                                                                info={info!}
                                                                height={maxHeight}
                                                                onModalClick={handleModalOpenClick}
                                                                onDidMount={!!heightData
                                                                    ? (height) => heightData.leftSetter(height)
                                                                    : () => {}
                                                                }
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {!!noteType && (
                                            <InformationNote type={noteType} />
                                        )}
                                    </div>
                                ))}
                                <SimilarProducts productId={productId} />
                                {graph1Data && (
                                    <GraphWidget
                                        title='Demand Trend'
                                        trend={graph1Data.trend === true ? 'good' : 'bad'}
                                        description='We analyze both global and local beauty brands, utilizing diverse data sources to accurately predict demand trends and consumer preferences.'
                                        graph={<LineChart2 data={graph1Data.data} />}
                                    />
                                )}
                                {graph2Data && (
                                    <GraphWidget
                                        title='Competitor’s Ad Activity'
                                        trend={graph2Data.trend === true ? 'good' : 'bad'}
                                        description='We analyze global and local beauty brands using diverse data sources to predict demand.'
                                        //@ts-ignore
                                        graph={<HorizontalBarChart data={graph2Data.data} labels={graph2Data.labels} />}
                                    />
                                )}
                                {graph3Data && (
                                    <GraphWidget
                                        title='Competitor’s SMM activity'
                                        trend={calculateMockTrend(graph3Data[1])}
                                        description='We use local market data to predict potential competitor activity on social media platforms.'
                                        graph={<LineChart data={graph3Data} />}
                                    />
                                )}
                            </div>

                            <div className={styles.right}>
                                {right.map(({title, fields, noteType}, fieldIdx) => {
                                    const isCustomWidget = title === 'Typical customer profiles in the city:';
                                    const customIndex = isCustomWidget
                                        ? info?.[ScoreFieldObject.audience_features].findIndex((data) => data.gender?.value === currentGender) || 0
                                        : undefined;
                                    const femaleInfo = info?.[ScoreFieldObject.city].female_share;
                                    const showDropdown = isCustomWidget && typeof femaleInfo !== 'undefined' && (info?.[ScoreFieldObject.audience_features].length || 0) > 1;


                                    return (
                                        <div className={styles.block} key={`result_block_right_${fieldIdx}`}>
                                            <div className={styles.blockTitleWrapper}>
                                                <div className={styles.blockTitle}>{title}</div>
                                                {showDropdown && (
                                                    <GenderDropdown
                                                        femalePart={femaleInfo}
                                                        onChange={handleChangeGender}
                                                    />
                                                )}
                                            </div>
                                            <table>
                                                <tbody>
                                                {fields.map((field, idx) => {
                                                    const heightData = fieldIdx === 0 ? heightSetterConfig[idx] : null;
                                                    const maxHeight = !!heightData ? Math.max(heightData.left, heightData.right) : 0;

                                                    return (
                                                        <CityMatchRow
                                                            key={`result_row_right_${fieldIdx}_${idx}`}
                                                            field={field}
                                                            info={info!}
                                                            height={maxHeight}
                                                            customIndex={customIndex}
                                                            onModalClick={handleModalOpenClick}
                                                            onDidMount={!!heightData
                                                                ? (height) => heightData.rightSetter(height)
                                                                : () => {}
                                                            }
                                                        />
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                            {!!noteType && (
                                                <InformationNote type={noteType} />
                                            )}
                                        </div>
                                    );
                                })}
                                <TopCities productId={productId} />
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/* {showResult && (
                <div className={styles.footer}>
                    <Button
                        text='Download file'
                        onClick={() => {
                            if (!ref.current) return;

                            const targetToRemove = document.getElementById('top-cities-block');
                            if (!!targetToRemove) {
                                targetToRemove.style.visibility = 'hidden';
                            }
                            const targetToRemove2 = document.getElementById('similar-products-block');
                            if (!!targetToRemove2) {
                                targetToRemove2.style.visibility = 'hidden';
                            }

                            html2canvas(ref.current).then((canvas) => {
                                const doc = new jsPDF();
                                const offset = 5;
                                doc.addImage(Logo, 'png', offset, offset, 30, 5);
                                const width = doc.internal.pageSize.getWidth() - offset * 2;
                                const k = Number(ref.current?.offsetHeight) / Number(ref.current?.offsetWidth);
                                const height = width * k;
                                //@ts-ignore
                                doc.addImage(canvas, 'png', offset, offset + 10, width, height);
                                doc.save('target_audience_result.pdf');
                            });

                            if (!!targetToRemove) {
                                targetToRemove.style.visibility = 'visible';
                            }
                            if (!!targetToRemove2) {
                                targetToRemove2.style.visibility = 'visible';
                            }
                        }}
                    />
                </div>
            )} */}
            {showResult && (
                <>
                    <Nps />
                    <div className={styles.legal}>
                        <InformationNote type='important' />
                    </div>
                </>
            )}
        </div>
    );
});
