import {memo} from 'react';

import styles from './errors.module.css';

export const NoErrorsList = memo(() => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Congratulations!
            </div>
            <div className={styles.text}>
                No more errors :)
            </div>
        </div>
    );
});
