import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {createSearchParams, useNavigate} from 'react-router-dom';

import {Tag} from 'components/common/tag';
import {useAppContext} from 'components/app/AppContext';
import {Preview} from 'components/common/preview';
import {Checkbox} from 'components/common/checkbox';
import {TagTypes, TProduct, TScan} from 'core/types';
import {getRangeOfAges} from 'core/utils';
import {ROUTES} from 'core/constants';
import {ReactComponent as EditIcon} from 'assets/images/edit.svg';
import {ReactComponent as DeleteIcon} from 'assets/images/delete.svg';
import {ReactComponent as OpenIcon} from 'assets/images/open.svg';
import styles from './ProductRow.module.css';

const MAX_CITIES_COUNT = 3;

export const ProductRow = memo<IProps>(({
    product,
}) => {
    const {
        setProduct,
        setIsSelectModalOpen,
        setIsProductModalOpen,
        allProductChecked,
        deletingIds,
        setDeletingIds,
    } = useAppContext();

    const [checked, setChecked] = useState<boolean>(allProductChecked);
    const navigate = useNavigate();

    const {
        id,
        brand,
        name,
        productCategory = [],
        skinTypes,
        ageGroup,
        productImages,
        createdDate,
        citiesInfos,
        productAvailability,
    } = product;

    const SkinTypesNodes = useMemo(() => (
        skinTypes?.map((type, index) => (
            <Tag text={type} key={`${type}_${index}`} />
        )) || []
    ), [skinTypes]);

    const CategoryNodes = useMemo(() => (
        productCategory?.map((category, index) => (
            <Tag text={category} key={`${category}_${index}`} />
        )) || []
    ), [productCategory]);

    const ImageNode = useMemo(() => {
        if (!productImages?.[0]) {
            return <Preview url={null} />
        }

        const {url, id: imgID} = productImages[0];

        return <Preview url={url} alt={`${imgID}_${id}_${name}`} key={imgID} />
    }, [productImages?.[0], id, name]);

    const age = useMemo(() => {
        if (!ageGroup?.length) {
            return <Tag text='No' type={TagTypes.NEGATIVE} />;
        } else {
            const text = getRangeOfAges(ageGroup);
            return <Tag text={text} />;
        }
    }, [ageGroup]);

    const getBooleanTag = useCallback((field?: string | Array<string> | Array<TScan>) => {
        if (Boolean(field?.length)) {
            return <Tag text='Yes' type={TagTypes.POSITIVE} />
        }

        return <Tag text='No' type={TagTypes.NEGATIVE} />
    }, []);

    const AvailabilityNodes = useMemo(() => {
        if (!citiesInfos && !productAvailability) {
            return [<Tag text='No' type={TagTypes.NEGATIVE} key={`no_tag_availability`} />];
        }

        const availabilityValue = Array.isArray(productAvailability) ? productAvailability[0] : productAvailability;

        if (!!availabilityValue && availabilityValue !== 'In production') {
            let tagType = TagTypes.POSITIVE;
            if (availabilityValue === 'Not available') tagType = TagTypes.NEGATIVE;
            if (availabilityValue === 'Out of Stock') tagType = TagTypes.DEFAULT;

            return ([
                <Tag
                    text={availabilityValue}
                    key={`${availabilityValue}_${id}`}
                    type={tagType}
                    isIconSupported
                />
            ]);
        }

        if (!citiesInfos) {
            return [<Tag text='No' type={TagTypes.NEGATIVE} key={`no_tag_availability`} />];
        }

        let visibleInfo;
        if (citiesInfos.length > MAX_CITIES_COUNT) {
            const restCount = citiesInfos.length - MAX_CITIES_COUNT + 1;
            visibleInfo = [...citiesInfos.slice(0, MAX_CITIES_COUNT - 1), {city: `+ ${restCount} more cities`, id: -1505}];
        } else {
            visibleInfo = [...citiesInfos];
        }

        //@ts-ignore
        return visibleInfo.map(({city, id}, index) => {
            return (
                <Tag
                    text={city}
                    key={`${city}_${id}_${index}`}
                    type={TagTypes.POSITIVE}
                    isIconSupported
                />
            );
        });
    }, [citiesInfos, productAvailability]);

    const handleDelete = useCallback((e: MouseEvent) => {
        setProduct(product);
        setIsSelectModalOpen(true);
        setIsProductModalOpen(false);
        e.stopPropagation();
    }, [product]);

    const handleOpenScorePage = useCallback((e: MouseEvent) => {
        navigate({
            pathname: ROUTES.CITY_MATCH,
            search: createSearchParams({
                product: String(id),
            }).toString(),
        });

        e.stopPropagation();
    }, [navigate, id]);

    const handleCheck = useCallback(() => {
        const newValue = !checked;

        if (newValue) {
            const newArrayForDeleting = [...deletingIds, Number(id)];
            setDeletingIds(newArrayForDeleting);
        } else {
            const newArrayForDeleting = deletingIds.filter((item) => item !== id);
            setDeletingIds(newArrayForDeleting);
        }

        setChecked(newValue);
    }, [setChecked, checked, setDeletingIds, deletingIds, id]);

    useEffect(() => {
        setChecked(allProductChecked);
    }, [allProductChecked]);

    useEffect(() => {
        if (!deletingIds.length) {
            setChecked(false);
        }
    }, [deletingIds]);

    return (
        <tr>
            <td>
                <Checkbox
                    checked={checked}
                    onClick={handleCheck}
                />
            </td>
            <td>{id}</td>
            <td
                onClick={() => {
                    setProduct(product);
                    setIsProductModalOpen(true);
                }}
                className={styles.clickable}
            >
                {ImageNode}
            </td>
            <td className={styles.short}>
                {brand}
            </td>
            <td className={styles.short}>
                {name}
            </td>
            <td className={styles.tags}>
                <div className={styles.tagsWrapper}>
                    {Boolean(CategoryNodes.length)
                        ? CategoryNodes
                        : <Tag text='No' type={TagTypes.NEGATIVE} />
                    }
                </div>
            </td>
            <td className={styles.tags}>
                <div className={styles.tagsWrapper}>
                    {Boolean(SkinTypesNodes.length)
                        ? SkinTypesNodes
                        : <Tag text='No' type={TagTypes.NEGATIVE} />
                    }
                </div>
            </td>
            <td className={styles.short}>
                <div className={styles.tagsWrapper}>
                    {age}
                </div>
            </td>
            <td className={styles.short}>
                <div className={styles.tagsWrapper}>
                    {AvailabilityNodes}
                </div>
            </td>
            <td>
                {createdDate
                    ? new Date(createdDate).toLocaleDateString('ru-RU')
                    : <Tag text='No' type={TagTypes.NEGATIVE} />
                }
            </td>
            <td className={styles.actionsWrapper}>
                <div className={styles.actions}>
                    <div
                        className={styles.action}
                        onClick={() => {
                            setProduct(product);
                            setIsProductModalOpen(true);
                        }}
                    >
                        <EditIcon />
                    </div>
                    <div
                        className={styles.action}
                        /* @ts-ignore */
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </div>

                    <div
                        className={styles.action}
                        /* @ts-ignore */
                        onClick={handleOpenScorePage}
                    >
                        <OpenIcon />
                    </div>
                </div>
            </td>
        </tr>
    );
});

interface IProps {
    product: TProduct;
}
