import {ChangeEvent, ForwardedRef, forwardRef, useCallback, useEffect, useState, useRef} from 'react';
import cn from 'classnames';

import {Suggests} from './Suggests';
import {TProduct} from 'core/types';
import styles from './FormInput.module.css';

const MemoFormInput = ({
    label,
    placeholder = 'Text',
    onInput,
    suggests= [],
    type= 'text',
    showToggle = false,
    onSave,
}: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [value, setValue] = useState<string>('');
    const [showSuggests, setShowSuggests] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [toggledType, setToggledType] = useState<string>(type);

    const [activeSuggestIdx, setActiveSuggestIdx] = useState<number | null>(null);
    const blankInputRef = useRef<HTMLInputElement>(null);

    //TODO: добавить дебаунс
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setValue(value);
        if (value.length > 2) {
            onInput?.(value);
        } else {
            setShowSuggests(false);
        }
    }, []);

    const handleToggleType = useCallback(() => {
        if (toggledType === 'text') {
            setToggledType('password');
        } else {
            setToggledType('text');
        }
    }, [toggledType]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape') {
            setShowSuggests(false);
        }

        if (e.key !== 'ArrowDown' || !suggests.length || !showSuggests) {
            return;
        }

        e.preventDefault();
        setActiveSuggestIdx(0);
    }, [suggests, showSuggests]);

    const handleBlur = useCallback((e: any) => {
        if (!e.relatedTarget) {
            setShowSuggests(false);
            setFocused(false);
        }
    }, [setShowSuggests, setFocused]);

    useEffect(() => {
        setShowSuggests(Boolean(suggests?.length));
    }, [suggests]);

    useEffect(() => {
        onSave?.(value);
    }, [value]);

    useEffect(() => {
        if (activeSuggestIdx !== -1) {
            return;
        }

        // @ts-ignore
        if (Boolean(ref?.current)) {
            // @ts-ignore
            ref?.current!.focus();
        }

        if (Boolean(blankInputRef.current)) {
            blankInputRef.current!.focus();
        }
    }, [activeSuggestIdx, blankInputRef]);

    useEffect(() => {
        if (!showSuggests) {
            setActiveSuggestIdx(null);
        }
    }, [showSuggests, setActiveSuggestIdx]);

    return (
        <div className={styles.withSuggests}>
            <label
                className={cn(
                    styles.wrapper,
                    {[styles.wrapperActive]: focused},
                    {[styles.wrapperFilled]: Boolean(value.length)},
                )}
                htmlFor={`form_input_${label}`}
            >
                <div className={styles.title}>
                    {label}
                </div>
                <input
                    autoComplete='off'
                    id={`form_input_${label}`}
                    placeholder={placeholder}
                    className={cn(styles.input, {[styles.shortInput]: showToggle})}
                    value={value}
                    ref={ref ? ref : blankInputRef}
                    onChange={handleChange}
                    onFocus={() => {
                        setShowSuggests(true);
                        setFocused(true);
                    }}
                    type={showToggle ? toggledType : type}
                    tabIndex={1}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                {showToggle && Boolean(value.length) && (
                    <span
                        className={styles.modeLabel}
                        onClick={handleToggleType}
                    >
                        {toggledType === 'text' ? 'HIDE' : 'SHOW'}
                    </span>
                )}
            </label>
            {showSuggests && value.length > 2 && (
                <Suggests
                    items={suggests}
                    onClick={({name}) => {setValue(name || '')}}
                    activeIdx={activeSuggestIdx}
                    onSetActiveIdx={setActiveSuggestIdx}
                    onClose={() => setShowSuggests(false)}
                />
            )}
        </div>
    );
};

interface IProps {
    label?: string;
    placeholder?: string;
    onInput?: (text: string) => void;
    suggests?: Array<TProduct>;
    type?: string;
    showToggle?: boolean;
    onSave?: (text: string) => void;
}

export const FormInput = forwardRef(MemoFormInput);
