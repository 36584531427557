import {memo, useCallback} from 'react';
import cn from 'classnames';

import {TagTypes} from 'core/types';
import {ReactComponent as CheckedIcon} from 'assets/images/checked-icon.svg';
import {ReactComponent as StopIcon} from 'assets/images/stop-icon.svg';
import {ReactComponent as ClockIcon} from 'assets/images/clock-icon.svg';
import {ReactComponent as RefreshIcon} from 'assets/images/refresh-icon.svg';
import styles from './Tag.module.css';

export const Tag = memo<IProps>(({
    text,
    type = TagTypes.DEFAULT,
    onClick,
    isIconSupported = false,
    id,
    hoverable = false,
}) => {
    let Icon;
    if (isIconSupported) {
        switch (type) {
            case TagTypes.POSITIVE:
                Icon = CheckedIcon;
                break;
            case TagTypes.NEGATIVE:
                Icon = StopIcon;
                break;
            case TagTypes.DEFAULT:
                Icon = ClockIcon;
                break;
            case TagTypes.PRODUCING:
                Icon = RefreshIcon;
                break;
        }
    }

    const classes = cn(
        {[styles.withIcon]: !!Icon},
        {[styles.positive]: type === TagTypes.POSITIVE},
        {[styles.negative]: type === TagTypes.NEGATIVE},
        {[styles.default]: type === TagTypes.DEFAULT},
        {[styles.big]: type === TagTypes.BIG},
        {[styles.hidden]: type === TagTypes.HIDDEN},
        {[styles.selected]: type === TagTypes.SELECTED},
        {[styles.readyToSelect]: type === TagTypes.READY_TO_SELECT},
        {[styles.disabled]: type === TagTypes.DISABLED},
        {[styles.producing]: type === TagTypes.PRODUCING},
        {[styles.hoverable]: hoverable},
    );

    const handleClick = useCallback(() => {
        if (type === TagTypes.DISABLED) {
            return;
        }

        onClick?.();
    }, [type, onClick]);

    return (
        <div
            className={classes}
            onClick={handleClick}
            {...(!!id && {id})}
        >
            {!!Icon && <Icon />}
            <div className={styles.text}>{text}</div>
        </div>
    );
});

interface IProps {
    text: string;
    type?: TagTypes;
    onClick?: () => void;
    isIconSupported?: boolean;
    id?: string;
    hoverable?: boolean;
}
