import {memo, useCallback, useMemo, useState} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {Content} from './Content';
import {ReactComponent as DeleteIcon} from 'assets/images/delete.svg';
import styles from '../editProductModal/EditProductModal.module.css';

export const ErrorModal = memo(() => {
    const {setIsErrorModalOpen, scan, setProduct, setScan, setIsSelectModalOpen} = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        setIsErrorModalOpen(false);
        setProduct(null);
    }, []);

    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);
    }, []);

    const handleDelete = useCallback(() => {
        setScan(scan);
        setIsSelectModalOpen(true);
    }, [setScan, scan]);

    const ContentNode = useMemo(() => (
        <Content
            scan={scan}
        />
    ), [scan]);

    const extraControls = [
        <div
            className={styles.action}
            onClick={handleDelete}
            key={'error_modal_delete_icon'}
        >
            <DeleteIcon />
        </div>,
    ];

    return (
        <>
            <Modal
                onClose={handleClose}
                title='Are these products identical?'
                extraHeaderControls={extraControls}
                borderUnderTitle
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                />
            )}
        </>
    );
});
