import {useContext, createContext} from 'react';
import {TProduct, TScan, TCurrentUser, TImage} from 'core/types';

interface IAppContext {
    product: TProduct | null;
    setProduct: (value: TProduct | null) => void;
    scan: TScan | null;
    setScan: (value: TScan | null) => void;
    isSelectModalOpen: boolean;
    setIsSelectModalOpen: (value: boolean) => void;
    refresh: boolean;
    setRefresh: (value: boolean) => void;
    isSuccessDeleteModalOpen: boolean;
    setIsSuccessDeleteModalOpen: (value: boolean) => void;
    isSuccessSaveModalOpen: boolean;
    setIsSuccessSaveModalOpen: (value: boolean) => void;
    isComingSoonModalOpen: boolean;
    setIsComingSoonModalOpen: (value: boolean) => void;
    isProductModalOpen: boolean;
    setIsProductModalOpen: (value: boolean) => void;
    isMatchModalOpen: boolean;
    setIsMatchModalOpen: (value: boolean) => void;
    isFindModalOpen: boolean;
    setIsFindModalOpen: (value: boolean) => void;
    isCreateWithScanModalOpen: boolean;
    setIsCreateWithScanModalOpen: (value: boolean) => void;
    user: TCurrentUser | null;
    setUser: (value: TCurrentUser | null) => void;
    isErrorModalOpen: boolean,
    setIsErrorModalOpen: (value: boolean) => void,
    isAllScansModalOpen: boolean,
    setIsAllScansModalOpen: (value: boolean) => void,
    isDeleteScanModalOpen: boolean,
    setIsDeleteScanModalOpen: (value: boolean) => void,
    deletingImage: {
        data: TScan | TImage,
        isProductImage: boolean,
    } | null,
    setDeletingImage: (info: {data: TScan | TImage, isProductImage: boolean} | null) => void,
    isSaveErrorModalOpen: boolean,
    setIsSaveErrorModalOpen: (value: boolean) => void,
    sort: {
        field: string,
        order: string,
    } | null,
    setSort: (info: {field: string, order: string} | null) => void,
    createdProductBubble: string,
    setCreatedProductBubble: (value: string) => void,
    enteredBrandInSearch: string,
    setEnteredBrandInSearch: (value: string) => void,
    enteredProductInSearch: string,
    setEnteredProductInSearch: (value: string) => void,
    allProductChecked: boolean,
    setAllProductChecked: (value: boolean) => void,
    allMatchesChecked: boolean,
    setAllMatchesChecked: (value: boolean) => void,
    allInProgressChecked: boolean,
    setAllInProgressChecked: (value: boolean) => void,
    allErrorsChecked: boolean,
    setAllErrorsChecked: (value: boolean) => void,
    deletingIds: Array<number>,
    setDeletingIds: (value: Array<number>) => void,
    wasProductChanged: boolean,
    setWasProductChanged: (value: boolean) => void,
    isBrandModalOpened: boolean,
    setIsBrandModalOpened: (value: boolean) => void,
    isGptModalOpened: boolean,
    setIsGptModalOpened: (value: boolean) => void,
    isSubscriptionModalOpen: boolean,
    setIsSubscriptionModalOpen: (value: boolean) => void,
    isAuthPending: boolean,
    setIsAuthPending: (value: boolean) => void,
    refreshBalance: boolean;
    setRefreshBalance: (value: boolean) => void,
    isFirstRequestModalOpen: boolean;
    setIsFirstRequestModalOpen: (value: boolean) => void,
    isSuccessPaymentModalOpen: boolean;
    setIsSuccessPaymentModalOpen: (value: boolean) => void,
    isPaymentDevelopingModalOpen: boolean;
    setIsPaymentDevelopingModalOpen: (value: boolean) => void,
    balance: number | null,
    setBalance: (value: number | null) => void,
}

const defaultState = {
    product: null,
    setProduct: () => {},
    scan: null,
    setScan: () => {},
    isSelectModalOpen: false,
    setIsSelectModalOpen: () => {},
    refresh: false,
    setRefresh: () => {},
    isSuccessDeleteModalOpen: false,
    setIsSuccessDeleteModalOpen: () => {},
    isSuccessSaveModalOpen: false,
    setIsSuccessSaveModalOpen: () => {},
    isComingSoonModalOpen: false,
    setIsComingSoonModalOpen: () => {},
    isProductModalOpen: false,
    setIsProductModalOpen: () => {},
    isMatchModalOpen: false,
    setIsMatchModalOpen: () => {},
    isFindModalOpen: false,
    setIsFindModalOpen: () => {},
    isCreateWithScanModalOpen: false,
    setIsCreateWithScanModalOpen: () => {},
    user: null,
    setUser: () => {},
    isErrorModalOpen: false,
    setIsErrorModalOpen: () => {},
    isAllScansModalOpen: false,
    setIsAllScansModalOpen: () => {},
    isDeleteScanModalOpen: false,
    setIsDeleteScanModalOpen: () => {},
    deletingImage: null,
    setDeletingImage: () => {},
    isSaveErrorModalOpen: false,
    setIsSaveErrorModalOpen: () => {},
    sort: null,
    setSort: () => {},
    createdProductBubble: '0',
    setCreatedProductBubble: () => {},
    enteredBrandInSearch: '',
    setEnteredBrandInSearch: () => {},
    enteredProductInSearch: '',
    setEnteredProductInSearch: () => {},
    allProductChecked: false,
    setAllProductChecked: () => {},
    allMatchesChecked: false,
    setAllMatchesChecked: () => {},
    allInProgressChecked: false,
    setAllInProgressChecked: () => {},
    allErrorsChecked: false,
    setAllErrorsChecked: () => {},
    deletingIds: [],
    setDeletingIds: () => {},
    wasProductChanged: false,
    setWasProductChanged: () => {},
    isBrandModalOpened: false,
    setIsBrandModalOpened: () => {},
    isGptModalOpened: false,
    setIsGptModalOpened: () => {},
    isSubscriptionModalOpen: false,
    setIsSubscriptionModalOpen: () => {},
    isAuthPending: false,
    setIsAuthPending: () => {},
    refreshBalance: false,
    setRefreshBalance: () => {},
    isFirstRequestModalOpen: false,
    setIsFirstRequestModalOpen: () => {},
    isSuccessPaymentModalOpen: false,
    setIsSuccessPaymentModalOpen: () => {},
    isPaymentDevelopingModalOpen: false,
    setIsPaymentDevelopingModalOpen: () => {},
    balance: null,
    setBalance: () => {},
};

export const AppContext = createContext<IAppContext>(defaultState);
export const useAppContext = () => useContext(AppContext);
