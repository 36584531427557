import {ForwardedRef, forwardRef} from 'react';

import styles from './Checkbox.module.css';

const ClearCheckbox = ({onClick, checked = false}: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
        <label className={styles.checkbox}>
            <input
                type='checkbox'
                name='delete-reason'
                checked={checked}
                ref={ref}
                onChange={() => {}}
            />
            <span
                className={styles.checkmark}
                onClick={(e) => {
                    onClick?.();
                    e.stopPropagation();
                }}
            />
        </label>
    );
};

export const Checkbox = forwardRef(ClearCheckbox);

interface IProps {
    onClick?: () => void;
    checked?: boolean;
}
