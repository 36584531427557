import {memo, useMemo, useState, useEffect} from 'react';
import cn from 'classnames';

import {SCAN_LIST_HEADERS} from 'core/constants';
import {TScan} from 'core/types';
import {ScanRow} from 'components/blocks/scanRow';
import {useAppContext} from 'components/app/AppContext';
import {Checkbox} from 'components/common/checkbox';
import styles from './ScanList.module.css';

export const ScanList = memo<IProps>(({
    items,
}) => {
    const {setSort, sort, setRefresh, setAllInProgressChecked, allInProgressChecked, setDeletingIds} = useAppContext();
    const [cached, setCached] = useState<Array<TScan>>([]);

    const RowsNodes = useMemo(() => {
        return cached.map((item) => {
            return (
                <ScanRow
                    scan={item}
                    key={`scans_${item.id}`}
                />
            );
        });
    }, [cached]);

    const CheckboxHeaderNode = useMemo(() => (
        <th>
            <Checkbox
                onClick={() => {
                    const newValue = !allInProgressChecked;
                    if (newValue) {
                        setDeletingIds(items.map(({id}) => Number(id)));
                    } else {
                        setDeletingIds([]);
                    }
                    setAllInProgressChecked(newValue);
                }}
                checked={allInProgressChecked}
            />
        </th>
    ), [setAllInProgressChecked, allInProgressChecked, setDeletingIds, items]);

    const HeaderNodes = useMemo(() => {
        return SCAN_LIST_HEADERS.map((name, index) => {
            const isClickable = name === 'Scan ID' || 'Date of scan';

            return (
                <th
                    key={`${name}_${index}_${sort?.order}`}
                    className={cn({[styles.clickable]: isClickable})}
                    onClick={() => {
                        if (!isClickable) return;

                        const {field, order} = sort || {};

                        if (name === 'Scan ID') {
                            if (!field || field !== 'inProgressScanId' || order === 'id desc') {
                                setSort({field: 'inProgressScanId', order: 'id asc'});
                            } else {
                                setSort({field: 'inProgressScanId', order: 'id desc'});
                            }
                        }

                        if (name === 'Date of scan') {
                            if (!field || field !== 'inProgressCreatedDate' || order === 'createdDate desc') {
                                setSort({field: 'inProgressCreatedDate', order: 'createdDate asc'});
                            } else {
                                setSort({field: 'inProgressCreatedDate', order: 'createdDate desc'});
                            }
                        }

                        setRefresh(true);
                    }}
                >
                    {name}
                </th>
            );
        });
    }, [setSort, sort, setRefresh]);

    useEffect(() => {
        if (!cached.length) {
            setCached(items);
            return;
        }

        setCached(items);
    }, [items]);

    return (
        <>
            <table className={styles.list}>
                <thead>
                    <tr>
                        {CheckboxHeaderNode}
                        {HeaderNodes}
                    </tr>
                </thead>
                <tbody>
                    {RowsNodes}
                </tbody>
            </table>
        </>
    );
});

interface IProps {
    items: Array<TScan>;
}
