import {memo, useMemo} from 'react';
import cn from 'classnames';

import {ReactComponent as PositiveIcon} from 'assets/images/heart.svg';
import {ReactComponent as NegativeIcon} from 'assets/images/broken-heart.svg';
import {ReactComponent as IngredientsIcon} from 'assets/images/ingredients-icon.svg';
import styles from './ScoreResult.module.css';

const NEGATIVE_BREAKPOINT = 50;

export const ScoreResult = memo<IProps>(({score, isEnoughData = true, label, isMini = false, custom = null}) => {
    const percentage = (isMini || custom === 'ingredients') ? score : Math.round(score * 100);
    const isNegative = useMemo(() => percentage < NEGATIVE_BREAKPOINT, [percentage]);
    const isUndefined = !isEnoughData;
    const classes = isUndefined
        ? styles.wrapperUndefined
        : isNegative ? styles.wrapperNegative : styles.wrapperPositive;

    if (custom === 'ingredients') {
        return (
            <div className={cn(classes, styles.wrapperMicro)}>
                <div className={styles.icon}>
                    <IngredientsIcon />
                </div>
                <div>{label || 'MATCH SCORE'} — {percentage}%</div>
            </div>
        );
    }

    return (
        <div className={cn(classes, {[styles.wrapperMini]: isMini})}>
            <div className={styles.icon}>
                {isMini
                ? <IngredientsIcon />
                : (isNegative || isUndefined) ? <NegativeIcon /> : <PositiveIcon />}
            </div>
            {isUndefined
            ? (
                <div>NOT ENOUGH DATA</div>
            )
            : (
                isMini
                    ? <div>{percentage}%</div>
                    : <div>{label || 'MATCH SCORE'} — {percentage}%</div>
            )}
        </div>
    );
});

interface IProps {
    score: number;
    isEnoughData?: boolean;
    label?: string;
    isMini?: boolean;
    custom?: 'ingredients';
}
