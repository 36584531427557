import {memo, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'components/app/AppContext';
import styles from './Pagination.module.css';

export const Page = memo<IProps>(({
    number,
    clickable = false,
    onClick,
    isActive,
}) => {
    // const isActive = useMemo(() => {
    //     const page = searchParams.get('p');
    //
    //     if (!page) {
    //         return number === 1;
    //     }
    //
    //     return number === Number(page);
    // }, [searchParams, number]);

    const handleClick = useCallback(() => {
        if (isActive || !clickable) return;

        onClick();
        // let oldParams = {};
        // if (Array.from(searchParams.keys()).length > 0) {
        //     oldParams = Array.from(searchParams.entries()).map(([a, b]) => ({[a]: b})).reduce((acc, val) => {
        //         acc = {...acc, ...val};
        //         return acc;
        //     }, {});
        // }
        //
        // setDeletingIds([]);
        // setAllProductChecked(false);
        //
        // setSearchParams(
        //     {...oldParams, p: String(number)},
        //     {replace: true},
        // );
    }, [isActive, number, clickable]);

    return (
        <div
            className={cn(
                styles.page,
                {[styles.pageActive]: isActive},
                {[styles.notClickablePage]: !clickable},
            )}
            onClick={handleClick}
        >
            {number}
        </div>
    );
});

interface IProps {
    number: number | string;
    clickable?: boolean;
    onClick: () => void;
    isActive: boolean;
}
