import {memo, useCallback, useEffect, useMemo} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import {MAX_ITEM_COUNT_FOR_PAGE} from 'core/constants';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as ArrowIcon} from 'assets/images/arrow.svg';
import {Page} from './Page';
import styles from './Pagination.module.css';

export const Pagination = memo<IProps>(({
    count,
}) => {
    const {search} = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {setDeletingIds, setAllProductChecked} = useAppContext();

    const active = useMemo(() => {
        const decoded = new URLSearchParams(search).get('p');

        return Number(decoded) || 1;
    }, [search]);

    const numbers = useMemo(() => {
        if (!count) return [];

        const fullCount = Math.ceil(count/MAX_ITEM_COUNT_FOR_PAGE);
        return Array.from({length: fullCount}, (_, i) => i + 1);
    }, [count]);

    const numberNodes = useMemo(() => {
        const max = numbers.length;

        const activeIndex = active - 1;
        const diff = max - activeIndex;

        // case, when there aren't skipped pages
        if (max <= 6) {
            return numbers.map((num) => ({value: num, clickable: true}));
        }

        // case, when we in the middle
        if (diff > 4 && activeIndex > 3) {
            const window = numbers.slice(activeIndex - 2, activeIndex + 3);
            return [
                {
                    value: 1,
                    clickable: true,
                },
                {
                    value: '...',
                    clickable: false,
                },
                ...window.map((num) => ({value: num, clickable: true})),
                {
                    value: '...',
                    clickable: false,
                },
                {
                    value: max,
                    clickable: true,
                }
            ];
        }

        // case, when we in the beginning
        if (activeIndex <= 3) {
            const window = numbers.slice(0, 5);
            return [
                ...window.map((num) => ({value: num, clickable: true})),
                {
                    value: '...',
                    clickable: false,
                },
                {
                    value: max,
                    clickable: true,
                }
            ];
        }

        // case, when we in the end
        return [
            {
                value: 1,
                clickable: true,
            },
            {
                value: '...',
                clickable: false,
            },
            ...numbers.slice(-5).map((num) => ({value: num, clickable: true})),
        ];
    }, [numbers, active]);

    const handleArrowClick = useCallback((side: string) => {
        setDeletingIds([]);
        setAllProductChecked(false);

        const current = searchParams.get('p') || 1;

        let oldParams = {};
        if (Array.from(searchParams.keys()).length > 0) {
            oldParams = Array.from(searchParams.entries()).map(([a, b]) => ({[a]: b})).reduce((acc, val) => {
                acc = {...acc, ...val};
                return acc;
            }, {});
        }

        if (side === 'right') {
            setSearchParams(
                {...oldParams, p: String(Number(current) + 1)},
                {replace: true},
            );
        }

        if (side === 'left') {
            setSearchParams(
                {...oldParams, p: String(Number(current) - 1)},
                {replace: true},
            );
        }
    }, [searchParams, setDeletingIds]);

    useEffect(() => {
        if (active > numbers[numbers.length - 1]) {
            let oldParams = {};
            if (Array.from(searchParams.keys()).length > 0) {
                oldParams = Array.from(searchParams.entries()).map(([a, b]) => ({[a]: b})).reduce((acc, val) => {
                    acc = {...acc, ...val};
                    return acc;
                }, {});
            }

            setDeletingIds([]);
            setAllProductChecked(false);

            setSearchParams(
                {...oldParams, p: String(numbers[numbers.length - 1])},
                {replace: true},
            );
        }
    }, [active, numbers, searchParams, setDeletingIds]);

    if (!count) return null;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div
                    className={active === 1 ? styles.arrowDisable : styles.arrow}
                    onClick={() => handleArrowClick('left')}
                >
                    <ArrowIcon />
                </div>
                {numberNodes.map(({value, clickable}, idx) => (
                    <Page
                        number={value}
                        key={`page_${value}_${idx}`}
                        clickable={clickable}
                    />
                ))}
                <div
                    className={active === numbers.length ? styles.arrowDisableRotate : styles.arrowRotate}
                    onClick={() => handleArrowClick('right')}
                >
                    <ArrowIcon />
                </div>
            </div>
        </div>
    );
});

interface IProps {
    count: number | null;
}
