import {useEffect, useState} from 'react';
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosError} from 'axios';

import {axios} from 'services/http';
import {RequestType} from 'core/types';
import {USER_ROLES} from 'core/constants';
import {ROLE_ALLOW_ROUTES_CONFIG} from 'core/configs';
import {useAppContext} from 'components/app/AppContext';

interface IRequestResult<T> {
    data: T | null;
    isPending: boolean;
    error?: string | AxiosError<T>;
}

interface IRequest<T> {
    url: string;
    method: RequestType;
    data?: T;
    headers?: AxiosRequestHeaders;
}

type RequestCB<T> = (data: T) => IRequest<Partial<T>>;

/**
 * Universal fully typed hook for making axios requests.
 * Requires two generics - Request type and Response type
 * Usage:
 * const useMyReq =
 *   useRequest<RequestInterface, ResponseInterface>((data <- what you've passed in fetch()) => ({
 *     url: 'your url'
 *     method: 'get | post | ...',
 *     data <- request body, optional,
 *   }));
 * In component
 * const [{
 *    data, <- server response. Will be ResponseInterface type.
 *    isPending, <- boolean did request finished
 *    error <- string or Error object, if error occurred during request
 *  }, fetch <- function to make request. Pass arguments, that you've described in RequestInterface
 * ] = useMyReq();
 */
export const useRequest = <IReq, IRes>(setRequestData: RequestCB<IReq>, isAuth: boolean = false, isFile: boolean = false) => {
    const [result, setResult] = useState<IRequestResult<IRes>>({
        data: null,
        isPending: false,
    });
    const [request, setRequest] = useState<AxiosRequestConfig>();

    useEffect(() => {
        if (!request) return;

        setResult({
            data: null,
            isPending: true,
            error: undefined,
        });

        axios(request)
            .then((response) =>
                setResult({
                    data: response.data,
                    isPending: false,
                }),
            )
            .catch((err) =>
                setResult({
                    data: null,
                    isPending: false,
                    error: err.response || 'Network error',
                }),
            );
    }, [request, isAuth]);

    const makeRequest = (args: IReq) => {
        const inputData = {
            ...setRequestData(args),
        };

        if (isAuth) {
            const savedToken = localStorage.getItem('token');

            if (!savedToken) {
                setResult({
                    data: null,
                    isPending: false,
                    error: new AxiosError('Not allowed without JWT-token. Need authentication'),
                });
            } else {
                inputData.headers = {
                    ...inputData.headers,
                    'Authorization': `Bearer ${savedToken}`,
                }
            }
        }

        setRequest(inputData);
    };

    return [result, makeRequest] as const;
};

//@ts-ignore
export const useOutsideClicker = (ref, callback) => {
    useEffect(() => {
        //@ts-ignore
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{width: number, height: number}>({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const useCheckAllowRoutes = (path: string) => {
    const {user} = useAppContext();

    if (!user?.role) {
        return false;
    }

    const allowedRoutes = ROLE_ALLOW_ROUTES_CONFIG[user.role as USER_ROLES];
    if (!allowedRoutes.length) {
        return true;
    }

    // @ts-ignore
    return allowedRoutes.includes(path);
}
