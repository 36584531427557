import {memo, ReactNode, useState} from 'react';
import cn from 'classnames';

import NoImagePicture from 'assets/images/noImage.png';
import styles from './Preview.module.css';

export const Preview = memo<IProps>(({
    url,
    alt,
    fit = false,
    customFallback,
}) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    if (!url || isError) {
        return (
            <div className={fit ? styles.noImageFit : styles.noImage}>
                {!!customFallback
                    ? <div className={styles.noImageInner}>{customFallback}</div>
                    : <img src={NoImagePicture} alt='' />
                }
            </div>
        );
    }

    return (
        <div className={fit ? styles.imageFit : styles.image}>
            <div className={cn(styles.skeleton, {
                [styles.skeletonHidden]: isLoaded
            })}/>
            <img
                src={url!}
                onLoad={() => setIsLoaded(true)}
                alt={alt}
                className={cn({[styles.imageHidden]: !isLoaded})}
                onError={() => {
                    setIsLoaded(true);
                    setIsError(true);
                }}
            />
        </div>
    );
});

interface IProps {
    url: string | null;
    alt?: string;
    fit?: boolean;
    customFallback?: ReactNode;
}
