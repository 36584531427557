import {memo, useState, useMemo, useEffect} from 'react';
import cn from 'classnames';

import {SCAN_LIST_HEADERS} from 'core/constants';
import {TScan} from 'core/types';
import {MatchRow} from 'components/blocks/matchRow';
import {useAppContext} from 'components/app/AppContext';
import {Checkbox} from 'components/common/checkbox';
import styles from './MatchList.module.css';

export const MatchList = memo<IProps>(({
    items,
}) => {
    const {setSort, sort, setRefresh, setAllMatchesChecked, allMatchesChecked, setDeletingIds} = useAppContext();
    const [cachedMatches, setCachedMatches] = useState<Array<TScan>>([]);

    const RowsNodes = useMemo(() => {
        return cachedMatches.map((item) => {
            return (
                <MatchRow
                    scan={item}
                    key={`match_${item.id}`}
                />
            );
        });
    }, [cachedMatches]);

    const CheckboxHeaderNode = useMemo(() => (
        <th>
            <Checkbox
                onClick={() => {
                    const newValue = !allMatchesChecked;
                    if (newValue) {
                        setDeletingIds(items.map(({id}) => Number(id)));
                    } else {
                        setDeletingIds([]);
                    }
                    setAllMatchesChecked(newValue);
                }}
                checked={allMatchesChecked}
            />
        </th>
    ), [setAllMatchesChecked, allMatchesChecked, setDeletingIds, items]);

    const HeaderNodes = useMemo(() => {
        return SCAN_LIST_HEADERS.map((name, index) => {
            const isClickable = name === 'Prod. ID' || name === 'Scan ID' || 'Date of scan';

            return (
                <th
                    key={`${name}_${index}_${sort?.order}`}
                    className={cn({[styles.clickable]: isClickable})}
                    onClick={() => {
                        if (!isClickable) return;

                        const {field, order} = sort || {};

                        if (name === 'Prod. ID') {
                            if (!field || field !== 'matchProductId' || order === 'id desc') {
                                setSort({field: 'matchProductId', order: 'productId asc'});
                            } else {
                                setSort({field: 'matchProductId', order: 'productId desc'});
                            }
                        }

                        if (name === 'Scan ID') {
                            if (!field || field !== 'matchScanId' || order === 'id desc') {
                                setSort({field: 'matchScanId', order: 'id asc'});
                            } else {
                                setSort({field: 'matchScanId', order: 'id desc'});
                            }
                        }

                        if (name === 'Date of scan') {
                            if (!field || field !== 'matchCreatedDate' || order === 'createdDate desc') {
                                setSort({field: 'matchCreatedDate', order: 'createdDate asc'});
                            } else {
                                setSort({field: 'matchCreatedDate', order: 'createdDate desc'});
                            }
                        }

                        setRefresh(true);
                    }}
                >
                    {name}
                </th>
            );
        })
    }, [setSort, sort, setRefresh]);

    useEffect(() => {
        if (!cachedMatches.length) {
            setCachedMatches(items);
            return;
        }

        setCachedMatches(items);
    }, [items]);

    if (!items.length) {
        return (
            <div className={styles.notFoundWrapper}>
                <div className={styles.notFoundTitle}>
                    No matches :(
                </div>
                <div className={styles.notFoundText}>
                    Maybe mathes with this filter don’t exist in our database.
                    Try another one.
                </div>
            </div>
        );
    }

    return (
        <>
            <table className={styles.list}>
                <thead>
                    <tr>
                        {CheckboxHeaderNode}
                        {HeaderNodes}
                    </tr>
                </thead>
                <tbody>
                    {RowsNodes}
                </tbody>
            </table>
        </>
    );
});

interface IProps {
    items: Array<TScan>;
}
