import {memo, useCallback, useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {Button} from 'components/common/newButton';
import {FormInput} from 'components/common/form';
import {useAppContext} from 'components/app/AppContext';
import {ROUTES, USER_ROLES} from 'core/constants';
import {useLogin, useMe} from 'queries/user';
import {useRequestsBalance} from 'queries/score';
import {ReactComponent as WarningIcon} from 'assets/images/warning.svg';
import styles from './Login.module.css';

export const Login = memo(() => {
    const [{data, isPending, error}, fetch] = useLogin();
    const [{data: dataMe, isPending: isPendingMe}, fetchMe] = useMe();
    const [{data: balance}, fetchBalance] = useRequestsBalance();

    const location = useLocation();
    const {setUser, user, setBalance} = useAppContext();
    const [name, setName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [showError, setShowError] = useState<boolean>(false);

    const handleSubmit = useCallback(() => {
        if (Boolean(name?.length && password?.length)) {
            fetch({
                name: name as string,
                password: password as string
            });
        }
    }, [name, password]);

    useEffect(() => {
        if (!!data && !error) {
            localStorage.setItem('token', String(data?.token));
            fetchMe();
            fetchBalance();
        }

        if (!!error) {
            setShowError(true);
        }
    }, [data, error]);

    useEffect(() => {
        if (!!dataMe) {
            setUser(dataMe);
            // if (balance !== null) {
            //     setUser({...dataMe, balance: balance === null ? null : balance});
            // }
        }
    }, [dataMe, setUser]);

    useEffect(() => {
        if (balance !== null) {
            setBalance(balance);
        }
    }, [balance]);

    if (!!user) {
        return (
            <Navigate
                to={ROUTES.PRODUCTS}
                state={{from: location}}
                replace
            />
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Log Into My Account
            </div>
            <div className={styles.form}>
                <FormInput
                    label='E-mail'
                    placeholder='your@email.com'
                    onInput={(text) => {
                        setShowError(false);
                        setName(text);
                    }}
                />
                <FormInput
                    label='Password'
                    placeholder='• • • • • • • • '
                    type='password'
                    onInput={(text) => {
                        setShowError(false);
                        setPassword(text);
                    }}
                    showToggle
                />
            </div>
            {showError && (
                <div className={styles.error}>
                    <div>
                        <WarningIcon />
                    </div>
                    <div className={styles.errorText}>
                        The password or email you entered is incorrect. Let's try that again.
                    </div>
                </div>
            )}
            <div className={styles.button}>
                <Button text='Log In' onClick={handleSubmit} isLoading={isPending || isPendingMe} />
            </div>
            <div className={styles.help}>
                <a href={ROUTES.HOME}>Don’t have an account?</a>
                <a href={ROUTES.HOME}>Forgot password?</a>
            </div>
        </div>
    );
});
