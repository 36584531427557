import {memo, ReactNode} from 'react';

import {InformationNote} from 'components/blocks/informationNote/InformationNote';
import {ReactComponent as RiseArrow} from 'assets/images/rise-arrow.svg';
import styles from './GraphWidget.module.css';

export const GraphWidget = memo<IProps>(({title, trend, description, graph}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h2 className={styles.title}>{title}</h2>
                <TrendBadge type={trend} />
            </div>
            <div className={styles.description}>
                {description}
            </div>
            <div className={styles.graph}>
                {graph}
            </div>
            <div>
                <InformationNote type='graph' />
            </div>
        </div>
    );
});

interface IProps {
    title: string;
    trend: 'good' | 'bad';
    description: string;
    graph: ReactNode;
}

const TrendBadge = memo<ITrendBadgeProps>(({type}) => {
    const isBad = type === 'bad';

    return (
        <div className={isBad ? styles.badgeWrapperBad : styles.badgeWrapper}>
            {isBad ? <RiseArrow className={styles.badgeRiseDownIcon} /> : <RiseArrow />}
            <span>
                {isBad ? 'BAD TIME FOR PROMOTION' : 'GOOD TIME FOR PROMOTION'}
            </span>
        </div>
    );
});

interface ITrendBadgeProps {
    type: IProps['trend'];
}
