import {memo} from 'react';

import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import styles from './CancelModal.module.css';

export const CancelModal = memo<IProps>(({
    onOk,
    onCancel,
}) => {
    return (
        <Modal
            title='Are you sure?'
            description='If you close this screen all your changes will disappear.'
            onClose={onCancel}
        >
            <div className={styles.wrapper}>
                <Button text='Ok' onClick={onOk} />
                <div
                    className={styles.link}
                    onClick={onCancel}
                >
                    Cancel
                </div>
            </div>
        </Modal>
    );
});

interface IProps {
    onOk: () => void;
    onCancel: () => void;
}
