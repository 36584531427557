import {memo, useEffect, useRef} from 'react';

import {ScoreCheckingField} from 'components/blocks/scoreCheckingField';
import {ScoreConfigField, TCityMatchResult} from 'core/types';
import styles from './CityMatchRow.module.css';

export const CityMatchRow = memo<IProps>((props) => {
    const {field, info, onModalClick, onDidMount, height, customIndex} = props;
    const ref = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (!!ref?.current && !height) {
            onDidMount(ref.current.offsetHeight);
        }
    }, [ref?.current, height]);

    return (
        <tr
            ref={ref}
            style={{'height': (!!height && !!field.title) ? height : 'initial'}}
        >
            {!!field.title && (
                <td className={styles.cellName}>
                    {field.title}
                </td>
            )}
            <td className={!field.title ? styles.cellCollapsed : styles.cellValue}>
                <ScoreCheckingField
                    field={field}
                    info={info}
                    onModalClick={onModalClick}
                    customIndex={customIndex}
                />
            </td>
        </tr>
    );
});

interface IProps {
    field: ScoreConfigField;
    info: TCityMatchResult;
    height: number;
    onModalClick: () => void;
    onDidMount: (height: number) => void;
    customIndex?: number;
}
