import {memo, useMemo, useEffect, useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useErrorHandler} from 'react-error-boundary';

import {useScansErrors, useScansErrorsCount} from 'queries/scans';
import {ErrorList} from 'components/blocks/errorList';
import {Forbidden, InternalError, NoErrorsList} from 'components/blocks/troubles';
import {useAppContext} from 'components/app/AppContext';
import {Pagination} from 'components/blocks/pagination';
import {TScan} from 'core/types';
import {MAX_ITEM_COUNT_FOR_PAGE} from 'core/constants';

export const Errors = memo(() => {
    const [{data, isPending, error}, fetch] = useScansErrors();
    const {refresh, setRefresh, sort, deletingIds, setIsSelectModalOpen, setDeletingIds} = useAppContext();
    const [{data: countData}, fetchCount] = useScansErrorsCount();
    const [cached, setCached] = useState<Array<TScan> | null>([]);
    const [cachedCount, setCachedCount] = useState<number | null>();

    const fetchScans = useCallback(fetch, []);
    const fetchScansCount = useCallback(fetchCount, []);

    const {search} = useLocation();
    const handleError = useErrorHandler();

    const handleDeletePack = useCallback(() => {
        setIsSelectModalOpen(true);
    }, []);

    const handleClearPackSelection = useCallback(() => {
        setDeletingIds([]);
    }, [setDeletingIds]);

    const offset = useMemo(() => {
        if (!search.length) return 0;

        const page = new URLSearchParams(search).get('p');
        if (!page || Number(page) === 1) return 0;

        return (Number(page) - 1) * MAX_ITEM_COUNT_FOR_PAGE;
    }, [search]);

    const selectedInfo = useMemo(() => {
        if (!deletingIds.length) {
            return null;
        }

        return (
            <div className='selectedInfo'>
                <div className='selectedInfoCount'>
                    {deletingIds.length}
                </div>
                <div className='selectedInfoText'>
                    item(s) to delete
                </div>
                <div className='selectedInfoButtons'>
                    <div
                        className='selectedInfoButton'
                        onClick={handleDeletePack}
                    >
                        Delete
                    </div>
                    <div
                        className='selectedInfoButton'
                        onClick={handleClearPackSelection}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }, [deletingIds]);

    useEffect(() => {
        fetchScans({offset});
        fetchScansCount();
    }, [fetchScans, offset]);

    useEffect(() => {
        if (refresh) {
            setCached(data);
            setCachedCount(countData?.count);

            fetchScans({offset, order: sort?.order});
            fetchScansCount();
            setRefresh(false);
        }
    }, [fetchScans, refresh, offset, sort, setCached, setCachedCount]);

    useEffect(() => {
        if (isPending && Boolean(cached?.length)) {
            document.body.classList.add('overflowed');
        } else {
            document.body.classList.remove('overflowed');
        }
    }, [isPending, cached?.length]);

    if (Boolean(error)) {
        // @ts-ignore
        if (typeof error !== 'string' && error?.status === 403) return <Forbidden />;
        // @ts-ignore
        if (typeof error !== 'string' && error?.status === 500) return <InternalError />;

        handleError(error);
        return null;
    }

    // Свежая загрузка (без кеша)
    if ((!data || isPending) && !cached?.length) {
        return (
            <div className='pageloaderWrapper'>
                <div className='pageloader'/>
            </div>
        );
    }

    return (
        <>
            {!data?.length && !cached?.length && !isPending
            ? (
                <NoErrorsList />
            )
            : (
                <div>
                    {selectedInfo}
                    {/* Подгрузка (обновление кеша) */}
                    {isPending && Boolean(cached?.length) && (
                        <>
                            <div className='contentLoaderUnder'>
                                <div className='contentLoaderWrapper'>
                                    <div className='contentLoader'/>
                                </div>
                            </div>
                        </>
                    )}

                    <ErrorList items={(data || cached || [])} />
                    <Pagination count={countData?.count || cachedCount || null} />
                </div>
            )}
        </>
    );
});
