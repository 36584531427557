import {memo, useCallback, useMemo, useState} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {Content} from './Content';

export const FindModal = memo(() => {
    const {setIsFindModalOpen, scan} = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        setIsFindModalOpen(false);
    }, []);
    const handleConfirm = useCallback(() => {
        setIsConfirmModalOpen(true);
    }, []);
    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);
    }, []);

    const ContentNode = useMemo(() => (
        <Content
            scan={scan}
            onConfirm={handleConfirm}
        />
    ), [scan]);

    return (
        <>
            <Modal
                onClose={handleClose}
                title='Find the right product'
                borderUnderTitle
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                />
            )}
        </>
    );
});
