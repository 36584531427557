import {memo, useCallback} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import styles from './PaymentDevelopingModal.module.css';

export const PaymentDevelopingModal = memo(() => {
    const {setIsPaymentDevelopingModalOpen} = useAppContext();

    const handleClose = useCallback(() => {
        setIsPaymentDevelopingModalOpen(false);
    }, []);

    return (
        <Modal
            title='Payment issue'
            onClose={handleClose}
        >
            <div>
                <div className={styles.text}>
                    Our team will contact you shortly via the email provided in your login.
                </div>
                <div className={styles.controls}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
