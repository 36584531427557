import {memo, ReactElement, useMemo} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import styles from './Button.module.css';

export enum ButtonTypes {
    mini = 'ButtonMini',
    micro = 'ButtonMicro',
    tab = 'ButtonTab',
}

export enum ButtonThemes {
    primary = 'ButtonFilled',
    secondary = 'ButtonStroked',
    tab = 'TabDefault',
}

export const Button = memo<IProps>(({
    text,
    type = ButtonTypes.mini,
    theme = ButtonThemes.primary,
    icon,
    link,
    top,
    onClick,
    isLoading = false,
    isSubmit = false,
    isDisabled = false,
}) => {
    const typeClass = cn(
        {[styles.tabSize]: type === ButtonTypes.tab},
        {[styles.micro]: type === ButtonTypes.micro},
        {[styles.default]: type === ButtonTypes.mini},
        {[styles.icon]: Boolean(icon) && !text},
    );
    const themeClass = cn(
        {[styles.stroked]: theme === ButtonThemes.secondary},
        {[styles.tabTheme]: theme === ButtonThemes.tab},
        {[styles.filled]: theme === ButtonThemes.primary && !isDisabled},
        {[styles.filledDisabled]: theme === ButtonThemes.primary && isDisabled},
    );

    const ContentNode = useMemo(() => {
        if (isLoading) {
            return <div className={styles.loader} />;
        }

        return (
            <>
                {Boolean(text) && <span>{text}</span>}
                {Boolean(icon) && icon}
                {Boolean(top) && Number(top) !== 0 && top !== 'undefined' && (
                    <span className={styles.top}>
                    {top}
                </span>
                )}
            </>
        )
    }, [text, icon, top, isLoading]);

    if (Boolean(link)) {
        if (link?.includes('http')) {
            return (
                <a
                    href={String(link)}
                    className={cn(typeClass, themeClass)}
                    onClick={onClick}
                >
                    {ContentNode}
                </a>
            );
        }

        return (
            <Link
                to={String(link)}
                className={cn(typeClass, themeClass)}
                onClick={onClick}
            >
                {ContentNode}
            </Link>
        );
    }

    return (
        <button
            className={cn(typeClass, themeClass)}
            onClick={onClick}
            type={isSubmit ? 'submit' : 'button'}
        >
            {ContentNode}
        </button>
    )
});

interface IProps {
    text?: string;
    type?: ButtonTypes;
    theme?: ButtonThemes;
    icon?: ReactElement;
    onClick?: () => void;
    link?: string;
    top?: string;
    isLoading?: boolean;
    isSubmit?: boolean;
    isDisabled?: boolean;
}
