import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import {Dropdown, IOption} from 'components/common/dropdown';
import styles from './GenderDropdown.module.css';

export const GenderDropdown = memo<IProps>(({femalePart, onChange}) => {
    const options: Array<IOption> = useMemo(() => ([
        {
            text: <div className={styles.option}>Women: <span className={styles.regular}>{femalePart}%</span></div>,
            value: 'Female',
        },
        {
            text: <div className={styles.option}>Men: <span className={styles.regular}>{100 - femalePart}%</span></div>,
            value: 'Male',
        },
    ]), [femalePart]);

    const [current, setCurrent] = useState<IOption>(options[0]);

    const handleChange = useCallback((newOption: IOption) => {
        setCurrent(newOption);
        onChange(newOption.value);
    }, [onChange]);

    return (
        <div className={styles.wrapper}>
            <Dropdown
                options={options}
                current={current}
                onChange={handleChange}
            />
        </div>
    );
});

interface IProps {
    femalePart: number;
    onChange: (value: string) => void;
}
