import {memo, useMemo, useEffect, Fragment, useCallback} from 'react';

import {SelectModal} from 'components/common/selectModal';
import {useAppContext} from 'components/app/AppContext';
import {useDeleteError, useDeleteScans} from 'queries/scans';

const REASONS = [
    'Not a skincare product',
    'Inappropriate photo',
    'Product without photo',
    'Unreadable',
    'Not English',
    'Another reason',
];

export const DeleteErrorModal = memo(() => {
    const {scan, setScan, setIsSelectModalOpen, setIsSuccessDeleteModalOpen, setIsErrorModalOpen, deletingIds} = useAppContext();
    const [{data, isPending, error}, fetch] = useDeleteError(Number(scan?.id));
    const [deletedPack, fetchDeletingPack] = useDeleteScans();

    const handleClose = useCallback(() => {
        setIsSelectModalOpen(false);
        setScan(null);
    }, [setScan, setIsSelectModalOpen]);

    const handleReport = useCallback((text?: string) => {
        if (Boolean(scan?.id)) {
            fetch({deletedReason: text});
            return;
        }

        if (Boolean(deletingIds.length)) {
            fetchDeletingPack({
                deletedReason: text,
                // @ts-ignore
                ids: deletingIds,
            });
        }
    }, [scan?.id, deletingIds]);

    const title = useMemo(() => (
        <>
            Why do you want
            <Fragment><br/></Fragment>
            to delete {Boolean(scan) ? 'this' : `${deletingIds.length} items`}
            <Fragment><br/></Fragment>
            from Errors?
        </>
    ), [deletingIds, scan]);

    useEffect(() => {
        if ((Boolean(scan) && !error && typeof data === 'string')
            || (Boolean(deletingIds.length) && !deletedPack?.error && Boolean(deletedPack?.data))
        ) {
            handleClose();
            setIsErrorModalOpen(false);
            setIsSuccessDeleteModalOpen(true);
        }
    }, [data, error, scan, deletingIds, deletedPack?.error, deletedPack?.data, setIsErrorModalOpen, handleClose, setIsSuccessDeleteModalOpen]);

    return (
        <SelectModal
            title={title}
            reasons={REASONS}
            isLoading={isPending}
            onClose={handleClose}
            onSubmit={handleReport}
        />
    );
});
