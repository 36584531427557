import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';

import {Tag} from 'components/common/tag';
import {TagTypes} from 'core/types';
import {TAGS_SELECTED_ALONE, MAX_LENGTH} from 'core/constants';
import styles from './Input.module.css';

export const Input = memo<IProps>(({
    label,
    afterLabel,
    value,
    placeholder = 'Type here',
    onClick,
    onChange,
    notEditable = false,
    isActive = false,
    isInvalid = false,
    wrap = false,
    onlyOneTagCanBeChosen = false,
}) => {
    const [showEditLabel, setShowEditLabel] = useState<boolean>(false);
    const [chosenTags, setChosenTags] = useState<Array<string> | null>(null);
    const [disabledTags, setDisabledTags] = useState<Array<string> | null>(null);
    const [enteredValue, setEnteredValue] = useState<string | null>(null);
    const [unwrap] = useState<boolean>(!wrap);
    const inputRef = useRef<HTMLInputElement>(null);
    const doneRef = useRef<HTMLSpanElement>(null);

    const modeLabelClasses = showEditLabel ? styles.modeLabel : styles.modeLabelHidden;
    const wrapperClasses = cn(
        {[styles.wrapperActive]: isActive},
        {[styles.wrapperInvalid]: isInvalid},
        {[styles.wrapperNotEditable]: notEditable},
        {[styles.wrapper]: !isActive && !notEditable},
    );

    const extendedTagsSelectedAlone = useMemo(() => {
        if (onlyOneTagCanBeChosen && Array.isArray(value)) {
            const tagsNames = value?.map(({text}) => text) || [];
            return [
                ...TAGS_SELECTED_ALONE,
                ...tagsNames,
            ];
        }

        return TAGS_SELECTED_ALONE;
    }, [onlyOneTagCanBeChosen, value]);

    const showTagsPlaceholder = useMemo(() => {
        if (!value || typeof value === 'string') return false;

        return !chosenTags?.length && !isActive;
    }, [value, isActive, chosenTags]);

    const getTagType = useCallback((tag: TValueObj) => {
        const {text} = tag;
        if (isActive) {
            if (disabledTags?.includes(text)) {
                return TagTypes.DISABLED;
            }

            if (!chosenTags?.includes(text)) {
                return TagTypes.READY_TO_SELECT;
            }
            return TagTypes.SELECTED;
        }

        if (!chosenTags?.includes(text)) {
            return TagTypes.HIDDEN;
        }

        return TagTypes.BIG;
    }, [chosenTags, isActive, disabledTags]);

    const handleTagClick = useCallback((chosen: string) => {
        if (!value?.length || typeof value === 'string') return;

        if (chosenTags?.includes(chosen)) {
            if (extendedTagsSelectedAlone.includes(chosen)) {
                setDisabledTags([]);
            }
 
            /** Delete tag from product */
            const newTags = chosenTags.filter(text => text !== chosen);
            setChosenTags(newTags);
        } else {
            if (extendedTagsSelectedAlone.includes(chosen)) {
                setChosenTags([chosen]);
                const disabledTags = value.map(({text}) => text).filter((text) => text !== chosen);
                setDisabledTags(disabledTags);
            } else {
                /** Add tag to product */
                const newTags = [...(chosenTags || []), chosen];
                setChosenTags(newTags);
            }
        }
    }, [chosenTags, value, setDisabledTags, setChosenTags, extendedTagsSelectedAlone]);

    const handleTextBlur = useCallback(() => {
        if (enteredValue !== null) {
            onChange?.(enteredValue.trim());
        }
    }, [enteredValue]);

    const handleTagsBlur = useCallback(() => {
        if (!value || typeof value === 'string') return;

        const smthChanged = Boolean(value
            .filter(({text, showOnEdit}) => !showOnEdit && !chosenTags?.includes(text))
            .length
        ) || Boolean(chosenTags?.length);

        if (Boolean(chosenTags) && smthChanged) {
            onChange?.(chosenTags!);
        }
    }, [chosenTags, value]);

    useEffect(() => {
        if (!value || typeof value === 'string') return;

        const chosenIds = value.filter(({showOnEdit}) => !showOnEdit).map(({text}) => text);
        setChosenTags(chosenIds);

        if (chosenIds.some((id) => extendedTagsSelectedAlone.includes(id))) {
            if (onlyOneTagCanBeChosen) {
                const disabledTags = value.filter(({showOnEdit}) => Boolean(showOnEdit)).map(({text}) => text);
                setDisabledTags(disabledTags);
            } else {
                const disabledTags = value
                    .map(({text}) => text)
                    .filter((text) => !extendedTagsSelectedAlone.includes(text) || !(extendedTagsSelectedAlone.includes(text) && chosenIds.includes(text)));
                setDisabledTags(disabledTags);
            }
        }
    }, [value, setChosenTags, setDisabledTags, extendedTagsSelectedAlone, onlyOneTagCanBeChosen]);

    useEffect(() => {
        handleTagsBlur();
    }, [handleTagsBlur, chosenTags]);

    useEffect(() => {
        handleTextBlur();
    }, [handleTextBlur, enteredValue]);

    useEffect(() => {
        if (!enteredValue) return;

        const trimmed = enteredValue.trim();
        if (trimmed === '') {
            setEnteredValue('');
        }
    }, [isActive]);

    return (
        <div
            className={wrapperClasses}
            onClick={(e) => {
                if (isActive || notEditable || e.target === doneRef.current) return;

                onClick?.(String(label));

                if (e.target !== inputRef.current && inputRef.current !== null) {
                    // Set input cursor to the end of input
                    const selection = window.getSelection();
                    const range = document.createRange();
                    selection?.removeAllRanges();
                    range.selectNodeContents(inputRef.current);
                    range.collapse(false);
                    selection?.addRange(range);

                    inputRef.current.focus();
                }
            }}
            onMouseOver={() => !notEditable && setShowEditLabel(true)}
            onMouseLeave={() => !notEditable && setShowEditLabel(false)}
        >
            <label
                htmlFor={`textarea_${label}`}
                className={styles.title}
            >
                {label}
                {afterLabel && <span> {afterLabel}</span>}
            </label>
            {(typeof value === 'string') && wrap && !unwrap && !isActive && (
                // Показываем readmore
                <div
                    id={`textarea_${label}`}
                    data-placeholder={placeholder}
                    className={styles.input}
                    ref={inputRef}
                >
                    <>
                        {(enteredValue || value)?.slice(0, MAX_LENGTH)}&nbsp;...&nbsp;
                        <span className={styles.readMore}>
                            Read more
                        </span>
                    </>
                </div>
            )}
            {(typeof value === 'string' || !value)
                ? (
                    <div
                        id={`textarea_${label}`}
                        data-placeholder={placeholder}
                        className={cn(styles.input, {[styles.hiddenInput]: wrap && !unwrap && !isActive})}
                        onInput={(e) => setEnteredValue(e.currentTarget.textContent || '')}
                        contentEditable={!notEditable}
                        ref={inputRef}
                        suppressContentEditableWarning
                        onPaste={(e) => {
                            e.preventDefault();
                            const text = e.clipboardData.getData('text/plain');
                            document.execCommand('insertHTML', false, text);
                        }}
                    >
                        {value}
                    </div>
                )
                : (
                    <div
                        className={styles.tags}
                        id={`tags_${label}`}
                    >
                        {!showTagsPlaceholder
                            //@ts-ignore
                            ? value?.map((tag, idx) => (
                                <Tag
                                    text={tag.text}
                                    key={`input_${label}_tag_${tag.text}_${idx}`}
                                    type={getTagType(tag)}
                                    onClick={() => handleTagClick(tag.text)}
                                />
                            ))
                            : <span>{placeholder}</span>
                        }
                    </div>
                )}
            {!isActive && (
                <span className={modeLabelClasses}>
                    EDIT
                </span>
            )}
            {isActive && (
                <span
                    ref={doneRef}
                    className={styles.modeLabel}
                    onClick={() => {
                        handleTextBlur();
                        handleTagsBlur();
                        onClick?.('null');
                    }}
                >
                    DONE
                </span>
            )}
        </div>
    );
});

type TValueObj = {
    text: string;
    showOnEdit: boolean;
}

interface IProps {
    label?: string;
    afterLabel?: string;
    value?: string | Array<TValueObj>;
    placeholder?: string;
    onClick?: (label: string) => void;
    onChange?: (value: string | Array<string>) => void;
    notEditable?: boolean;
    isActive?: boolean;
    isInvalid?: boolean;
    wrap?: boolean;
    onlyOneTagCanBeChosen?: boolean; // настройка означает, что можно выбрать только один тег
}
