import {ChangeEvent, memo, useCallback, useEffect, useState, FormEvent, useRef} from 'react';
import {useLocation, useNavigate, createSearchParams} from 'react-router-dom';

import {Button} from 'components/common/newButton'
import {useSearchItems} from 'queries/products';
import {TProduct} from 'core/types';
import {ROUTES} from 'core/constants';
import {ReactComponent as ClearIcon} from 'assets/images/clear.svg';
import {SearchIcon} from './SearchIcon';
import {Suggests} from './Suggests';
import styles from './Search.module.css';

const MAX_SUGGEST_COUNT = 5;

export const Search = memo(() => {
    const [{data, error}, fetch] = useSearchItems();
    const navigate = useNavigate();
    const {search} = useLocation();

    const [value, setValue] = useState<string>('');
    const [suggests, setSuggests] = useState<Array<TProduct>>([]);
    const [showSuggests, setShowSuggests] = useState<boolean>(false);
    const [activeSuggestIdx, setActiveSuggestIdx] = useState<number | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    //TODO: добавить дебаунс
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setValue(value);
        if (Boolean(value.length)) {
            fetch({text: value, limit: MAX_SUGGEST_COUNT});
        } else {
            setShowSuggests(false);
        }
    }, []);

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!value) {
            return;
        }

        setShowSuggests(false);

        navigate({
            pathname: ROUTES.PRODUCTS,
            search: createSearchParams({
                text: value,
            }).toString(),
        });
    }, [value, navigate]);

    const handleClear = useCallback(() => {
        setValue('');
        setShowSuggests(false);

        const decoded = new URLSearchParams(search).get('text');
        if (decoded) {
            navigate({pathname: ROUTES.PRODUCTS});
        }
    }, [setValue, setShowSuggests, navigate, search]);

    const handleSuggestCLick = useCallback((name: string | undefined) => {
        if (!name) return;

        setShowSuggests(false);

        navigate({
            pathname: ROUTES.PRODUCTS,
            search: createSearchParams({
                text: name,
            }).toString()
        });
    }, [navigate, setShowSuggests]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape') {
            setShowSuggests(false);
        }

        if (e.key !== 'ArrowDown' || !suggests.length || !showSuggests) {
            return;
        }

        e.preventDefault();
        setActiveSuggestIdx(0);
    }, [suggests, showSuggests]);

    const handleBlur = useCallback((e: any) => {
        if (!e.relatedTarget) {
            setShowSuggests(false);
        }
    }, [setShowSuggests]);

    useEffect(() => {
        if (Boolean(data?.length) && !error) {

            setSuggests(data as Array<TProduct>);
            setShowSuggests(true);
        } else {
            setShowSuggests(false);
        }
    }, [data, error]);

    useEffect(() => {
        if (search.length) {
            const decoded = new URLSearchParams(search).get('text');
            if (decoded) {
                setValue(decoded);
            }
        } else {
            setValue('');
        }
    }, [search]);

    useEffect(() => {
        if (Boolean(inputRef.current) && activeSuggestIdx === -1) {
            inputRef.current!.focus();
        }
    }, [activeSuggestIdx, inputRef]);

    useEffect(() => {
        if (!showSuggests) {
            setActiveSuggestIdx(null);
        }
    }, [showSuggests, setActiveSuggestIdx]);

    return (
        <div className={styles.withSuggests}>
            <form
                className={styles.wrapper}
                onSubmit={handleSubmit}
            >
                <div className={styles.border} />
                <input
                    placeholder='Product Brand or Name'
                    value={value}
                    ref={inputRef}
                    tabIndex={1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                {Boolean(value.length) && (
                    <div
                        className={styles.clearBtn}
                        onClick={handleClear}
                    >
                        <ClearIcon />
                    </div>
                )}
                <div className={styles.button}>
                    <Button
                        icon={<SearchIcon />}
                        isSubmit
                    />
                </div>
            </form>
            {Boolean(suggests.length) && showSuggests && (
                <Suggests
                    activeIdx={activeSuggestIdx}
                    onSetActiveIdx={setActiveSuggestIdx}
                    items={suggests.slice(0, MAX_SUGGEST_COUNT)}
                    onClick={({name}) => handleSuggestCLick(name)}
                    onClose={() => setShowSuggests(false)}
                />
            )}
        </div>
    );
});
