import {memo} from 'react';

import styles from './InformationNote.module.css';

export const InformationNote = memo<IProps>(function InformationNote({type}) {
    if (type === 'ingredients') {
        return (
            <div className={styles.wrapper}>
                <b>Ingredients & Information:</b> Please be aware that ingredient lists and description for the products could&nbsp;
                be updated by brand, factory and country of production. Please, before use the product, refer to the ingredient list&nbsp;
                and product description on your product package for the most up to date list of ingredients to ensure it is suitable&nbsp;
                to your personal use.
            </div>
        );
    }

    if (type === 'research') {
        return (
            <div className={styles.wrapper}>
                <b>Free Research Preview:</b> Our goal is to make the AI skincare recommendation system more effective and to help people reduce the number
                of allergic reactions they experience after using skincare products.
            </div>
        );
    }

    if (type === 'important') {
        return (
            <div className={styles.wrapperCentered}>
                <b>Important information:</b> All the data presented in the 'Target Audience' section is collected from open sources and is provided solely
                for informational purposes. Please note that this data is approximate and may not always reflect the current situation accurately. We do not
                take responsibility for the accuracy or completeness of the provided information. By using this section, you agree that the data regarding the target audience may be incomplete or inaccurate.
            </div>
        )
    }

    if (type === 'ingredients2') {
        return (
            <div className={styles.wrapper}>
                <b>Ingredients & Information:</b> Please be aware that ingredient lists and product descriptions may be updated by the brand, factory, or
                country of production. Our algorithm utilizes ingredient information from product packaging and public sources, and we do not guarantee that
                it's the latest version or includes all ingredients. It's also important to note that ingredients with less than ()% content may not be listed on the product packaging.
                Additionally, the ingredient match score provided by our algorithm is approximate.
            </div>
        );
    }

    if (type === 'graph') {
        return (
            <div className={styles.wrapper}>
                <b>Data Source Disclosure:</b> We utilize data from open sources and draw conclusions based on this information.
            </div>
        );
    }

    return null;
});

interface IProps {
    type: 'ingredients' | 'ingredients2' | 'research' | 'important' | 'graph';
}
