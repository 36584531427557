import {memo} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

import {mockDatasets} from './mockData2';

const options = {
    responsive: true,
    tension: 0.3,
    parsing: {
        xAxisKey: 'date',
        yAxisKey: 'value',
    },
    scales: {
        x: {
            ticks: {
                color: 'black',
                font: {
                    size: 10,
                },
            },
        },
        y: {
            grid: {
                display: false,
            },
            ticks: {
                color: 'black',
                font: {
                    size: 10,
                },
            },
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            align: 'end' as const,
            labels: {
                boxWidth: 14,
                boxHeight: 14,
                generateLabels: () => ([
                    {
                        text: 'USA',
                        fillStyle: '#FFE896',
                        strokeStyle: '#FFE896',
                    },
                    {
                        text: 'World',
                        fillStyle: '#BCE1EA',
                        strokeStyle: '#BCE1EA',
                    },
                ]),
            },
        },
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

export const LineChart2 = memo<IProps>(({data}) => {
    const combined = {
        ...mockDatasets,
        datasets: mockDatasets.datasets.map((dts, idx) => ({...dts, data: data[idx]}))
    };

    return (
        <Line
            options={options}
            data={combined}
        />
    );
});

interface IProps {
    data: Array<unknown>;
}
