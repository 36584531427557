import {memo, Fragment, useEffect, useCallback, useMemo} from 'react';

import {SelectModal} from 'components/common/selectModal';
import {useAppContext} from 'components/app/AppContext';
import {useDeleteScan} from 'queries/scans';

const REASONS = [
    'This is not a match',
    'Not a skincare product',
    'Inappropriate photo',
    'Unreadable',
    'Not English',
    'Another reason',
];

export const DeleteScanModal = memo(() => {
    const {setIsDeleteScanModalOpen, deletingImage, setDeletingImage, setIsSuccessDeleteModalOpen} = useAppContext();
    const [deletedImage, deleteImage] = useDeleteScan(Number(deletingImage?.data?.id));

    const handleClose = () => {
        setDeletingImage(null);
        setIsDeleteScanModalOpen(false);
    };

    const handleReport = useCallback((text?: string) => {
        if (!deletingImage?.data?.id) return;

        deleteImage({deletedReason: text});
    }, [deletingImage?.data?.id]);

    const title = useMemo(() => (
        <>
            Why do you want
            <Fragment><br/></Fragment>
            to delete this scan from
            <Fragment><br/></Fragment>
            the Product?
        </>
    ), []);

    useEffect(() => {
        if (typeof deletedImage?.data !== 'string' || Boolean(deletedImage?.error)) return;

        setIsDeleteScanModalOpen(false);
        setIsSuccessDeleteModalOpen(true);
    }, [deletedImage?.data, deletedImage?.error]);

    return (
        <SelectModal
            title={title}
            reasons={REASONS}
            isLoading={deletedImage?.isPending || false}
            onClose={handleClose}
            onSubmit={handleReport}
        />
    );
});
