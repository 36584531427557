import {memo, ReactNode, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {Header} from 'components/common/header';
import {Menu} from 'components/blocks/menu';
import {LowResolution} from 'components/blocks/troubles/LowResolution';
import {useAppContext} from 'components/app/AppContext';
import {ROUTES} from 'core/constants';
import {useWindowSize} from 'core/hooks';
import {useMe} from 'queries/user';
import {useRequestsBalance} from 'queries/score';
import styles from './Layout.module.css';

const MIN_WIDTH_BREAKPOINT = 1150;

export const Layout = memo<IProps>(({children}) => {
    const {pathname} = useLocation();
    const {setDeletingIds, setUser, setIsAuthPending, refreshBalance, setRefreshBalance, isAuthPending, setBalance} = useAppContext();
    const size = useWindowSize();
    const [{data, isPending, error}, fetch] = useMe();
    const [{data: balance, isPending: isPendingBalance, error: balanceError}, fetchBalance] = useRequestsBalance();
    const [temp, setTemp] = useState(false);

    const withMenu = useMemo(() => ![ROUTES.HOME, ROUTES.LOGIN].includes(pathname), [pathname]);

    useEffect(() => {
        setIsAuthPending(true);
        fetch();
        // fetchBalance();
        // setTemp(true);
    }, []);

    useEffect(() => {
        // СУПЕР НАНО МЕГА КОСТЫЛЬ
        if (pathname === ROUTES.PRODUCTS && data === null && !isAuthPending && !!temp) {
            setIsAuthPending(true);
            fetch();
            // fetchBalance();
        }
    }, [pathname, data, isAuthPending, temp]);

    useEffect(() => {
        setDeletingIds([]);
    }, [pathname, setDeletingIds]);

    useEffect(() => {
        // if (!!data && !error && balance !== null) {
        //     setUser({...data, balance: balance === null ? null : balance});
        //     setIsAuthPending(false);
        // }

        if (!!data && !error) {
            setUser(data);
            setIsAuthPending(false);
        }

        if (!!error) {
            localStorage.removeItem('token');
            localStorage.removeItem('was-first-request-modal-opened');
            setUser(null);
            setIsAuthPending(false);
        }

        // if (!!balanceError) {
        //     setIsAuthPending(false);
        // }
    }, [data, error]);

    // useEffect(() => {
    //     if (refreshBalance) {
    //         setRefreshBalance(false);
    //         setIsAuthPending(true);
    //         fetchBalance();
    //     }
    // }, [refreshBalance]);

    useEffect(() => {
        fetchBalance();
    }, [data]);

    useEffect(() => {
        if (balance !== null) {
            setBalance(balance);
        }
    }, [balance]);

    if (size.width < MIN_WIDTH_BREAKPOINT) {
        return (
            <div className={styles.wrapper}>
                <Header onlyLogo />
                <LowResolution />
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            {(isPending || isPendingBalance)
            ? (
                <>
                    <div className='pageloaderWrapper'>
                        <div className='pageloader'/>
                    </div>
                </>
            )
            : (
                <>
                    <Header />
                    {withMenu && <Menu />}
                    {children}
                </>
            )}
        </div>
    );
});

interface IProps {
    children: ReactNode;
}
