import {memo, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {PRODUCT_CATEGORIES} from 'core/configs';
import {useCheckPermission} from 'core/utils';
import {PERMISSIONS} from 'core/constants';
import styles from './CategoryFilter.module.css';

export const CategoryFilter = memo(() => {
    const [searchParams, setSearchParams] = useSearchParams();
    const canUseFilter = useCheckPermission(PERMISSIONS.can_use_filters);

    const activeFilter = useMemo(() => {
        return searchParams.get('filter');
    }, [searchParams]);

    const isSearchActive = useMemo(() => {
        return searchParams.get('text');
    }, [searchParams]);

    const handleClick = useCallback((key: string) => {
        setSearchParams(
            {filter: key},
            {replace: true},
        );
    }, []);

    if (isSearchActive || !canUseFilter) return null;

    return (
        <div className={styles.wrapper}>
            {PRODUCT_CATEGORIES.map((category, index) => (
                <button
                    className={activeFilter === category ? styles.buttonActive : styles.button}
                    onClick={() => handleClick(category)}
                    key={`${category}_${index}`}
                >
                    {category}
                </button>
            ))}
        </div>
    );
});
