import {memo} from 'react';

import styles from './Nps.module.css';

export const Nps = memo(() => (
    <div className={styles.wrapper}>
        <a
            className={styles.link}
            href='mailto:info@beautyhunter.app'
        >
            Spot an issue? Tell us here
        </a>
    </div>    
));
