import {Fragment, memo, useCallback} from 'react';

import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as Icon} from 'assets/images/400.svg';
import styles from './errors.module.css';

export const BadRequest = memo(() => {
    const {setIsSaveErrorModalOpen} = useAppContext();

    const handleClose = useCallback(() => {
        setIsSaveErrorModalOpen(false);
    }, [setIsSaveErrorModalOpen]);

    return (
        <Modal onClose={handleClose}>
            <div className={styles.wrapper}>
                <Icon />
                <div className={styles.title}>
                    Saving error
                </div>
                <div className={styles.text}>
                    Sorry, but we couldn’t save the changes.
                    <Fragment><br/></Fragment>
                    Please try again later.
                </div>
                <div className={styles.button}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
