import {memo, useCallback, useEffect} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {useRequestsBalance} from 'queries/score';
import styles from './FirstRequestModal.module.css';

export const FirstRequestModal = memo(() => {
    const {setIsSubscriptionModalOpen, setIsFirstRequestModalOpen} = useAppContext();
    const [{data}, fetch] = useRequestsBalance();

    const handleClose = useCallback(() => {
        setIsFirstRequestModalOpen(false);
    }, []);

    const handleOkClick = useCallback(() => {
        setIsSubscriptionModalOpen(true);
        handleClose();
    }, []);

    useEffect(() => {
        fetch();
        localStorage.setItem('was-first-request-modal-opened', 'true');
    }, []);

    if (!data) {
        return null;
    }

    return (
        <Modal
            title={''}
            onClose={handleClose}
        >
            <div>
                <div className={styles.title}>{data} Requests left</div>
                <div className={styles.description}>
                    You can always get more in your account or you can purchase an unlimited subscription
                </div>
                <div className={styles.controls}>
                    <Button text='Ok' onClick={handleOkClick} />
                    <button className={styles.secondary} onClick={handleClose}>Maybe later</button>
                </div>
            </div>
        </Modal>
    );
});
