import {useRequest} from 'core/hooks';
import {TScoreResult, TCityMatchResult, TCity, TProduct} from 'core/types';

const BASE_URL = '/score';

export const useScoreInfo = () =>
    useRequest<{product: number, user: number}, Array<TScoreResult>>(({product, user}) => ({
        url: process.env.REACT_APP_SCORER_URL || `${BASE_URL}/userproduct`,
        method: 'POST',
        //@ts-ignore
        data: [{user, product}],
    }), true);

export const useCityMatchInfo = () =>
    useRequest<Array<{product: number, city: string, gender?: string}>, Array<TCityMatchResult>>((arr) => ({
        url: process.env.REACT_APP_CITY_MATCH_URL || `${BASE_URL}/cityproduct`,
        method: 'POST',
        //@ts-ignore
        data: arr,
    }), true);

export const useProductPossibleCustomers = () =>
    useRequest<{productId: number, cityId: number}, number>(({productId, cityId}) => ({
        url: `/product-possible-customers/${productId}/${cityId}`,
        method: 'GET',
    }), true);

export const useCities = () =>
    useRequest<void, Array<TCity>>(() => ({
        url: '/cities-info',
        method: 'GET',
    }), true);

export const useRequestsBalance = () =>
    useRequest<void, number>(() => ({
        url: '/brand-request-balance',
        method: 'GET',
    }), true);

export const useCreateSubscribe = () =>
    useRequest<number, number>((balance) => ({
        url: '/brand-subscriber',
        method: 'POST',
        //@ts-ignore
        data: {
            balance,
        }
    }), true);

export const useFreeAttempts = () =>
    useRequest<void, number>(() => ({
        url: '/brand-free-attempts',
        method: 'GET',
    }), true);

export const useSimilarProducts = () =>
    useRequest<number, Array<TProduct>>((id) => ({
        url: '/search/by_product',
        method: 'POST',
        //@ts-ignore
        data: [{product: id}],
    }), true);

export const useCityDemography = () =>
    useRequest<number, {demography: number}>((id) => ({
        url: `/cities-info/${id}`,
        method: 'GET',
    }), true);

export const useTrendsLocation = () =>
    useRequest<{id: number, cityId: number}, number>(({id, cityId}) => ({
        url: `/products/${id}/trendslocation/${cityId}`,
        method: 'GET',
    }), true);

export const useTrendsProductGraph = () =>
    useRequest<number, void>((id) => ({
        url: '/trends/product_graph',
        method: 'POST',
        // @ts-ignore
        data: {product_id: id},
    }), true);
