import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {CancelModal} from 'components/blocks/cancelModal';
import {
    usePutProduct,
    useProductsProperties,
    useProduct,
    useSmartCreateProduct,
    useCreateProductImage
} from 'queries/products';
import {useRemoveScanFromInProgress} from 'queries/scans';
import {ProductSources, TProduct} from 'core/types';
import {ROUTES} from 'core/constants';
import {getTrimmedCopyForText} from 'core/utils';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {ReactComponent as DeleteIcon} from 'assets/images/delete.svg';
import {ReactComponent as BrandIcon} from 'assets/images/brand-icon.svg';
import {ReactComponent as GptIcon} from 'assets/images/gpt-icon.svg';

import {Content} from './Content';
import styles from './EditProductModal.module.css';

export const EditProductModal = memo(() => {
    const {
        setIsProductModalOpen,
        setProduct,
        product,
        scan,
        refresh,
        setIsSelectModalOpen,
        setIsSaveErrorModalOpen,
        setIsSuccessSaveModalOpen,
        createdProductBubble,
        setCreatedProductBubble,
        setScan,
        setIsBrandModalOpened,
        setIsGptModalOpened,
    } = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
    const [editedProduct, setEditedProduct] = useState(product);
    const [chosenLocalImage, setChosenLocalImage] = useState<File>();

    //@ts-ignore
    const [{data, error}, fetch] = useSmartCreateProduct();
    const [{data: changedData, error: changeError}, fetchChanging] = usePutProduct();
    const [changedProductImage, fetchChangeProductImage] = useCreateProductImage();
    const [{data: properties}, fetchProperties] = useProductsProperties();
    const [{data: newProduct}, fetchOneProduct] = useProduct();
    const [{error: removeError}, fetchRemoveScanFromInProgress] = useRemoveScanFromInProgress();
    const {pathname} = useLocation();

    const isEditMode = useMemo(() => Boolean(product), [product]);
    const notEditable = useMemo(() => pathname === ROUTES.CITY_MATCH, [pathname]);

    const needSave = useMemo(() => {
        if (!product) {
            return Boolean(editedProduct)
                && Object.values(editedProduct as object)?.some((elem) => Boolean(elem?.length));
        }

        return Object
            .entries(editedProduct as TProduct)
            ?.some(([key, value]) => {
                if (key === 'gender') {
                    const modifiedNewValue = Array.isArray(value) ? value[0] : String(value);
                    return String(product[key]) !== modifiedNewValue;
                }

                // Кейс для spf, там одно число-строка в массиве
                if (key === 'spf') {
                    const modifiedNewValue = Array.isArray(value) ? value[0] : String(value);
                    return String(product[key]) !== modifiedNewValue;
                }

                // Кейс для ингредиентов, потому что они получаются из форматированной строки
                // @ts-ignore
                if (typeof value === 'string' && Array.isArray(product[key])) {
                    // @ts-ignore
                    return value !== product[key].join(',');
                }

                if (key === 'createdDate' || key === 'updatedDate') {
                    return false;
                }

                if (typeof value === 'string' || typeof value === 'number' || value === null) {
                    // @ts-ignore
                    return product[key] !== value;
                }

                if (!Array.isArray(value)) {
                    // don't compare objects
                    return false;
                }

                if (!value.length || typeof value?.[0] !== 'string') {
                    // don't compare pictures or empty arrays
                    return false;
                }

                // @ts-ignore
                if (!product[key]) {
                    return true;
                }

                // кейс для городов, у них другая ДТО
                if (key === 'citiesInfos') {
                    // @ts-ignore
                    const productCities = product[key]?.map(({city}) => city);
                    return (
                        value.some((elem) => !productCities?.includes(elem))
                        // @ts-ignore
                        || productCities?.some((elem) => !value.includes(elem))
                    );
                }

                return Boolean(
                    // @ts-ignore
                    value.some((elem) => !product[key]?.includes(elem))
                    // @ts-ignore
                    || product[key].some((elem) => !value.includes(elem))
                );
            });
    }, [editedProduct, product]);

    const handleClose = useCallback(() => {
        setIsProductModalOpen(false);
        setProduct(null);

        if (scan) {
            setScan(null);
        }
    }, []);

    const handleConfirm = useCallback(() => {
        if (needSave || !!chosenLocalImage) {
            setIsConfirmModalOpen(true);
        } else {
            handleClose();
        }
    }, [needSave, chosenLocalImage]);

    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);
        if (!editedProduct) return;

        if (!needSave && !!chosenLocalImage) {
            // Если поля не меняли, а поменяли только картинку, не дергаем запрос на апдейт
            // @ts-ignore
            fetchChangeProductImage(editedProduct.id, chosenLocalImage);
            return;
        }

        //@ts-ignore
        const spfValue = Array.isArray(editedProduct.spf) ? editedProduct.spf[0] : editedProduct.spf;
        //@ts-ignore
        const ingredients = (typeof editedProduct.ingredients === 'string' ? editedProduct.ingredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.ingredients) || [];
        //@ts-ignore
        const keyIngredients = (typeof editedProduct.keyIngredients === 'string' ? editedProduct.keyIngredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.keyIngredients) || [];
        const transformatedCopy = {
            ...getTrimmedCopyForText(editedProduct),
            spf: spfValue === 'No' ? 0 : Number(spfValue),
            ingredients: ingredients.map((v: string) => v.trim()),
            keyIngredients: keyIngredients.map((v: string) => v.trim()),
            warning: editedProduct.warning?.trim() || '',
            citiesInfos: editedProduct.citiesInfos?.map((text) => ({city: text})) || [],
            gender: editedProduct.gender?.[0] || '',
        };

        // Проверяем зависимые поля
        const configWithVisibleConds = PRODUCT_FIELDS_CONFIG.filter((field) => !!field.visibilityConfig);
        if (!!configWithVisibleConds.length) {
            configWithVisibleConds.forEach((field) => {
                const {dependField, visibilityValue} = field.visibilityConfig!;
                const dependEntered = editedProduct[dependField];
                const dependValueForCompare = Array.isArray(dependEntered) ? dependEntered[0] : dependEntered;
                const needShow = dependValueForCompare === visibilityValue;

                if (!needShow) {
                    // @ts-ignore
                    transformatedCopy[field.key] = [];
                }
            });
        }

        if (isEditMode) {
            const lightweightCopy = {
                ...transformatedCopy,
                ageGroup: transformatedCopy.ageGroup || [],
                skinSensitiveness: transformatedCopy.skinSensitiveness || [],
                timeToUse: transformatedCopy.timeToUse || [],
                bodyParts: transformatedCopy.bodyParts || [],
                productCategory: transformatedCopy.productCategory || [],
                ingredientsFree: transformatedCopy.ingredientsFree || [],
                rating: transformatedCopy.rating || 0,
            };
            delete lightweightCopy.productImages;
            delete lightweightCopy.scans;

            //@ts-ignore
            fetchChanging(lightweightCopy);
            // @ts-ignore
            fetchChangeProductImage(lightweightCopy.id, chosenLocalImage);

            if (!!scan) {
                fetchRemoveScanFromInProgress(scan.id);
            }
        } else {
            //@ts-ignore
            fetch(chosenLocalImage, transformatedCopy);
        }
    }, [editedProduct, isEditMode, chosenLocalImage]);

    const handleCancel = useCallback(() => {
        if (needSave || !!chosenLocalImage) {
            setIsCancelModalOpen(true);
        } else {
            handleClose();
        }
    }, [needSave, chosenLocalImage]);

    const handleCancelOk = useCallback(() => {
        setIsCancelModalOpen(false);
        handleClose();
    }, []);

    const handleDelete = useCallback(() => {
        setProduct(product);
        setIsSelectModalOpen(true);
    }, [product]);

    const extraControls = [
        <div className={styles.action} onClick={handleDelete} key={'modal_delete_icon'}>
            <DeleteIcon />
        </div>,
    ];

    const ContentNode = useMemo(() => (
        <Content
            product={newProduct || product}
            properties={properties}
            onConfirm={handleConfirm}
            onEdit={setEditedProduct}
            onCancel={handleCancel}
            onLocalPictureChange={setChosenLocalImage}
        />
    ), [product, newProduct, properties, handleConfirm, handleCancel, setEditedProduct]);

    useEffect(() => {
        if (Boolean(data) && !error) {
            if (pathname !== ROUTES.PRODUCTS) {
                const newCount = Number(createdProductBubble) + 1;
                setCreatedProductBubble(String(newCount));
            }

            setIsSuccessSaveModalOpen(true);
            setIsProductModalOpen(false);
        }

        if (typeof changedData === 'string' && !changeError) {
            if (!!scan && !removeError) {
                setScan(null);
            }
            setIsSuccessSaveModalOpen(true);
            setIsProductModalOpen(false);
        }

        // @ts-ignore
        if (!!changedProductImage.data && !changedProductImage.error) {
            setIsSuccessSaveModalOpen(true);
            setIsProductModalOpen(false);
        }
    }, [error, data, changedData, changeError, pathname, createdProductBubble, setCreatedProductBubble, scan, removeError, changedProductImage]);

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        if (product?.id && refresh) {
            fetchOneProduct(product.id);
        }
    }, [product?.id, refresh]);

    useEffect(() => {
        if (Boolean(error) || Boolean(changeError)) {
            setIsSaveErrorModalOpen(true);
        }
    }, [error, changeError]);

    const TitleNode = useMemo(() => {
        if (!isEditMode) return 'Create new product';

        const sign = `Product ID: ${product!.id}`;

        if (!product!.source) return sign;

        const isGpt = product!.source === ProductSources.gpt;
        const IconNode = isGpt ? GptIcon : BrandIcon;
        return (
            <div className={styles.titleWithIcon}>
                <IconNode onClick={() => isGpt ? setIsGptModalOpened(true) : setIsBrandModalOpened(true) }/>
                <span>{sign}</span>
            </div>
        );
    }, [isEditMode, product]);

    return (
        <>
            <Modal
                title={TitleNode}
                onClose={handleCancel}
                extraHeaderControls={(isEditMode && !notEditable) ? extraControls : null}
                borderUnderTitle
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                    isCreateMode={!isEditMode}
                />
            )}
            {isCancelModalOpen && (
                <CancelModal
                    onOk={handleCancelOk}
                    onCancel={() => setIsCancelModalOpen(false)}
                />
            )}
        </>
    );
});
