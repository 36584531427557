const mockDataMentions = {
    label: 'Mentions',
    borderColor: '#FFE896',
    pointBackgroundColor: '#FFE896',
    yAxisID: 'y',
    segment: {
        borderDash: ((ctx: any) => {
            const isPredicted = ctx.p0.raw.isPredicted;
            if (isPredicted) return [5, 5];
            return [];
        }),
    },
}

const mockDataReach = {
    label: 'Reach',
    borderColor: '#BCE1EA',
    pointBackgroundColor: '#BCE1EA',
    yAxisID: 'y1',
    segment: {
        borderDash: ((ctx: any) => {
            const isPredicted = ctx.p0.raw.isPredicted;
            if (isPredicted) return [5, 5];
            return [];
        }),
    },
}

export const mockDatasets = {
    datasets: [
        mockDataMentions,
        mockDataReach,
    ],
}
