import {memo} from 'react';

import styles from './Radio.module.css';

export const Radio = memo<IProps>((props) => {
    const {bold, text, price, isChecked, onCheck} = props;

    const tempId = Math.random();
    return (
        <label
            className={isChecked ? styles.wrapperChecked : styles.wrapper}
            htmlFor={`subscription-plan-${tempId}`}
            onClick={onCheck}
        >
            <div className={styles.left}>
                <label className={styles.checkbox}>
                    <input
                        type='radio'
                        name='subscription-plan'
                        id={`subscription-plan-${tempId}`}
                        checked={isChecked}
                        onChange={() => {}}
                    />
                    <span
                        className={styles.checkmark}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                </label>
                <span>
                    <b>{bold}&nbsp;</b>
                    <span>{text}</span>
                </span>
            </div>
            <div className={styles.right}>
                {price}
            </div>
        </label>
    );
});

interface IProps {
    bold: string;
    text: string;
    price: string;
    isChecked: boolean;
    onCheck: () => void;
}
