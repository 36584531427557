import {ChangeEvent, memo, useCallback, useEffect, useState, useRef} from 'react';

import {TCity} from 'core/types';
import {ReactComponent as ClearIcon} from 'assets/images/clear.svg';
import {Suggests} from './Suggests';
import styles from './Search.module.css';

const MAX_SUGGEST_COUNT = 5;

export const Search = memo<IProps>(({onChange, hideSuggests, possibleValues}) => {
    const [value, setValue] = useState<string>('');
    const [suggests, setSuggests] = useState<Array<TCity>>([]);
    const [showSuggests, setShowSuggests] = useState<boolean>(false);
    const [activeSuggestIdx, setActiveSuggestIdx] = useState<number | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    //TODO: добавить дебаунс
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setValue(value);
        onChange(value);
        if (!!value.length) {
            const filteredValues = possibleValues.filter((item) => item.city.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())) || [];
            setSuggests(filteredValues);
            setShowSuggests(true);
        } else {
            // setShowSuggests(false);
        }
    }, [possibleValues, onChange]);

    const handleClear = useCallback(() => {
        setValue('');
        onChange('');
        setShowSuggests(false);
    }, [setValue, setShowSuggests]);

    const handleSuggestCLick = useCallback((name: string | undefined) => {
        if (!name) return;

        setValue(name);
        onChange(name);
        setShowSuggests(false);
    }, [setShowSuggests]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape') {
            setShowSuggests(false);
        }

        if (e.key !== 'ArrowDown' || !suggests.length || !showSuggests) {
            return;
        }

        e.preventDefault();
        setActiveSuggestIdx(0);
    }, [suggests, showSuggests]);

    const handleBlur = useCallback((e: any) => {
        if (!e.relatedTarget) {
            setShowSuggests(false);
        }
    }, [setShowSuggests]);

    const handleInputClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current!.focus();
        }
    }, []);

    useEffect(() => {
        if (Boolean(inputRef.current) && activeSuggestIdx === -1) {
            inputRef.current!.focus();
        }
    }, [activeSuggestIdx, inputRef]);

    useEffect(() => {
        if (!showSuggests) {
            setActiveSuggestIdx(null);
        }
    }, [showSuggests, setActiveSuggestIdx]);

    useEffect(() => {
        if (hideSuggests) {
            setShowSuggests(false);
        }
    }, [hideSuggests]);

    return (
        <div className={styles.withSuggests}>
            <div className={styles.wrapperWithoutBtn} onClick={handleInputClick}>
                <div className={styles.borderWithoutBtn} />
                <span className={styles.label}>
                    City
                </span>
                <input
                    value={value}
                    ref={inputRef}
                    tabIndex={1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                {!!value.length && (
                    <div
                        className={styles.clearBtn}
                        onClick={handleClear}
                    >
                        <ClearIcon />
                    </div>
                )}
            </div>
            {Boolean(suggests.length) && showSuggests && (
                <Suggests
                    activeIdx={activeSuggestIdx}
                    onSetActiveIdx={setActiveSuggestIdx}
                    items={suggests.slice(0, MAX_SUGGEST_COUNT)}
                    onClick={(city) => handleSuggestCLick(city)}
                    onClose={() => setShowSuggests(false)}
                />
            )}
            {!suggests.length && showSuggests && (
                <div className={styles.suggests}>
                    <div
                        className={styles.emptySuggest}
                    >
                        No city in the database
                    </div>
                </div>
            )}
        </div>
    );
});

interface IProps {
    onChange: (value: string) => void;
    hideSuggests?: boolean;
    possibleValues: Array<TCity>;
}
