import {Fragment, memo} from 'react';

import {Button, ButtonThemes} from 'components/common/newButton';
import {ROUTES} from 'core/constants';
import styles from './Enter.module.css';

export const Enter = memo(() => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {/* TODO: return this text for non-brands */}
                {/* Beauty hunter is a community of experts who want
                <Fragment><br/></Fragment>
                to share their skincare experience with other */}
                Beauty Hunter is a collaborative platform enhancing 
                <Fragment><br/></Fragment>
                the effectiveness of skincare and hair care products
            </div>
            <div className={styles.buttons}>
                <Button
                    text='Join us'
                    // link='https://www.beautyhunter.app/jobs'
                    link='https://www.beautyhunter.app/brands'
                />
                <Button
                    text='Log in'
                    theme={ButtonThemes.secondary}
                    link={ROUTES.LOGIN}
                />
            </div>
        </div>
    );
});
