import {memo, useMemo, useState, useRef, useEffect} from 'react';
import {Pagination as SwiperPagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Tooltip} from 'react-tooltip';
import {createPortal} from 'react-dom';

import {Preview} from 'components/common/preview';
import {Pagination} from 'components/common/pagination';
import {ScoreResult} from 'components/blocks/scoreResult/ScoreResult';
import {InformationNote} from 'components/blocks/informationNote/InformationNote';
import {TScoreWithColor} from 'core/types';
import {ReactComponent as InfoIcon} from 'assets/images/info-icon.svg';
import {ReactComponent as BrandEmptyIcon} from 'assets/images/brand-empty-icon.svg';
import styles from './Gallery.module.css';

const WINDOW_THRESHOLD = 4;
const MAX_ITEMS_COUNT = WINDOW_THRESHOLD * 3;

export const Gallery = memo<IProps>(({items}) => {
    const [currentSlide, setCurrentSlide] = useState<number>(1);
    const [cardsStyle, setCardsStyle] = useState<any>();
    const swiperRef = useRef(null);
    const cardsRef = useRef(null);
    const shortenItems = items.slice(0, MAX_ITEMS_COUNT);

    const WindowNodes = useMemo(() => {
        return (
            <Swiper
                modules={[SwiperPagination]}
                spaceBetween={16}
                slidesPerView={4}
                ref={swiperRef}
                allowTouchMove={false}
            >
                {shortenItems.map((info) => (
                    <SwiperSlide
                        key={`similar_product_${info.product_id}`}
                        className={styles.slideWrapper}
                    >
                        <Slide info={info} />
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }, [shortenItems]);

    useEffect(() => {
        //@ts-ignore
        if (!cardsRef.current) return;
        //@ts-ignore
        const maxWidth = cardsRef.current.offsetWidth;
        setCardsStyle({maxWidth});
    }, [cardsRef.current]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.cards} ref={cardsRef} style={cardsStyle}>
                {!!cardsStyle && WindowNodes}
            </div>
            <Pagination
                count={shortenItems.length}
                maxCohortCount={WINDOW_THRESHOLD}
                activePage={currentSlide}
                onRightArrowClick={() => {
                    if (!swiperRef.current) return;
                    //@ts-ignore
                    swiperRef.current.swiper.slideTo(currentSlide * WINDOW_THRESHOLD);
                    setCurrentSlide(currentSlide + 1);
                }}
                onLeftArrowClick={() => {
                    if (!swiperRef.current) return;
                    //@ts-ignore
                    swiperRef.current.swiper.slideTo((currentSlide - 2) * WINDOW_THRESHOLD);
                    setCurrentSlide(currentSlide - 1);
                }}
                onNumberArrowClick={(num) => {
                    if (!swiperRef.current) return;
                    //@ts-ignore
                    swiperRef.current.swiper.slideTo((num - 1) * WINDOW_THRESHOLD);
                    setCurrentSlide(num);
                }}
            />
            <InformationNote type='ingredients2' />
        </div>
    );
});

interface IProps {
    items: Array<TSimilarProduct>;
}

const Slide = memo<ISlideProps>(({info}) => {
    const iconIdForTooltip = `ingredients-info-icon-${info.product_id}`;

    return (
        <div className={styles.slide}>
            <div className={styles.picture}>
                <Preview
                    url={info.img || null}
                    fit
                    customFallback={<BrandEmptyIcon />}
                />
            </div>
            <div className={styles.scoreInfo}>
                <div className={styles.score}>
                    <ScoreResult score={info.score} isMini />
                </div>
                <div id={iconIdForTooltip} className={styles.infoIcon}>
                    <InfoIcon />
                </div>
                {!!info.score && !!info.similar_ingredients?.length && (
                    <>
                        {createPortal(
                            <Tooltip
                                anchorSelect={`#${iconIdForTooltip}`}
                                place='bottom'
                                className={styles.infoTooltip}
                            >
                                <SlideTooltip
                                    score={info.score}
                                    ingredients={info.similar_ingredients?.map(({value}) => String(value)) || []}
                                    counts={info.fraction_score}
                                />
                            </Tooltip>,
                            document.body,
                        )}
                    </>
                )}
            </div>
            <div className={styles.title}>{info.brand_name}</div>
            <div className={styles.text}>{info.product_name}</div>
        </div>
    );
});

interface ISlideProps {
    info: TSimilarProduct;
}

type TSimilarProduct = {
    product_id: number;
    product_name: string;
    brand_name: string;
    img: string;
    score: number;
    similar_ingredients: Array<TScoreWithColor>;
    fraction_score: string;
}

const SlideTooltip = memo<ISlideTooltipProps>(({score, ingredients, counts}) => {
    return (
        <div className={styles.tooltipContentWrapper}>
            <div>
                <ScoreResult
                    score={score}
                    label='Ingredients match'
                    custom='ingredients'
                />
            </div>
            <div className={styles.tooltipContentTexts}>
                <div className={styles.tooltipContentTitle}>
                    Identical ingredients{!!counts ? ` (${counts})` : ''}:
                </div>
                <div>
                    {ingredients.join(', ')}
                </div>
            </div>
        </div>
    );
});

interface ISlideTooltipProps {
    score: number;
    ingredients: Array<string>;
    counts: string;
}
