import {memo, useMemo, ReactElement, useCallback, FormEvent} from 'react';

import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import styles from './SelectModal.module.css';

export const SelectModal = memo(({
    title,
    reasons,
    onClose,
    onSubmit,
    isLoading = false,
}: IProps) => {
    const CheckboxNode = useMemo(() => {
        return reasons.map((text: string, idx: number) => (
            <label className={styles.checkbox} key={`delete_reason_${idx}`}>
                {text}
                <input type='radio' name='delete-reason' value={text} />
                <span className={styles.checkmark} />
            </label>
        ));
    }, [reasons]);

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.target;
        //@ts-ignore
        const reason = form.querySelector('input[name="delete-reason"]:checked')?.value;
        onSubmit(reason);
    }, [onSubmit]);

    return (
        <Modal
            title={title}
            onClose={onClose}
        >
            <form
                className={styles.checkboxes}
                onSubmit={handleSubmit}
            >
                {CheckboxNode}
                <div className={styles.button}>
                    <Button
                        text='Delete and report'
                        isLoading={isLoading}
                        isSubmit
                    />
                </div>
            </form>
        </Modal>
    );
});

interface IProps {
    title: string | ReactElement;
    reasons: Array<string>;
    isLoading?: boolean;
    onClose: () => void;
    onSubmit: (text?: string) => void;
}
