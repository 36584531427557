import {memo, useMemo} from 'react';

import {ReactComponent as PositiveIcon} from 'assets/images/heart.svg';
import {ReactComponent as NegativeIcon} from 'assets/images/broken-heart.svg';
import styles from './CityToggler.module.css';

const NEGATIVE_BREAKPOINT = 50;

export const CityToggler = memo<IProps>(({info}) => {
    const {city, score, num, country} = info;

    const isNegative = useMemo(() => score < NEGATIVE_BREAKPOINT, [score]);
    const classes = isNegative ? styles.badgeNegative : styles.badgePositive;

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {num}. {city}, {country}
            </div>
            <div className={styles.badgeWrapper}>
                <div className={classes}>
                    <div className={styles.icon}>
                        {isNegative ? <NegativeIcon /> : <PositiveIcon />}
                    </div>
                    <div className={styles.badgeText}>{score}%</div>
                </div>
            </div>
        </div>
    );
});

interface IProps {
    info: {
        city: string,
        country: string,
        score: number,
        num: number,
    };
}
