import {memo, useMemo, useEffect, useCallback, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useErrorHandler} from 'react-error-boundary';

import {useScansInProgress, useScansInProgressCount, useScansInProgressMissed, useScansInProgressWithoutProduct} from 'queries/scans';
import {ScanList} from 'components/blocks/scanList';
import {useAppContext} from 'components/app/AppContext';
import {Forbidden, InternalError} from 'components/blocks/troubles';
import {InProgressFilter} from 'components/blocks/inProgressFilter';
import {Pagination} from 'components/blocks/pagination';
import {TScan} from 'core/types';
import {MAX_ITEM_COUNT_FOR_PAGE} from 'core/constants';

export const InProgress = memo(() => {
    const [{data, isPending, error}, fetch] = useScansInProgress();
    const [{data: missedData, isPending: missedPending, error: missedError}, fetchMissed] = useScansInProgressMissed();
    const [{data: noProductData, isPending: noProductPending, error: noProductError}, fetchNoProduct] = useScansInProgressWithoutProduct();

    const {refresh, setRefresh, sort, deletingIds, setIsSelectModalOpen, setDeletingIds} = useAppContext();
    const [{data: countData}, fetchCount] = useScansInProgressCount();
    const [cached, setCached] = useState<Array<TScan> | null>([]);
    const [cachedCount, setCachedCount] = useState<number | null>();

    const [searchParams, setSearchParams] = useSearchParams();

    const activeFilter = useMemo(() => {
        return searchParams.get('filter');
    }, [searchParams]);

    const fetchScans = useCallback(fetch, []);
    const fetchMissedScans = useCallback(fetchMissed, []);
    const fetchNoProductScans = useCallback(fetchNoProduct, []);
    const fetchScansCount = useCallback(fetchCount, []);

    const {search} = useLocation();
    const handleError = useErrorHandler();

    const handleDeletePack = useCallback(() => {
        setIsSelectModalOpen(true);
    }, []);

    const handleClearPackSelection = useCallback(() => {
        setDeletingIds([]);
    }, [setDeletingIds]);

    const offset = useMemo(() => {
        if (!search.length) return 0;

        const page = new URLSearchParams(search).get('p');
        if (!page || Number(page) === 1) return 0;

        return (Number(page) - 1) * MAX_ITEM_COUNT_FOR_PAGE;
    }, [search]);

    const selectedInfo = useMemo(() => {
        if (!deletingIds.length) {
            return null;
        }

        return (
            <div className='selectedInfo'>
                <div className='selectedInfoCount'>
                    {deletingIds.length}
                </div>
                <div className='selectedInfoText'>
                    item(s) to delete
                </div>
                <div className='selectedInfoButtons'>
                    <div
                        className='selectedInfoButton'
                        onClick={handleDeletePack}
                    >
                        Delete
                    </div>
                    <div
                        className='selectedInfoButton'
                        onClick={handleClearPackSelection}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }, [deletingIds]);

    const currentData = useMemo(() => {
        if (!activeFilter) return null;

        if (activeFilter === 'all') {
            return data;
        }

        if (activeFilter === 'missing') {
            return missedData;
        }

        if (activeFilter === 'new') {
            return noProductData;
        }

        return null;
    }, [activeFilter, data, missedData, noProductData]);

    const currentPending = useMemo(() => {
        if (!activeFilter) return null;

        if (activeFilter === 'all') {
            return isPending;
        }

        if (activeFilter === 'missing') {
            return missedPending;
        }

        if (activeFilter === 'new') {
            return noProductPending;
        }

        return null;
    }, [activeFilter, isPending, missedPending, noProductPending]);

    const currentError = useMemo(() => {
        if (!activeFilter) return null;

        if (activeFilter === 'all') {
            return error;
        }

        if (activeFilter === 'missing') {
            return missedError;
        }

        if (activeFilter === 'new') {
            return noProductError;
        }

        return null;
    }, [activeFilter, error, missedError, noProductError]);

    useEffect(() => {
        if (!activeFilter) return;

        if (activeFilter === 'all') {
            fetchScans({offset});
        }

        if (activeFilter === 'missing') {
            fetchMissedScans({offset});
        }

        if (activeFilter === 'new') {
            fetchNoProductScans({offset});
        }

        fetchScansCount(activeFilter || 'all');
    }, [fetchScans, offset, fetchNoProductScans, fetchMissedScans, activeFilter]);

    useEffect(() => {
        if (refresh) {
            setCached(currentData);
            setCachedCount(countData?.count);

            if (!activeFilter) return;

            if (activeFilter === 'all') {
                fetchScans({offset, order: sort?.order});
            }
    
            if (activeFilter === 'missing') {
                fetchMissedScans({offset, order: sort?.order});
            }
    
            if (activeFilter === 'new') {
                fetchNoProductScans({offset, order: sort?.order});
            }

            fetchScansCount(activeFilter || 'all');
            setRefresh(false);
        }
    }, [fetchScans, refresh, offset, sort, setCached, setCachedCount, activeFilter, currentData]);

    useEffect(() => {
        if (currentPending && Boolean(cached?.length)) {
            document.body.classList.add('overflowed');
        } else {
            document.body.classList.remove('overflowed');
        }
    }, [currentPending, cached?.length]);

    useEffect(() => {
        if (!activeFilter) {
            setSearchParams(
                {filter: 'all'},
                {replace: true},
            );
        }
    }, [activeFilter]);

    if (Boolean(currentError)) {
        // @ts-ignore
        if (typeof currentError !== 'string' && currentError?.status === 403) return <Forbidden />;
        // @ts-ignore
        if (typeof currentError !== 'string' && currentError?.status === 500) return <InternalError />;

        handleError(currentError);
        return null;
    }

    // Свежая загрузка (без кеша)
    if ((!currentData || currentPending) && !cached?.length) {
        return (
            <>
                <InProgressFilter />
                <div className='pageloaderWrapper'>
                    <div className='pageloader'/>
                </div>
            </>
        );
    }

    return (
        <div>
            <InProgressFilter />
            {selectedInfo}
            {/* Подгрузка (обновление кеша) */}
            {currentPending && Boolean(cached?.length) && (
                <>
                    <div className='contentLoaderUnder'>
                        <div className='contentLoaderWrapper'>
                            <div className='contentLoader'/>
                        </div>
                    </div>
                </>
            )}

            <ScanList items={(currentData || cached || [])} />
            <Pagination count={countData?.count || cachedCount || null} />
        </div>
    );
});
