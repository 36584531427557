import {ChangeEvent, memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';

import {useCheckPermission} from 'core/utils';
import {useOutsideClicker} from 'core/hooks';
import {PERMISSIONS} from 'core/constants';
import {useDeleteProductImage} from 'queries/products';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as MoreIcon} from 'assets/images/more.svg';
import styles from './Picture.module.css';
import {Button, ButtonTypes} from '../newButton';

export const MainPicture = memo<IProps>(function MainPicture({
    url,
    productId,
    availability,
    onLocalImageChange,
}) {
    const canUploadPicture = useCheckPermission(PERMISSIONS.can_upload_image);

    return (
        <div className={styles.wrapperMain}>
            {!!url && !!productId
            ? <PictureContent
                url={url}
                productId={productId}
                availability={availability}
                onLocalImageChange={onLocalImageChange}
              />
            : canUploadPicture
                    ? <AddPictureForm onLocalImageChange={onLocalImageChange} />
                    : <div className={styles.noPhoto}>There is no product cover yet</div>
            }
        </div>
    );
});

interface IProps {
    url?: string;
    productId?: number;
    availability: ReactElement | null;
    onLocalImageChange: (file: File) => void;
}

const PictureContent = memo<IPictureContentProps>(function PictureContent({
    url,
    productId,
    availability,
    onLocalImageChange,
}) {
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState(false);
    // Буферная картинка. Заполнена, когда выбрали Change image, но еще не сохранили
    const [updatedTempImage, setUpdatedTempImage] = useState<string>();
    const [deletedImage, deleteImage] = useDeleteProductImage();

    const {setRefresh} = useAppContext();

    const pictureSrc = useMemo(() => updatedTempImage || url, [updatedTempImage, url]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const src = URL.createObjectURL(file);
        setUpdatedTempImage(src);
        setIsLoaded(false);
        setIsError(false);

        onLocalImageChange(file);
    }, []);

    useEffect(() => {
        if (!deletedImage?.data || !!deletedImage?.error) return;

        setIsLoaded(false);
        setRefresh(true);
    }, [deletedImage?.data, deletedImage?.error]);

    return (
        <>
            <PictureMenu
                onDelete={() => {
                    setIsLoaded(false);
                    deleteImage({id: productId});
                }}
                onChange={handleChange}
            />
            {isError && (
                <>
                    {!!availability && (
                        <div className={styles.tag}>
                            {availability}
                        </div>
                    )}
                    <div className={styles.noPhoto}>
                        Failed to load product image
                    </div>
                </>
            )}
            {!isError && (
                <>
                    {!!availability && (
                        <div className={styles.tag}>
                            {availability}
                        </div>
                    )}
                    <div className={cn(styles.skeleton, {
                        [styles.skeletonHidden]: isLoaded
                    })}/>
                    <img
                        src={pictureSrc}
                        onLoad={() => setIsLoaded(true)}
                        alt='product_main_picture'
                        className={cn({
                            [styles.imageHidden]: !isLoaded,
                        })}
                        onError={() => {
                            setIsError(true);
                            setIsLoaded(true);
                        }}
                    />
                </>
            )}
        </>
    );
});

interface IPictureContentProps {
    url: string;
    productId: number;
    availability: ReactElement | null;
    onLocalImageChange: (file: File) => void;
}

const AddPictureForm = memo<IAddPictureFormProps>(function AddPictureForm({
    onLocalImageChange,
}) {
    // Буферная картинка. Заполнена, когда выбрали картинку, но еще не сохранили
    const [updatedTempImage, setUpdatedTempImage] = useState<string>();

    const handleCreate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const src = URL.createObjectURL(file);
        setUpdatedTempImage(src);

        onLocalImageChange(file);
    }, []);

    if (!!updatedTempImage) {
        return (
            <img
                src={updatedTempImage}
                alt='temp_product_main_picture'
            />
        );
    }

    return (
        <div className={styles.addImageButton}>
            <label className={styles.addImageLabel} htmlFor='uploadForm_file'>
                <Button text='+ Add image' onClick={() => {}} type={ButtonTypes.micro} />
                <input
                    className={styles.addImageSelector}
                    id='uploadForm_File'
                    type='file'
                    name='file_name'
                    accept='image/*'
                    onChange={handleCreate}
                />
            </label>
            <div className={styles.instructions}>
                Upload a photo that clearly shows the&nbsp;product with label and name on it.
            </div>
        </div>
    );
});

interface IAddPictureFormProps {
    onLocalImageChange: (file: File) => void;
}

const PictureMenu = memo<IPictureMenuProps>(function PictureMenu({
    onUncover,
    onChange,
    onDelete,
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const dropdownRef = useRef(null);

    useOutsideClicker(dropdownRef, () => {setIsDropdownOpen(false)});

    return (
        <>
            <div
                className={styles.menu}
                onClick={() => setIsDropdownOpen(true)}
            >
                <MoreIcon />
            </div>
            {isDropdownOpen && (
                <>
                    <div
                        className={styles.dropdown}
                        ref={dropdownRef}
                    >
                        {!!onUncover && (
                            <div
                                className={styles.dropdownItem}
                                onClick={() => {
                                    onUncover();
                                    setIsDropdownOpen(false);
                                }}
                            >
                                Make uncover
                            </div>
                        )}
                        {!!onChange && (
                            <label className={styles.addImageLabel} htmlFor='changeImage_file'>
                                <div className={styles.dropdownItem}>
                                    Change image
                                </div>
                                <input
                                    className={styles.addImageSelector}
                                    id='changeImage_file'
                                    type='file'
                                    name='file_name'
                                    accept='image/*'
                                    onChange={(e) => {
                                        onChange(e);
                                        setIsDropdownOpen(false);
                                    }}
                                />
                            </label>
                        )}
                        {!!onDelete && (
                            <div
                                className={styles.dropdownItem}
                                onClick={() => {
                                    onDelete();
                                    setIsDropdownOpen(false);
                                }}
                            >
                                Delete image
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
});

interface IPictureMenuProps {
    onUncover?: () => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onDelete?: () => void;
}
