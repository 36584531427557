import {Fragment, memo} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import styles from './ComingSoonModal.module.css';

export const ComingSoonModal = memo(() => {
    const {setIsComingSoonModalOpen} = useAppContext();

    const handleClose = () => {
        setIsComingSoonModalOpen(false);
    };

    return (
        <Modal onClose={handleClose}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    Coming soon!
                </div>
                <div className={styles.description}>
                    Our team will contact you shortly via the email provided in your login.
                </div>
                <div className={styles.button}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
