import {memo, useCallback, useMemo, useState} from 'react';
import {Tooltip} from 'react-tooltip';

import {
    ScoreConfigField,
    ScoreFieldTypes,
    ScoreSentiments,
    TagTypes,
    TCityMatchResult,
    TExplanation,
    TScoreResult
} from 'core/types';
import {MAX_LENGTH} from 'core/constants';
import {Tag} from 'components/common/tag';
import {ReactComponent as InfoIcon} from 'assets/images/info-icon.svg';
import styles from './ScoreCheckingField.module.css';

const DEFAULT_EMPTY_TEXT = 'No data';

export const ScoreCheckingField = memo<IProps>(({field, info, onModalClick, customIndex}) => {
    const [unwrap, setUnwrap] = useState<boolean>(!field.wrap);

    const values = field.takeFromCustomIndex && typeof customIndex !== 'undefined'
        //@ts-ignore
        ? field.keys.map((key) => info?.[field.where][customIndex][key] || []).flat()
        : !!field.where
            //@ts-ignore
            ? field.keys.map((key) => info?.[field.where][key] || []).flat()
            //@ts-ignore
            : field.keys.map((key) => info?.[key] || []).flat()

    //@ts-ignore
    const needCollapse = useMemo(() => {
        if (!values || !field.wrap) return false;
        //@ts-ignore
        const texts = values.map((str) => typeof str !== 'object' ? str : str.value).join(', ');
        return Number(texts.length) > MAX_LENGTH;
    }, [values]);

    const handleReadMoreClick = useCallback(() => {
        setUnwrap(!unwrap);
    }, [unwrap]);

    if (field.type === ScoreFieldTypes.tagFromText) {
        const val = values[0] || DEFAULT_EMPTY_TEXT;

        return (
            <div className={styles.tagsWrapper}>
                <span>
                    <Tag
                        text={val}
                        type={TagTypes.DEFAULT}
                    />
                </span>
            </div>
        );
    }

    if (field.type === ScoreFieldTypes.tags && values.length > 0) {
        const getType = (val: any) => {
            if (!field.isMatchable || !val?.sentiment) return TagTypes.DEFAULT;
            if (val.sentiment === ScoreSentiments.positive) return TagTypes.POSITIVE;
            if (val.sentiment === ScoreSentiments.negative) return TagTypes.NEGATIVE;
            return TagTypes.DEFAULT;
        }

        return (
            <div className={styles.tagsWrapper}>
                {values
                .map((val, idx) => {
                        const text = !val ? DEFAULT_EMPTY_TEXT : (typeof val !== 'object' ? val : val.value);
                        
                        const formattedText = !!val && !!field.format
                            ? !!field.prefixes ? field.format(field.prefixes[idx], text) : field.format(text)
                            : text;

                        const tooltipId = `${field.where}_${field.keys[0]}_${idx}`;
                        const showTooltip = val.sentiment === ScoreSentiments.negative && !!val.explanation?.paragraphs?.length;

                        return (
                            <span key={`${field.keys[0]}_${idx}`}>
                                <Tag
                                    text={formattedText}
                                    type={getType(val)}
                                    id={tooltipId}
                                    hoverable={showTooltip}
                                />
                                {showTooltip && (
                                    <Tooltip
                                        anchorSelect={`#${tooltipId}`}
                                        place='bottom'
                                        className={styles.tagTooltip}
                                    >
                                        <ConflictTooltipContent paragraphs={val.explanation.paragraphs} />
                                    </Tooltip>
                                )}
                            </span>
                        );
                })}
            </div>
        );
    }

    if (values.every((v) => (v === undefined || v === null)) || !values.length) {
        if (field.title === 'Allergic reactions') return <Tag text='No reactions' />;
        return <Tag text={DEFAULT_EMPTY_TEXT} />;
    }

    // Пока работает только для продукта
    if (field.type === ScoreFieldTypes.modal) {
        return (
            <div className={styles.clickable} role='button' onClick={onModalClick}>
                <span>
                    {!!field.format && field.format(...values.map((obj) => typeof obj !== 'object' ? obj : obj.value))}
                    {!field.format && <span>{values.join(', ')}</span>}
                </span>
                <span className={styles.icon}>
                    <InfoIcon />
                </span>
            </div>
        );
    }

    if (field.title === 'Location' && !!field.format) {
        const fieldsValues = values.map((obj) => typeof obj !== 'object' ? obj : obj.value);
        if (Number(fieldsValues[0]).toFixed(0) === '0' && Number(fieldsValues[1]).toFixed(0) === '0') {
            return <Tag text={DEFAULT_EMPTY_TEXT} />;
        }

        return (
            //@ts-ignore
            <span>{field.format(...fieldsValues.reverse())}</span>
        );
    }

    //@ts-ignore
    const formattedValues = values.map((str) => {
        const text = typeof str !== 'object' ? str : str.value;

        if (!!field.format) {
            return field.format(text);
        }

        const extraStyles = {
            ...(str?.sentiment === 'positive' && {'backgroundColor': '#DAFF8B'}),
            ...(str?.sentiment === 'negative' && {'backgroundColor': '#FF834E'})
        };

        const tooltip = str?.sentiment !== 'negative' ? null : str?.explanation;
        return {text, extraStyles, tooltip};
    });

    if (formattedValues.every((v) => (typeof v === 'string'))) {
        return <span>{formattedValues.join(', ')}</span>;
    }

    if (!needCollapse || unwrap) {
        return (
            <>
                {/*@ts-ignore*/}
                {formattedValues.map(({text, extraStyles, tooltip}, idx) => {
                    if (!!tooltip) {
                        const textTooltipId = `${field.where}_${field.keys[0]}_${idx}`;
                        return (
                            <>
                                <span key={`field_text_part_${idx}_${field.title}`} id={textTooltipId}>
                                    <span style={extraStyles}>{text}</span>
                                    {(idx < values.length - 1) && ', '}
                                </span>
                                <Tooltip
                                    anchorSelect={`#${textTooltipId}`}
                                    place='bottom'
                                    className={styles.tagTooltip}
                                >
                                    <ConflictTooltipContent paragraphs={tooltip.paragraphs}/>
                                </Tooltip>
                            </>
                        );
                    }

                    return (
                        <span key={`field_text_part_${idx}_${field.title}`}>
                            <span style={extraStyles}>{text}</span>
                            {(idx < values.length - 1) && ', '}
                        </span>
                    );
                })}&nbsp;
                {field.wrap && unwrap && (
                    <span
                        className={styles.readMore}
                        onClick={handleReadMoreClick}
                    >
                        Read less
                    </span>
                )}
            </>
        )
    }

    let nodes = [];
    let counter = 0;

    for (let i = 0; i < formattedValues.length; i++) {
        //@ts-ignore
        const {text, extraStyles, tooltip} = formattedValues[i];
        if (counter >= MAX_LENGTH) break;

        if (!!tooltip) {
            const textTooltipId = `${field.where}_${field.keys[0]}_${i}`;
            nodes.push(
                <>
                    <span key={`field_text_part_${i}_${field.title}`} id={textTooltipId}>
                        <span style={extraStyles}>{text}</span>
                        {(i < formattedValues.length - 1) && (counter + text.length < MAX_LENGTH) &&  ', '}
                    </span>
                    <Tooltip
                        anchorSelect={`#${textTooltipId}`}
                        place='bottom'
                        className={styles.tagTooltip}
                    >
                        <ConflictTooltipContent paragraphs={tooltip.paragraphs} />
                    </Tooltip>
                </>
            );
        } else {
            nodes.push(
                <span key={`field_text_part_${i}_${field.title}`}>
                    <span style={extraStyles}>{text}</span>
                    {(i < formattedValues.length - 1) && (counter + text.length < MAX_LENGTH) &&  ', '}
                </span>
            );
        }
        counter += text.length;
    }

    return (
        <div>
            <>
                {nodes}&nbsp;...&nbsp;
                <span
                    className={styles.readMore}
                    onClick={handleReadMoreClick}
                >
                    Read more
                </span>
            </>
        </div>
    );
});

interface IProps {
    field: ScoreConfigField;
    info: TScoreResult | TCityMatchResult;
    onModalClick: () => void;
    customIndex?: number;
}

const ConflictTooltipContent = memo<TExplanation>(({paragraphs}) => {
    const [text, ...tags] = paragraphs;

    const getType = (val: any) => {
        if (!val?.sentiment) return TagTypes.DEFAULT;
        if (val.sentiment === ScoreSentiments.positive) return TagTypes.POSITIVE;
        if (val.sentiment === ScoreSentiments.negative) return TagTypes.NEGATIVE;
        return TagTypes.DEFAULT;
    }

    if (!tags.length) {
        return (
            <div className={styles.conflictTooltipContent}>
                <div>
                    <span><b>Reason: </b></span>
                    <span>{typeof text === 'string' ?  text : 'no'}</span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.conflictTooltipContent}>
            <div><b>It has a conflict with:</b></div>
            {tags.map((tagInfo) => {
                if (typeof tagInfo === 'string') return null;

                return (
                    <Tag
                        text={String(tagInfo.text)}
                        type={getType(tagInfo)}
                    />
                );
            })}
            <div>
                <span><b>Reason: </b></span>
                <span>{typeof text === 'string' ?  text : 'no'}</span>
            </div>
        </div>
    );
});
