import {Dispatch, memo, SetStateAction, useCallback, useEffect, useRef} from 'react';

import {TProduct} from 'core/types';
import styles from './Search.module.css';

export const Suggests = memo<IProps>(({items, onClick, activeIdx, onSetActiveIdx, onClose}) => {
    const handleClick = useCallback((selected: TProduct) => {
        onClick(selected);
    }, []);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.focus();
    }, [activeIdx]);

    const onKeyDown = useCallback((event: any, product: TProduct) => {
        event.preventDefault();

        if (activeIdx === null) {
            return;
        }

        if (event.key === 'Enter') {
            handleClick(product);
        }

        if (event.key === 'Escape') {
            onClose();
        }

        if (event.key === 'ArrowDown' && activeIdx + 1 !== items.length) {
            onSetActiveIdx(activeIdx + 1);
        }

        if (event.key === 'ArrowUp') {
            if (activeIdx === 0) {
                onSetActiveIdx(-1);
            } else {
                onSetActiveIdx(activeIdx - 1);
            }
        }
    }, [onSetActiveIdx, activeIdx, onClose]);

    if (!items.length) {
        return null;
    }

    return (
        <div className={styles.suggests}>
            {items.map((product, idx) => (
                <div
                    ref={activeIdx === idx ? ref : undefined}
                    className={styles.suggest}
                    key={`search_suggest_${product.id}`}
                    onMouseDown={() => handleClick(product)}
                    tabIndex={idx + 2}
                    onKeyDown={(e) => onKeyDown(e, product)}
                >
                    {`${product.brand} — ${product.name}`}
                </div>
            ))}
        </div>
    );
});

interface IProps {
    activeIdx: number | null;
    onSetActiveIdx: Dispatch<SetStateAction<number | null>>;
    items: Array<TProduct>;
    onClick: (product: TProduct) => void;
    onClose: () => void;
}
