import {USER_ROLES} from './constants';

export type TProduct = {
    id?: number;
    brand?: string;
    name?: string;
    productCategory?: Array<string>;
    skinTypes?: Array<string>;
    minAge?: number | null;
    maxAge?: number | null;
    ageGroup?: Array<string>;
    description?: string;
    ingredients?: Array<string>;
    keyIngredients?: Array<string>;
    howToUse?: string;
    scans?: Array<TScan>;
    productImages?: Array<TImage>;
    positiveEffects?: Array<string>;
    timeToUse?: Array<string>;
    skinSensitiveness?: Array<string>;
    bodyParts?: Array<string>;
    urls?: Array<string>;
    ingredientsFree?: Array<string>;
    rating?: number;
    consistence?: Array<string>;
    spf?: number | null;
    warning?: string;
    createdDate?: string;
    source?: ProductSources;
    citiesInfos?: Array<string>;
    gender?: string;
    productAvailability?: Array<string>;
}

export enum ProductSources {
    brand = 'brand',
    gpt = 'chatgpt',
}

export type TImage = {
    id: number;
    url: string;
}

type TOCR = {
    word: string;
}

type TLog = {
    id: number;
    OCR: Array<TOCR>;
}

export type TScan = {
    id: number;
    scanImages?: Array<TImage>;
    productId?: number;
    brand?: string;
    name?: string;
    productCategory?: Array<string>;
    skinTypes?: Array<string>;
    description?: string;
    product?: TProduct;
    productSearchLog?: TLog;
    status?: string;
    createdDate?: string;
    inProgress?: boolean;
};

export enum TagTypes {
    DEFAULT = 'default',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    BIG = 'big',
    HIDDEN = 'hidden',
    SELECTED = 'selected',
    READY_TO_SELECT = 'readyToSelect',
    DISABLED = 'disabled',
    PRODUCING = 'producing',
}

export type RequestType =
    | 'get'
    | 'GET'
    | 'delete'
    | 'DELETE'
    | 'head'
    | 'HEAD'
    | 'options'
    | 'OPTIONS'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH'
    | 'link'
    | 'LINK'
    | 'unlink'
    | 'UNLINK'
    | undefined;

export type TCurrentUser = {
    id: string;
    name: string;
    role: USER_ROLES;
    brand?: string;
    balance: number | null;
}

export type TErrorsCount = {
    count: number;
}

export type TLoginInfo = {
    name: string;
    password: string;
}

export type TLoginResponse = {
    token: string;
}

export type TSearchInput = {
    text: string;
    limit: number;
}

export type TPatchImage = {
    id: number;
    url?: string;
}

export type TPatchScan = {
    scanId: number;
    productId: number;
}

export type TProductProperty = {
    id: number;
    name: string;
}

export type TProductCityProperty = {
    id: number;
    city: string;
}

export type TProductProperties = {
    productCategories: Array<TProductProperty>;
    skinTypes: Array<TProductProperty>;
    positiveEffects: Array<TProductProperty>;
    ageGroups: Array<TProductProperty>;
    timeToUse: Array<TProductProperty>;
    skinSensitiveness: Array<TProductProperty>;
    bodyParts: Array<TProductProperty>;
    spf: Array<TProductProperty>;
    citiesInfos: Array<TProductCityProperty>;
    productAvailability: Array<string>;
}

export type TSearchProduct = {
    text: string;
    brand?: string;
}

export type TProductSort = {
    offset?: number;
    order?: string;
    group?: string;
}

export type TDeleteItem = {
    deletedReason?: string;
    isMisstake?: boolean;
    id?: object;
}

export enum ProductFieldType {
    text = 'text',
    formattedText = 'formattedText',
    tags = 'tags',
    oneFromTags = 'oneFromTags',
}

export type TProductField = {
    key: keyof TProduct;
    label: string;
    isRequired: boolean;
    // canEdit: <-- тут массив ролей, для который открываем поле на редактирование
    type: ProductFieldType;
    wrap?: boolean;
    notEditableForBrand?: boolean;
    visibilityConfig?: {
        dependField: keyof TProduct;
        visibilityValue: string;
    };
}

export enum ScoreSentiments {
    positive = 'positive',
    negative = 'negative',
    neutral = 'neutral',
}

export enum ScoreFieldTypes {
    text = 'ScoreFieldTextType',
    tags = 'ScoreFieldTagsType',
    modal = 'ScoreFieldModalType',
    tagFromText = 'ScoreFieldTagFromTextType'
}

export enum ScoreFieldObject {
    product = 'product',
    user = 'user',
    city = 'city',
    audience_features = 'audience_features',
    ingredients_info_for_city = 'ingredients_info_for_city',
    allergens = 'allegrens',
    restricted_ingredients = 'restricted_ingredients',
    trends = 'trends',
}

export type ScoreConfigField = {
    keys: Array<keyof TProductScore | keyof TUserScore | keyof TIngredientsConflicts | keyof TAudienceFeature | keyof TCityScore | keyof TAllergens | keyof TRestrictedIngredients | keyof TTrends>;
    title: string | null;
    type: ScoreFieldTypes;
    where: ScoreFieldObject | null;
    isMatchable?: boolean;
    takeFromCustomIndex?: boolean;
    format?: (...rest: Array<string>) => string | Array<string>;
    prefixes?: Array<string>;
    wrap?: boolean;
}

export type ScoreConfigRow = {
    left: Array<ScoreConfigField>;
    right: Array<ScoreConfigField>;
}

export type CityScoreConfig = {
    left: {
        title: string;
        fields: Array<ScoreConfigField>;
        noteType?: 'ingredients' | 'research';
    }[],
    right: {
        title: string;
        fields: Array<ScoreConfigField>;
        noteType?: 'ingredients' | 'research';
    }[],
}

export type TExplanationWithColor = {
    text: number | string;
    sentiment: ScoreSentiments;
}

export type TExplanation = {
    paragraphs: Array<string | TExplanationWithColor>;
}

export type TScoreWithColor = {
    value: number | string;
    sentiment: ScoreSentiments;
    explanation: TExplanation | null;
}

export type TProductScore = {
    id: number;
    brand: string | null;
    name: string | null;
    product_category: Array<string> | null;
    time_to_use: Array<TScoreWithColor> | null;
    spf: TScoreWithColor | null;
    age_group: Array<TScoreWithColor> | null;
    skin_types: Array<TScoreWithColor> | null;
    skin_sensitiveness: Array<TScoreWithColor> | null;
    positive_effects: Array<TScoreWithColor> | null;
    ingredients: Array<TScoreWithColor> | null;
    water: Array<TScoreWithColor> | null;
}

export type TUserScore = {
    id: number;
    lat: TScoreWithColor | null;
    lon: TScoreWithColor | null;
    timezone: string | null;
    temperature: TScoreWithColor | null;
    uv_index: TScoreWithColor | null;
    humidity: TScoreWithColor | null;
    age: TScoreWithColor | null;
    skin_type: TScoreWithColor | null;
    skin_sensitive: TScoreWithColor | null;
    skin_goals: Array<TScoreWithColor> | null;
    skin_concerns: Array<TScoreWithColor> | null;
    ingredient_skip: Array<TScoreWithColor> | null;
    ingredient_preferences: Array<TScoreWithColor> | null;
    water: Array<TScoreWithColor> | null;
    country: string | null;
    city: string | null;
    allergic_reactions: Array<TScoreWithColor> | null;
}

//TODO: дополнить
export type TCityScore = {
    id: number;
    city: string;
    country: string;
    local_brands: Array<TScoreWithColor> | null;
    female_share?: number;
}

export type TAudienceFeature = {
    gender: TScoreWithColor | null;
    skin_type: TScoreWithColor | null;
    skin_sensitive: TScoreWithColor | null;
    skin_goals: Array<TScoreWithColor> | null;
    skin_concerns: Array<TScoreWithColor> | null;
    ingredient_preferences: Array<TScoreWithColor> | null;
}

export type TIngredientsConflicts = {
    water_conflict: Array<TScoreWithColor> | null;
    uv_conflict: Array<TScoreWithColor> | null;
    humidity_conflict: Array<TScoreWithColor> | null;
    temperature_conflict: Array<TScoreWithColor> | null;
}

export type TAllergens = {
    allegrens: Array<TScoreWithColor> | null;
}

export type TRestrictedIngredients = {
    restricted_ingredients: Array<TScoreWithColor> | null;
}

export type TTrends = {
    popular_claims: Array<TScoreWithColor> | null;
    top_categories: Array<TScoreWithColor> | null;
}

export type TScoreResult = {
    [ScoreFieldObject.product]: TProductScore;
    [ScoreFieldObject.user]: TUserScore;
    score: number;
    completeness: {
        is_complete: boolean,
    }
}

export type TCityMatchResult = {
    [ScoreFieldObject.city]: TCityScore;
    [ScoreFieldObject.product]: TProductScore;
    [ScoreFieldObject.audience_features]: Array<TAudienceFeature>;
    [ScoreFieldObject.ingredients_info_for_city]: TIngredientsConflicts;
    [ScoreFieldObject.allergens]: TAllergens;
    [ScoreFieldObject.restricted_ingredients]: TRestrictedIngredients;
    [ScoreFieldObject.trends]: TTrends,
    score: number;
}

export type TCity = {
    id: number;
    city: string;
}
