import {memo, useEffect, useMemo, useState, Fragment, useCallback, useRef} from 'react';
import cn from 'classnames';

import {TScan} from 'core/types';
import {Picture} from 'components/common/picture';
import {Button, ButtonTypes} from 'components/common/newButton';
import {FormInput} from 'components/common/form';
import {useAppContext} from 'components/app/AppContext';
import {useFoundProducts} from 'queries/products';
import {useFoundBrands} from 'queries/brands';
import {ReactComponent as WarningIcon} from 'assets/images/warning.svg';
import styles from './FindModal.module.css';

export const Content = memo<IProps>(({
    scan,
}) => {
    const [savedInputProduct, setSavedInputProduct] = useState<string>();
    const [savedInputBrand, setSavedInputBrand] = useState<string>();
    const brandRef = useRef<HTMLInputElement>(null);

    const [showError, setShowError] = useState<boolean>(false);
    const [fullSearch, setFullSearch] = useState<boolean>(false);
    const [{data: products}, fetchProducts] = useFoundProducts();
    const [foundProduct, fetchProduct] = useFoundProducts();
    const [{data: brands}, fetchBrands] = useFoundBrands();
    const {
        setIsCreateWithScanModalOpen,
        setIsFindModalOpen,
        setIsMatchModalOpen,
        setProduct,
        setEnteredBrandInSearch,
        setEnteredProductInSearch,
    } = useAppContext();
    const {
        id,
        scanImages,
        productSearchLog,
    } = scan || {};

    const scanImageUrl = useMemo(() => {
        if (scanImages?.[0]) {
            const {url} = scanImages?.[0];
            return url;
        }

        return null;
    }, [scanImages]);

    const fetchProductsCb = useCallback((text: string) => {
        fetchProducts({text, brand: brandRef.current?.value});
    }, [brandRef.current, fetchProducts]);

    const ProductFormInput = useMemo(() => (
        <FormInput
            label='Product name'
            onInput={(text) => {
                setFullSearch(false);
                setShowError(false);
                setSavedInputProduct(text);
                fetchProductsCb(text);
            }}
            suggests={products || []}
            onSave={(text) => {
                setSavedInputProduct(text);
                setEnteredProductInSearch(text);
            }}
        />
    ), [savedInputBrand, products]);

    useEffect(() => {
        if (!savedInputProduct || !fullSearch) return;

        if (foundProduct?.data?.length) {
            setShowError(false);
            setProduct(foundProduct?.data?.[0]);
            setIsFindModalOpen(false);
            setIsMatchModalOpen(true);
            return;
        }

        if ((
            (!foundProduct?.data?.length && foundProduct?.data !== null)
            || Boolean(foundProduct.error)
        ) && !foundProduct.isPending) {
            setShowError(true);
        }
    }, [foundProduct, savedInputProduct, fullSearch]);

    return (
        <>
            <div className={styles.left}>
                <Picture
                    url={scanImageUrl}
                    isMainPicture
                    hidePictureMenu
                />
                <div className={styles.pictureSign}>
                    Scan ID:
                    <span> {id}</span>
                </div>
                <div className={styles.blockTitle}>
                    Recognized info
                </div>
                <div className={styles.info}>
                    <div className={styles.infoCard}>
                        <div className={styles.infoCardTitle}>Other information</div>
                        <div>
                            {Boolean(productSearchLog)
                                ? (productSearchLog!.OCR.map(({word}, id) => (
                                        <div key={`ocr_${id}`}>{word}</div>
                                    ))
                                )
                                : ('No')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.rightTitle}>
                    <Fragment>
                        Find the product using the brand and<br/> the product name as written on the package
                    </Fragment>
                </div>
                <div className={styles.inputs}>
                    <FormInput
                        label='Brand'
                        ref={brandRef}
                        onInput={(text) => {
                            setFullSearch(false);
                            setShowError(false);
                            setSavedInputBrand(text);
                            fetchBrands(text);
                        }}
                        // @ts-ignore
                        suggests={brands || []}
                        onSave={(text) => {
                            setSavedInputBrand(text);
                            setEnteredBrandInSearch(text);
                        }}
                    />
                    {ProductFormInput}
                </div>
                <div className={styles.submit}>
                    <Button
                        text='Find a product'
                        type={ButtonTypes.micro}
                        isLoading={foundProduct.isPending}
                        onClick={() => {
                            if (!savedInputProduct)  {
                                setShowError(true);
                                return;
                            }
                            setFullSearch(true);
                            fetchProduct({text: savedInputProduct, brand: brandRef.current?.value});
                        }}
                    />
                </div>
                <div className={cn(styles.error, {[styles.errorHidden]: !showError})}>
                    <div>
                        <WarningIcon />
                    </div>
                    <div className={styles.errorText}>
                        It seems that the product is not in the database.
                    </div>
                </div>
                <div className={styles.footer}>
                    <div
                        className={styles.link}
                        onClick={() => {
                            setIsFindModalOpen(false);
                            setIsCreateWithScanModalOpen(true);
                        }}
                    >
                        + Add new product
                    </div>
                </div>
            </div>
        </>
    );
});

interface IProps {
    scan?: TScan | null;
    onConfirm?: () => void;
}
