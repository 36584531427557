import {memo, useMemo} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'components/app/AppContext';
import {UserMenu} from 'components/blocks/userMenu';
import {PERMISSIONS, ROUTES, USER_ROLES} from 'core/constants';
import {ROLE_ALLOW_ROUTES_CONFIG} from 'core/configs';
import {useCheckPermission} from 'core/utils';
import Logo from 'assets/images/logo.svg';
import styles from './Header.module.css';

export const Header = memo<IProps>(({onlyLogo = false}) => {
    const {user} = useAppContext();
    const {pathname} = useLocation();

    const withLogout = useMemo(() => pathname !== ROUTES.HOME && pathname !== ROUTES.LOGIN, [pathname]);
    const isMainPage = useMemo(() =>
        [ROUTES.PRODUCTS, ROUTES.MATCHES, ROUTES.IN_PROGRESS, ROUTES.ERRORS].includes(pathname),
        [pathname],
    );
    const allowedRoutes = useMemo(() => {
        if (!user?.role) {
            return;
        }

        return ROLE_ALLOW_ROUTES_CONFIG[user.role];
    }, [user?.role]);

    const canUseMenu = useCheckPermission(PERMISSIONS.can_use_menu);

    if (onlyLogo) {
        return (
            <a className={styles.static} href='https://www.beautyhunter.app/'>
                <img src={Logo} alt='beauty hunter logo' />
            </a>
        );
    }

    return (
        <div className={styles.wrapper}>
            <a className={styles.left} href='https://www.beautyhunter.app/'>
                <img src={Logo} alt='beauty hunter logo' />
            </a>
            {Boolean(user) && withLogout
                ? (
                    <>
                        {canUseMenu && (
                            <div className={styles.center}>
                                <Link
                                    to={ROUTES.HOME}
                                    className={cn(
                                        styles.navLink,
                                        {'active': isMainPage},
                                        {[styles.navLinkForbidden]: !!allowedRoutes?.length && !allowedRoutes?.includes(ROUTES.HOME)},
                                    )}
                                >
                                    {user?.role === USER_ROLES.brand ? 'Brand Products' : 'Mainpage'}
                                </Link>
                                <NavLink
                                    to={ROUTES.SCORE}
                                    className={cn(
                                        styles.navLink,
                                        {[styles.navLinkForbidden]: !!allowedRoutes?.length && !allowedRoutes?.includes(ROUTES.SCORE)},
                                    )}
                                >
                                    Score checking
                                </NavLink>
                                <NavLink
                                    to={ROUTES.CITY_MATCH}
                                    className={cn(
                                        styles.navLink,
                                        {[styles.navLinkForbidden]: !!allowedRoutes?.length && !allowedRoutes?.includes(ROUTES.CITY_MATCH)},
                                    )}
                                >
                                    Target audience
                                </NavLink>
                            </div>
                        )}
                        <div className={styles.right}>
                            {!!user && <UserMenu user={user} />}
                        </div>
                    </>
                )
                : (
                    <div className={styles.rightWide}>
                        <a
                            className={styles.label}
                            href='https://www.beautyhunter.app/#how-to-works'
                        >
                            How It Works
                        </a>
                        <a
                            className={styles.label}
                            href='https://www.beautyhunter.app/about'
                        >
                            About
                        </a>
                        <a
                            className={styles.label}
                            href='https://www.beautyhunter.app/brands'
                        >
                            For Cosmetic Brands
                        </a>
                        <a
                            className={styles.label}
                            href='mailto:info@beautyhunter.app'
                        >
                            Contact
                        </a>
                    </div>
                )
            }
        </div>
    );
});

interface IProps {
    onlyLogo?: boolean;
}
