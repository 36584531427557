import {memo} from 'react';

import {ReactComponent as Icon} from 'assets/images/low-resolution.svg';
import styles from './errors.module.css';

export const LowResolution = memo(() => {
    return (
        <div className={styles.wrapperWide}>
            <Icon />
            <div className={styles.title}>
                Your screen has too low resolution!
            </div>
            <div className={styles.text}>
                We display a large number of product parameters, 
                and&nbsp;they fit only in desktop screen resolution.<br/>
                Please use a device with a bigger screen.
            </div>
        </div>
    );
});
