import {useRequest} from 'core/hooks';
import {TCurrentUser, TLoginInfo, TLoginResponse} from 'core/types';

export const useMe = () =>
    useRequest<void, TCurrentUser>(() => ({
        url: '/whoAmI',
        method: 'GET',
    }), true);

export const useLogin = () =>
    useRequest<TLoginInfo, TLoginResponse>((data) => ({
        url: '/login',
        method: 'POST',
        data: data,
    }));
