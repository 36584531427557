import {Fragment, memo, useMemo, useCallback} from 'react';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {Button} from 'components/common/newButton';
import {ReactComponent as Icon} from 'assets/images/deleteSuccess.svg';
import styles from './SuccessDeleteModal.module.css';

export const SuccessDeleteModal = memo(() => {
    const {
        setIsSuccessDeleteModalOpen,
        setRefresh,
        product,
        scan,
        setProduct,
        setScan,
        setDeletingIds,
        setAllProductChecked,
        setAllMatchesChecked,
        setAllInProgressChecked,
        setAllErrorsChecked,
        deletingImage,
        setDeletingImage,
    } = useAppContext();

    const handleClose = useCallback(() => {
        if (Boolean(deletingImage)) {
            setDeletingImage(null);
        } else {
            setProduct(null);
        }

        setScan(null);
        setDeletingIds([]);
        setAllProductChecked(false);
        setAllMatchesChecked(false);
        setAllInProgressChecked(false);
        setAllErrorsChecked(false);

        setIsSuccessDeleteModalOpen(false);
        setRefresh(true);
    }, [
        setProduct,
        setScan,
        setDeletingIds,
        setIsSuccessDeleteModalOpen,
        setRefresh,
        setAllProductChecked,
        setAllMatchesChecked,
        setAllInProgressChecked,
        setAllErrorsChecked,
        deletingImage,
        setDeletingImage,
    ]);

    const text = useMemo(() => (
        <>
            {!product && !scan ? 'Items were' : 'Item was'} successfully
            <Fragment><br/></Fragment>
            deleted!
        </>
    ), [product, scan]);

    return (
        <Modal onClose={handleClose}>
            <div className={styles.wrapper}>
                <Icon />
                <div className={styles.title}>
                    {text}
                </div>
                <div className={styles.button}>
                    <Button text='Ok' onClick={handleClose} />
                </div>
            </div>
        </Modal>
    );
});
